import { gengql } from '@/__generated__';
import { EmailValidationAction } from '@/__generated__/graphql';
import { setViewer } from '@/auth/store/viewerSlice';
import { AppThunk } from '@/store';

import { updateUserInfo } from '../store/user';
import ActionTypes from './ActionTypes';

const { ADDRESS__SET_ADDRESS, ADDRESS__REMOVE_ADDRESS } = ActionTypes;

export const SET_ADDRESS = gengql(/* GraphQL */ `
  mutation actionSetAddress(
    $profileId: String!
    $address: String!
    $transport: String!
    $primary: Boolean!
  ) {
    setAddress(
      profile_id: $profileId
      address: $address
      transport: $transport
      primary: $primary
    ) {
      address
      display
      primary
      verified
    }
  }
`);

export function setAddress(
  profileId: any,
  transport: any,
  address: any,
  primary = false
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(SET_ADDRESS, { profileId, address, transport, primary });

    dispatch({
      type: ADDRESS__SET_ADDRESS,
      profileId,
      transport,
      address: data!.setAddress,
    });
  };
}

export function requestEmailValidation(
  profileId: any,
  email: any,
  action = EmailValidationAction.Verify
): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionRequestEmailValidation(
          $profileId: String!
          $email: String!
          $action: EmailValidationAction!
        ) {
          requestEmailValidation(profile_id: $profileId, email: $email, action: $action) {
            address
            display
            primary
            verified
          }
        }
      `),
      { profileId, email, action }
    );
}

export function validateEmail(token: string): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionValidateEmail($token: String!) {
          validateEmail(token: $token) {
            address
            display
            primary
            verified
          }
        }
      `),
      { token }
    );

    const { viewer } = getState();

    if (viewer.id) {
      const address = data!.validateEmail;
      dispatch({
        type: ADDRESS__SET_ADDRESS,
        profileId: viewer.profile?.id,
        transport: 'email',
        address,
      });
      dispatch(
        setViewer({
          ...viewer,
          email: {
            ...viewer.email,
            ...address,
            accepted: address.verified,
            confirmed: address.verified,
          },
        })
      );

      if (address.primary) {
        dispatch(
          updateUserInfo({
            id: viewer.id,
            email: {
              ...viewer.email,
              ...address,
              accepted: address.verified,
            },
          })
        );
      }
    }
  };
}

export function removeAddress(
  profileId: string,
  transport: string,
  address: any
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.mutate({
      mutation: gengql(/* GraphQL */ `
        mutation actionRemoveAddress($profileId: String!, $transport: String!, $address: String!) {
          removeAddress(profile_id: $profileId, transport: $transport, address: $address) {
            address
            display
            primary
            verified
            confirmed
          }
        }
      `),
      variables: { profileId, transport, address },
    });

    const addresses = data!.removeAddress;

    dispatch({
      type: ADDRESS__REMOVE_ADDRESS,
      profileId,
      transport,
      addresses,
    });
  };
}
