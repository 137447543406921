import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';

import { gengql } from '@/__generated__';
import Button from '@/components/Button/Button';
import EmptyMessage from '@/components/EmptyMessage';
import { useApp } from '@/hooks/useAppContext';
import { PROFILE__REMOVE_EDUCATION, PROFILE__UPDATE_EDUCATION } from '@/profile/store';

import Education from './Education';

const ADD_EDUCATION = gengql(/* GraphQL */ `
  mutation addEducation(
    $profile_id: String!
    $degree: String
    $field_of_study: String
    $school: String
    $start_date: Date
    $end_date: Date
    $description: String
  ) {
    addEducation(
      profile_id: $profile_id
      degree: $degree
      field_of_study: $field_of_study
      school: $school
      start_date: $start_date
      end_date: $end_date
      description: $description
    ) {
      id
      degree
      field_of_study
      description
      start_date
      end_date
      school
    }
  }
`);

const UPDATE_EDUCATION = gengql(/* GraphQL */ `
  mutation updateEducation(
    $id: String!
    $degree: String
    $field_of_study: String
    $school: String
    $start_date: Date
    $end_date: Date
    $description: String
  ) {
    updateEducation(
      id: $id
      degree: $degree
      field_of_study: $field_of_study
      school: $school
      start_date: $start_date
      end_date: $end_date
      description: $description
    ) {
      id
      degree
      field_of_study
      description
      start_date
      end_date
      school
    }
  }
`);

const REMOVE_EDUCATION = gengql(/* GraphQL */ `
  mutation removeEducation($id: String!) {
    removeEducation(id: $id) {
      id
    }
  }
`);

const validate = (values: any) => {
  const errors = {};

  const educationErrors: any = [];
  values?.education.forEach((edu: any, i: any) => {
    if (emptyEducation(edu)) {
      // empty education form, treat it as if it was never included
      return;
    }

    const err = {};

    if (!edu.degree) {
      // @ts-expect-error TS(2339): Property 'degree' does not exist on type '{}'.
      err.degree = 'Required';
    }

    if (!edu.school) {
      // @ts-expect-error TS(2339): Property 'school' does not exist on type '{}'.
      err.school = 'Required';
    }

    if (edu.start_date && edu.end_date && moment(edu.start_date).isAfter(edu.end_date)) {
      // @ts-expect-error TS(2339): Property 'end_date' does not exist on type '{}'.
      err.end_date = 'End year before start year';
    }

    if (Object.keys(err).length > 0) {
      educationErrors[i] = err;
    }
  });

  if (educationErrors.length) {
    // @ts-expect-error TS(2339): Property 'education' does not exist on type '{}'.
    errors.education = educationErrors;
  }

  return errors;
};

function emptyEducation(edu: any) {
  if (edu.degree) return false;
  if (edu.field_of_study) return false;
  if (edu.school) return false;
  if (edu.start_date) return false;
  if (edu.end_date) return false;
  if (edu.description) return false;
  return true;
}

function Educations({ fields, onAdd, change }: any) {
  return (
    <div>
      {fields.length > 0 ? (
        fields.map((field: any, index: any) => (
          <Education
            showRemove
            showAdd={index === fields.length - 1}
            onAdd={onAdd}
            education={fields.get(index)}
            field={field}
            key={field}
            onRemove={() => fields.remove(index)}
            change={change}
          />
        ))
      ) : (
        <EmptyMessage
          border={false}
          iconName="work"
          body="Add you first education record"
          action={<Button onClick={onAdd}>Add a Education</Button>}
        />
      )}
    </div>
  );
}

interface EducationFormProps {
  component: React.ElementType;
  array: {
    push: (name: string, value: any) => void;
  };
  change: (field: string, value: any) => void;
  handleSubmit: (
    callback: (values: any) => void
  ) => (event: React.FormEvent<HTMLFormElement>) => void;
  reset: () => void;
  onSubmit?: (values: { education: any[] }) => void;
  onReset?: () => void;
  profile: {
    id: string;
    education: any[];
  };
}

const EducationForm: React.FC<EducationFormProps> = ({
  component: Container,
  array,
  change,
  handleSubmit,
  reset,
  onSubmit,
  onReset,
  profile,
  ...other
}) => {
  const mutationOptions = {
    refetchQueries: ['getProfileCompleteness'],
  };
  const [addEducation, { data: addEducationData, reset: addEducationReset }] = useMutation(
    ADD_EDUCATION,
    mutationOptions
  );
  const [updateEducation, { data: updateEducationData, reset: updateEducationReset }] = useMutation(
    UPDATE_EDUCATION,
    mutationOptions
  );
  const [removeEducation, { data: removeEducationData, reset: removeEducationReset }] = useMutation(
    REMOVE_EDUCATION,
    mutationOptions
  );

  const { store } = useApp(); // Remove store when Profile component removes redux
  useEffect(() => {
    if (addEducationData) {
      store.dispatch({
        type: PROFILE__UPDATE_EDUCATION,
        profileId: profile.id,
        education: addEducationData.addEducation,
      });
      addEducationReset();
    }
  }, [addEducationData, profile.id, addEducationReset, store]);

  useEffect(() => {
    if (updateEducationData) {
      store.dispatch({
        type: PROFILE__UPDATE_EDUCATION,
        profileId: profile.id,
        education: updateEducationData.updateEducation,
      });
      updateEducationReset();
    }
  }, [updateEducationData, profile.id, updateEducationReset, store]);

  useEffect(() => {
    if (removeEducationData) {
      store.dispatch({
        type: PROFILE__REMOVE_EDUCATION,
        profileId: profile.id,
        id: removeEducationData?.removeEducation?.id,
      });
      removeEducationReset();
    }
  }, [removeEducationData, profile.id, removeEducationReset, store]);

  const handleFormSubmit = (values: any) => {
    const education = values?.education.filter((edu: any) => !emptyEducation(edu));

    const itemsToDelete = profile.education.filter(
      (current: any) => !education.find((edited: any) => edited.id === current.id)
    );

    itemsToDelete.forEach((e: any) => {
      removeEducation({ variables: { id: e.id } });
    });

    education.forEach((e: any) => {
      const opts = {
        variables: {
          profile_id: profile.id,
          ...e,
          current: e.current === true,
        },
      };

      if (e.id) {
        updateEducation(opts);
      } else {
        addEducation(opts);
      }
    });

    if (onSubmit) {
      onSubmit({ education });
    }
  };

  const handleFormReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <Container {...other} onSubmit={handleSubmit(handleFormSubmit)} onReset={handleFormReset}>
      <FieldArray
        name="education"
        component={Educations}
        onAdd={() => array.push('education', {})}
        change={change}
      />
    </Container>
  );
};

export default connect((state, ownProps) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const profile = state.profiles.fullProfiles[ownProps.profileId];
  const initialEducations =
    profile.education && profile.education.length > 0 ? profile.education : [{}];
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const form = state.form.expertEducation;
  const initialValues = { education: initialEducations };
  const formValues = form ? form.values : initialValues;

  return {
    profile,
    formValues,
    initialValues,
  };
})(
  reduxForm({
    form: 'expertEducation',
    validate,
    enableReinitialize: true,
    // @ts-expect-error TS(2345): Argument of type 'typeof EducationForm' is not ass... Remove this comment to see the full error message
  })(EducationForm)
);
