import IconButton from '@mui/material/IconButton';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import MaterialIcon from '../Icon/MaterialIcon';
import Menu from '../Menu';

// Assume `MenuProps` is exported from the custom Menu component

interface IconMenuProps {
  children: ReactNode;
  buttonStyle?: React.CSSProperties;
  iconElement?: ReactNode;
  iconSize?: number;
  iconStyle?: React.CSSProperties;
  placement?: 'bottom-end' | 'bottom-start' | 'top-end' | 'top-start';
}

const IconMenu = (props: IconMenuProps): JSX.Element => {
  const {
    children,
    buttonStyle,
    iconElement,
    iconSize,
    placement = 'bottom-end',
    iconStyle,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const clonedChildren = useMemo(
    () =>
      React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child as React.ReactElement<any>, {
              onClick: (event: React.MouseEvent) => {
                child.props.onClick?.(event);
                handleClose();
              },
            })
          : child
      ),
    [children, handleClose]
  );

  return (
    <>
      <IconButton style={{ padding: 0, ...buttonStyle }} onClick={handleOpen}>
        {iconElement || (
          <MaterialIcon style={iconStyle} size={iconSize || 36} icon="more_horiz" color="silver" />
        )}
      </IconButton>
      <Menu {...rest} anchorEl={anchorEl} placement={placement} onClose={handleClose}>
        {clonedChildren}
      </Menu>
    </>
  );
};

export default IconMenu;
