import makeStyles from '@mui/styles/makeStyles';
import { useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { popup } from '@/actions/ui';
import { disableOtpAuth, unlockAccount } from '@/auth/store';
import Button from '@/components/Button/Button';
import Link from '@/components/Link';
import SelectMergeSourceProfileButton from '@/components/SelectMergeSourceProfileButton';
import { fetchProfileCandidates } from '@/expertrequest/store';
import { deleteProfile } from '@/profile/store';
import { RootState } from '@/store';
import { deleteUser, fetchUser, updateExpertState } from '@/store/user';
import { red500, white } from '@/theme/colors';

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: 15,
  },
  action: {
    marginTop: 10,
    textAlign: 'center',
  },
  actionLink: {
    display: 'block',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  button: {
    fontSize: 14,
    padding: '15px 22px',
  },
}));

interface ProfileManagementPanelProps {
  user: any;
  profile: any;
  onEditAdditionalInformation: () => void;
}

const connector = connect(
  ({ viewer }: RootState) => ({
    viewer,
  }),
  {
    updateExpertState,
    unlockAccount,
    deleteUser,
    deleteProfile,
    fetchProfileCandidates,
    popup,
    disableOtpAuth,
    fetchUser,
  }
);

const ProfileManagementPanel = ({
  user,
  profile,
  onEditAdditionalInformation,
  viewer,
  updateExpertState,
  unlockAccount,
  deleteUser,
  deleteProfile,
  fetchProfileCandidates,
  popup,
  disableOtpAuth,
  fetchUser,
}: ProfileManagementPanelProps & ConnectedProps<typeof connector>) => {
  const navigate = useNavigate();
  const s = useStyles();

  const handleUnlock = useCallback(() => {
    popup({
      title: 'Are you sure?',
      contents: 'It will unlock the account and send a reset ' + 'password link to the user email.',
      buttonAlignment: 'space-between',
      buttons: [
        { flat: true, label: 'Cancel' },
        {
          label: 'Unlock',
          secondary: true,
          callback: () => unlockAccount(user.id),
        },
      ],
    });
  }, [popup, unlockAccount, user?.id]);

  const handleReactivate = useCallback(() => {
    popup({
      title: 'Should this user be reactivated?',
      buttonAlignment: 'space-between',
      buttons: [
        { flat: true, label: 'Cancel' },
        {
          label: 'Reactivate',
          secondary: true,
          callback: () => updateExpertState({ id: user.id, expert_state: 'active' }),
        },
      ],
    });
  }, [popup, updateExpertState, user]);

  const handleDeactivate = useCallback(() => {
    popup({
      title: 'Should this user be deactivated?',
      buttonAlignment: 'space-between',
      buttons: [
        { flat: true, label: 'Cancel' },
        {
          label: 'Deactivate',
          backgroundColor: red500,
          style: { color: white },
          callback: () => updateExpertState({ id: user.id, expert_state: 'inactive' }),
        },
      ],
    });
  }, [popup, updateExpertState, user]);

  const handleDeactivate2FA = useCallback(() => {
    popup({
      title: 'Are you sure you want to disable two-factor authentication?',
      buttonAlignment: 'space-between',
      buttons: [
        { flat: true, label: 'Cancel' },
        {
          label: 'Yes, disable',
          backgroundColor: red500,
          style: { color: white },
          callback: () => {
            disableOtpAuth(user.id);
            fetchUser(user.username, { force: true, otpAuthEnabled: true });
          },
        },
      ],
    });
  }, [popup, fetchUser, disableOtpAuth, user]);

  const handleDeleteClick = useCallback(() => {
    fetchProfileCandidates(profile.id).then((candidates: any) => {
      let contents;

      if (candidates && candidates.length > 0) {
        contents = (
          <div>
            The candidates associated with this profile will also be deleted:
            {candidates.map((c: any) => (
              <p key={c.request_id}>
                <Link to={`/expert_request/${c.request_id}`}>{c.request_id}</Link>
              </p>
            ))}
          </div>
        );
      }

      popup({
        title: 'Should this profile be permanently deleted?',
        contents,
        buttonAlignment: 'space-between',
        buttons: [
          { flat: true, label: 'Cancel' },
          {
            label: 'Delete',
            backgroundColor: red500,
            style: { color: white },
            callback: async () => {
              if (user) {
                await deleteUser(user.id);
              } else {
                await deleteProfile(profile);
              }
              navigate('/dashboard');
            },
          },
        ],
      });
    });
  }, [fetchProfileCandidates, profile, popup, user, navigate, deleteUser, deleteProfile]);

  return (
    <div className={s.actions}>
      {user && (
        <Link
          force
          to={`/loginas?user=${user.username}`}
          className={s.actionLink}
          id="adminExpertLogin"
          disabled={user.id === viewer.id}
        >
          Log in as {user.name}
        </Link>
      )}

      {user?.locked && (
        <div className={s.action}>
          <Button className={s.button} startIcon="unlock" onClick={handleUnlock} fullWidth>
            Unlock Account
          </Button>
        </div>
      )}

      <div className={s.action}>
        <SelectMergeSourceProfileButton profile={profile} />
      </div>

      {user && (
        <div className={s.action}>
          {user.expert_state === 'active' && (
            <Button
              className={s.button}
              color="lightTan"
              startIcon="remove"
              onClick={handleDeactivate}
              fullWidth
              size="medium"
            >
              Deactivate
            </Button>
          )}
          {user.expert_state === 'inactive' && (
            <Button
              className={s.button}
              color="lightTan"
              startIcon="check"
              onClick={handleReactivate}
              fullWidth
              size="medium"
            >
              Reactivate
            </Button>
          )}
        </div>
      )}

      <div className={s.action}>
        <Button
          className={s.button}
          color="lightTan"
          startIcon="trash"
          onClick={handleDeleteClick}
          fullWidth
          size="medium"
        >
          Delete
        </Button>
      </div>
      {user?.otp_auth_enabled && (
        <div className={s.action}>
          <Button
            className={s.button}
            color="lightTan"
            startIcon="key"
            onClick={handleDeactivate2FA}
            fullWidth
            size="medium"
          >
            Deactivate 2FA
          </Button>
        </div>
      )}

      <div className={s.action}>
        <Button
          color="lightTan"
          startIcon="plus-circle"
          fullWidth
          style={{ fontSize: 14, padding: '15px 22px' }}
          onClick={onEditAdditionalInformation}
          size="medium"
        >
          Add Additional Information
        </Button>
      </div>
    </div>
  );
};

export default connector(ProfileManagementPanel);
