import clsx from 'clsx';
import { ReactNode } from 'react';
import { useParams } from 'react-router';

import { AssignmentTypes } from '@/assignment/AssignmentsManager';
import { DEFAULT_ASSIGNMENTS_LIMIT } from '@/assignment/queries/assignments';
import { Drawer } from '@/componentsv2/Drawer';
import useFindElementMinHeight from '@/hooks/useFindElementMinHeight';
import usePaginatedAssignments from '@/hooks/usePaginatedAssignments';
import { PaginatedAssignmentsList } from '@/openapi';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { arrayFromRange } from '@/utils/array';

import ExperienceRow from '../ExperienceRow';

const Contracts = ({
  title,
  isPreviousData,
  data,
  pagination,
  initialCount,
  showUpdatingIndicator,
}: {
  title: string;
  isPreviousData: boolean;
  data?: PaginatedAssignmentsList;
  pagination?: ReactNode;
  initialCount: number;
  showUpdatingIndicator?: boolean;
}) => {
  const { containerRef, containerMinHeight } = useFindElementMinHeight();

  return (
    <Drawer.Section
      title={title}
      showUpdatingIndicator={showUpdatingIndicator}
      className="mt-20 pb-16"
    >
      <div
        className={clsx('m-16 flex flex-col gap-10', {
          'animate-pulse opacity-50': isPreviousData,
        })}
        ref={containerRef}
        style={{ minHeight: `${containerMinHeight}px` }}
      >
        {data?.results?.length
          ? data.results.map((contract) => (
              <ExperienceRow
                experience={contract}
                key={contract.assignment_id}
                to={
                  '../' +
                  (contract.assignment_type === 'Project'
                    ? PROFILE_ROUTES().project(contract.assignment_parent_id)
                    : PROFILE_ROUTES().proposal(contract.assignment_parent_id))
                }
              />
            ))
          : arrayFromRange({ max: initialCount }).map((i) => <ExperienceRow key={i} isLoading />)}
      </div>
      {pagination}
    </Drawer.Section>
  );
};

export const ContractsSection = ({
  title,
  assignmentTypes,
  initialCount,
}: {
  title: string;
  assignmentTypes: AssignmentTypes;
  initialCount: number;
}) => {
  const { jobId } = useParams();
  const { data, count, isPreviousData, pagination, isRefetching } = usePaginatedAssignments({
    jobId: jobId ?? '',
    assignmentTypes,
  });

  if (initialCount === 0 && count === 0) {
    return null;
  }

  return (
    <Contracts
      key={count}
      title={title}
      isPreviousData={isPreviousData}
      data={data}
      pagination={pagination}
      initialCount={Math.min(initialCount, DEFAULT_ASSIGNMENTS_LIMIT)}
      showUpdatingIndicator={isRefetching}
    />
  );
};
