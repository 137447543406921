import { redirect } from 'react-router';

import ChangePassword from '@/auth/components/ChangePassword';
import LayoutPage from '@/components/Layout/LayoutPage';
import { LegacyRoute } from '@/routes/routesMiddleware';

const route: LegacyRoute = {
  path: '/change_password',
  async action({ store, query }) {
    const next = query.get('next') || undefined;
    const { viewer } = store.getState();

    if (!viewer.id) {
      return redirect('/login');
    }

    document.title = 'Change Password';
    return (
      <LayoutPage hideSearch showNewRequest={false} showReviewConsultation={false}>
        <ChangePassword next={next} />
      </LayoutPage>
    );
  },
};

export default route;
