import moment, { Moment } from 'moment-timezone';

import ActionTypes from '@/actions/ActionTypes';
import { Viewer } from '@/core/viewer';
import { add, mergeAt, removeAt, updateAt } from '@/utils/reducer';

import {
  PROFILE__COUNT,
  PROFILE__COUNT_LOADING,
  PROFILE__DELETE,
  PROFILE__LIST,
  PROFILE__LIST_LOADED,
  PROFILE__LIST_LOADING,
  PROFILE__LIST_RESET,
  PROFILE__REMOVE_EDUCATION,
  PROFILE__REMOVE_EXPERIENCE,
  PROFILE__SET_CSV_PREVIEW,
  PROFILE__UPDATE,
  PROFILE__UPDATE_EDUCATION,
  PROFILE__UPDATE_EXPERIENCE,
} from '.';

const { ADDRESS__REMOVE_ADDRESS, ADDRESS__SET_ADDRESS } = ActionTypes;

interface Edge {
  node: { id: string };
}

const initialCollectionState = {
  edges: [] as Edge[],
  pageInfo: { hasNextPage: true } as { hasNextPage: boolean } | undefined,
  loading: false,
};

interface Address {
  address: string;
  display: string;
  primary: boolean;
  verified: boolean;
}

export interface Profile extends NonNullable<Viewer['profile']> {
  id: string;
  url_endpoint: string;
  experiences?: Experience[];
  education?: Education[];
  sectors?: { id: string }[];
  regions?: { id: string }[];
  sector_ids?: string[];
  region_ids?: string[];
  phones: Address[];
  emails: Address[];
  cover_url?: string;
  credit_rate: number;
  group_keywords?: { group_id: string; keywords: string[] }[];
  can_request_consultation: boolean;
}

interface Experience {
  id: string;
  start_date: string;
}

interface Education {
  id: string;
}

interface ProfilesState {
  csvPreviews: object;
  fullProfiles: { [key: string]: Profile };
  collections: {
    conflicts: typeof initialCollectionState;
    [key: string]: typeof initialCollectionState;
  };
  counts: { [key: string]: { value?: number; expire?: Moment } };
  $$query_meta$$: { [key: string]: { expiresAt: string; result: object } };
}

const initialState: ProfilesState = {
  csvPreviews: {},
  fullProfiles: {},
  collections: {
    conflicts: initialCollectionState,
  },
  counts: {},
  $$query_meta$$: {},
};

type ProfilesReducerAction = Omit<ProfileReducerAction, 'type'> & {
  type:
    | ProfileReducerAction['type']
    | CollectionReducerAction['type']
    | CountReducerAction['type']
    | typeof PROFILE__SET_CSV_PREVIEW;
  queryKey?: string;
  count: string;
  preview: { url: string };
  collection: string;
  edges: Edge[];
  value: unknown;
};

export default function profilesReducer(
  state = initialState,
  action: ProfilesReducerAction
): ProfilesState {
  switch (action.type) {
    case PROFILE__SET_CSV_PREVIEW:
      return {
        ...state,
        csvPreviews: {
          ...state.csvPreviews,
          [action.preview.url]: action.preview,
        },
      };
    case PROFILE__LIST_LOADING:
    case PROFILE__LIST:
    case PROFILE__LIST_LOADED:
    case PROFILE__LIST_RESET: {
      const col = action.collection;
      return {
        ...state,
        collections: {
          ...state.collections,
          [col]: collectionReducer(state.collections[col], action as CollectionReducerAction),
        },
      };
    }
    case PROFILE__DELETE: {
      const newProfiles = { ...state.fullProfiles };
      delete newProfiles[action.profile.id];
      delete newProfiles[action.profile.url_endpoint];
      return {
        ...state,
        fullProfiles: newProfiles,
        collections: Object.entries(state.collections).reduce(
          (acc, [key, collection]) => ({
            ...acc,
            [key]: collectionReducer(collection, action as CollectionReducerAction),
          }),
          { conflicts: initialCollectionState }
        ),
        counts: {}, // reset counts
      };
    }
    case PROFILE__COUNT_LOADING:
    case PROFILE__COUNT: {
      const { count } = action;
      return {
        ...state,
        counts: {
          ...state.counts,
          [count]: countReducer(state.counts[count], action as CountReducerAction),
        },
      };
    }
    case PROFILE__UPDATE:
    case PROFILE__REMOVE_EXPERIENCE:
    case PROFILE__UPDATE_EXPERIENCE:
    case PROFILE__REMOVE_EDUCATION:
    case PROFILE__UPDATE_EDUCATION:
    case ADDRESS__REMOVE_ADDRESS:
    case ADDRESS__SET_ADDRESS:
      return (() => {
        const id = action.profile ? action.profile.id : action.profileId;
        const urlEndpoint = action.profile && action.profile.url_endpoint;

        const newState = {
          ...state,
          fullProfiles: {
            ...state.fullProfiles,
            [id]: profileReducer(state.fullProfiles[id], action as ProfileReducerAction),
          },
        };

        if (urlEndpoint) {
          newState.fullProfiles[urlEndpoint] = newState.fullProfiles[id];
        }

        if (action.queryKey) {
          newState.$$query_meta$$ = {
            ...newState.$$query_meta$$,
            [action.queryKey]: {
              expiresAt: moment().add(1, 'minute').toISOString(),
              result: newState.fullProfiles[id],
            },
          };
        }

        return newState;
      })();
    default:
      return state;
  }
}

type ExperienceReducerAction = {
  type: typeof PROFILE__REMOVE_EXPERIENCE | typeof PROFILE__UPDATE_EXPERIENCE;
  experience: Experience;
  id: string;
};

function experienceReducer(state: Experience[] = [], action: ExperienceReducerAction) {
  const experienceId = action.experience ? action.experience.id : action.id;
  const index = state.findIndex((item) => item.id === experienceId);
  switch (action.type) {
    case PROFILE__REMOVE_EXPERIENCE:
      return removeAt(state, index);
    case PROFILE__UPDATE_EXPERIENCE: {
      const { experience } = action;
      if (index < 0) {
        // EKN-355: Sort added experience
        return add(state, experience).sort((e1, e2) =>
          moment(e1.start_date).isBefore(moment(e2.start_date)) ? 1 : -1
        );
      }
      return updateAt(state, index, experience);
    }
    default:
      return state;
  }
}

type EducationReducerAction = {
  type: typeof PROFILE__REMOVE_EDUCATION | typeof PROFILE__UPDATE_EDUCATION;
  education: Education;
  id: string;
};

function educationReducer(state: Education[] = [], action: EducationReducerAction) {
  const educationId = action.education ? action.education.id : action.id;
  const index = state.findIndex((item) => item.id === educationId);
  switch (action.type) {
    case PROFILE__REMOVE_EDUCATION:
      return removeAt(state, index);
    case PROFILE__UPDATE_EDUCATION: {
      const { education } = action;
      return index < 0 ? add(state, education) : updateAt(state, index, education);
    }
    default:
      return state;
  }
}

type ProfileReducerAction = {
  type:
    | typeof PROFILE__UPDATE
    | typeof PROFILE__REMOVE_EDUCATION
    | typeof PROFILE__UPDATE_EDUCATION
    | typeof PROFILE__REMOVE_EXPERIENCE
    | typeof PROFILE__UPDATE_EXPERIENCE;
  profile: Profile;
  profileId: string;
  transport: string;
  id: string;
  experience: Experience;
  education: Education;
};

function profileReducer(state: Profile = {} as Profile, action: ProfileReducerAction): Profile {
  switch (action.type) {
    case ADDRESS__REMOVE_ADDRESS:
    case ADDRESS__SET_ADDRESS: {
      const prop =
        action.transport === 'email' ? 'emails' : action.transport === 'phone' ? 'phones' : '';

      if (!state || !prop) return state;

      return {
        ...state,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [prop]: addressesReducer(state[prop], action),
      };
    }
    case PROFILE__REMOVE_EXPERIENCE:
    case PROFILE__UPDATE_EXPERIENCE:
      return {
        ...state,
        experiences: experienceReducer(state.experiences || [], action as ExperienceReducerAction),
      };
    case PROFILE__REMOVE_EDUCATION:
    case PROFILE__UPDATE_EDUCATION:
      return {
        ...state,
        education: educationReducer(state.education || [], action as EducationReducerAction),
      };
    case PROFILE__UPDATE: {
      const { profile } = action;
      return {
        ...state,
        ...profile,
        sector_ids: profile.sectors && profile.sectors.map((v) => v.id),
        region_ids: profile.regions && profile.regions.map((v) => v.id),
      };
    }
    default:
      return state;
  }
}

type CollectionReducerAction = {
  type:
    | typeof PROFILE__LIST
    | typeof PROFILE__LIST_LOADING
    | typeof PROFILE__LIST_LOADED
    | typeof PROFILE__LIST_RESET
    | typeof PROFILE__DELETE;
  edges: Edge[];
  profile: Profile;
  reset?: boolean;
  pageInfo?: { hasNextPage: boolean };
  collection: string;
};

function collectionReducer(state = initialCollectionState, action: CollectionReducerAction) {
  switch (action.type) {
    case PROFILE__LIST_RESET:
      return initialCollectionState;
    case PROFILE__LIST: {
      const { reset, edges, pageInfo } = action;
      if (reset) {
        return { ...state, edges, pageInfo, resetAt: new Date() };
      }
      return { ...state, edges: [...state.edges, ...(edges || [])], pageInfo };
    }
    case PROFILE__LIST_LOADING:
      return { ...state, loading: true };
    case PROFILE__LIST_LOADED:
      return { ...state, loading: false };
    case PROFILE__DELETE:
      return {
        ...state,
        edges: state.edges.filter(
          (e: { node: { id: string } }) => e.node?.id !== action.profile.id
        ),
      };
    default:
      return state;
  }
}

type CountReducerAction = {
  type: typeof PROFILE__COUNT | typeof PROFILE__COUNT_LOADING | typeof PROFILE__LIST_RESET;
  value: unknown;
};

function countReducer(state = {}, action: CountReducerAction) {
  switch (action.type) {
    case PROFILE__COUNT_LOADING:
      return {
        loading: true,
      };
    case PROFILE__COUNT:
      return {
        expire: moment().add(10, 'minutes'),
        value: action.value,
      };
    case PROFILE__LIST_RESET:
      return {};
    default:
      return state;
  }
}

type AddressReducerAction = {
  type: typeof ADDRESS__REMOVE_ADDRESS | typeof ADDRESS__SET_ADDRESS;
  addresses: Address[];
  address: Address;
};

function addressesReducer(state: Address[] = [], action: AddressReducerAction) {
  switch (action.type) {
    case ADDRESS__REMOVE_ADDRESS:
      return [...action.addresses];
    case ADDRESS__SET_ADDRESS: {
      const { address } = action;

      let newState = [...(state || [])];
      if (address.primary) {
        newState = newState.map((a) => ({ ...a, primary: false }));
      }

      const index = state?.findIndex((item: Address) => item.address === address.address) || -1;
      if (index < 0) {
        return [...newState, address];
      }

      return mergeAt(newState, index, address);
    }
    default:
      return state;
  }
}
