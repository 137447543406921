import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { CSSProperties } from 'react';
import { Helmet } from 'react-helmet';
import { ConnectedProps, connect } from 'react-redux';
import { useParams, useSearchParams } from 'react-router';

import { gengql } from '@/__generated__';
import { ExpertRequestType } from '@/__generated__/graphql';
import Body from '@/components/Body';
import Button from '@/components/Button/Button';
import Customers from '@/components/Customers';
import EmptyMessage from '@/components/EmptyMessage/EmptyMessage';
import FaqLink from '@/components/FaqLink';
import FAIcon from '@/components/Icon/FAIcon';
import Layout from '@/components/Layout';
import Link from '@/components/Link';
import LongText from '@/components/LongText';
import MediaQuery from '@/components/MediaQuery';
import RegionList from '@/components/RegionList';
import SectorList from '@/components/SectorList';
import ConfirmationRow from '@/components/Wizard/ConfirmationRow';
import { hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import { Viewer } from '@/core/viewer';
import ReferFriend from '@/expertrequest/components/ReferFriend';
import { isCallType, isOpportunityType } from '@/expertrequest/store';
import NotFoundPage from '@/pages/NotFoundPage';
import { RootState } from '@/store';
import { darkGreen, sand, white } from '@/theme/colors';
import { SCREEN_MD } from '@/theme/screens';
import { queryPart } from '@/utils';
import { DocumentNodeResult } from '@/utils/gql';

import s from './ExpertRequestPublic.module.scss';
import promoImageURL from './promo.jpg';

const GET_EXPERT_REQUEST = gengql(/* GraphQL */ `
  query getPublicExpertRequest($id: String!) {
    expertRequest(id: $id) {
      id
      html_url
      created_at
      name
      description
      focus_areas
      qualifications {
        id
        query
        response_type
        required
      }
      questions {
        id
        query
        response_type
        required
      }
      state
      slug
      public_html_url
      regions {
        id
        name
      }
      sectors {
        id
        name
      }
      project {
        id
        group {
          id
          name
        }
      }
      public_group_name
      group_about
      er_type
      job_scope
      opportunity_location
      unpaid
      disclosure
    }
  }
`);

type ExpertRequest = DocumentNodeResult<typeof GET_EXPERT_REQUEST>['expertRequest'];

interface ExplainerBoxProps {
  expertRequest: ExpertRequest;
  viewer: Viewer;
  tags: string[];
}

const ExplainerBox = ({ expertRequest, viewer, tags }: ExplainerBoxProps) => {
  const isSignedIn = viewer && viewer.id;

  return (
    <div className={s.explainerBox}>
      <FAIcon iconSet="fal" icon="lightbulb" size={50} color={white} />

      <div className={s.explainerBoxBody}>
        <div className={s.explainerBoxTitle}>
          {`${
            expertRequest.er_type && isOpportunityType(expertRequest.er_type)
              ? 'Would you like to be considered for this opportunity?'
              : `Consultations like this typically pay $250/hr. ${
                  !isSignedIn ? '1st calls usually result in additional calls.' : ''
                }`
          }`}
        </div>
        <div className={s.explainerBoxText}>
          {isSignedIn ? (
            <div>
              {expertRequest.er_type && isOpportunityType(expertRequest.er_type)
                ? 'If it seems like the opportunity below would be a fit, click “I’m Interested” below.'
                : 'Most first calls result in several additional consultation calls.'}
            </div>
          ) : (
            <div>
              OnFrontiers is a next generation peer-to-peer learning platform that provides easy
              access to Experts around the globe. Chances are, you probably have significant
              experience with a particular sector, company, or region.
              <br />
              <br />
              We receive many opportunities just like this one every day from our clients and we
              often recruit top talent from external networks to meet their needs. Becoming an
              OnFrontiers expert is free and the application takes about{' '}
              <span>
                {expertRequest.er_type && isOpportunityType(expertRequest.er_type)
                  ? '10 minutes.'
                  : '5-7 minutes.'}
              </span>
            </div>
          )}
        </div>
        <Link
          to={`${expertRequest.public_html_url}/request_add${queryPart({
            t: tags,
          })}`}
        >
          <Button size="normal" style={{ marginTop: 10 }}>
            I’m Interested
          </Button>
        </Link>
      </div>
    </div>
  );
};

interface FAQProps {
  erType: ExpertRequestType;
}

const FAQ = ({ erType }: FAQProps) => {
  const isCall = isCallType(erType);
  const articleUrlPrefix = 'https://support.onfrontiers.com/en/articles/';

  // FAQ component hidden as per OT-502
  return (
    <div className={s.faq} style={{ display: 'none' }}>
      <h3>Expert Consultation FAQ</h3>

      <div className={s.faqList}>
        <FaqLink
          title="Will this engagement conflict with my current employment?"
          url={`${articleUrlPrefix}5698180-will-this-call-conflict-with-my-current-employment`}
        />

        <FaqLink
          title="What is the time commitment?"
          url={`${articleUrlPrefix}5698184-what-is-the-time-commitment`}
          disable={!isCall}
        />

        <FaqLink
          title="Will the call be recorded?"
          url={`${articleUrlPrefix}5698188-will-the-call-be-recorded`}
          disable={!isCall}
        />

        <FaqLink
          title="How will I be paid?"
          url={`${articleUrlPrefix}705853-how-do-i-get-paid-as-an-onfrontiers-expert`}
        />

        <FaqLink
          title="How much will I be paid?"
          url={`${articleUrlPrefix}1346873-how-much-will-i-get-paid-where-do-i-set-my-rate`}
          disable={!isCall}
        />

        <FaqLink
          title="How will I be connected to the client?"
          url={`${articleUrlPrefix}5698189-how-will-i-be-connected-to-the-client`}
          disable={!isCall}
        />
      </div>
    </div>
  );
};

interface IconTextProps {
  icon: string;
  text?: string;
  style?: CSSProperties;
}

const IconText = ({ icon, text, style }: IconTextProps) => {
  return text ? (
    <div className={s.iconText} style={style}>
      <FAIcon icon={icon} size={24} style={{ marginRight: 10 }} color={darkGreen} />
      {text}
    </div>
  ) : null;
};

const connector = connect((state: RootState) => ({
  viewer: state.viewer,
}));

const ExpertRequestPublic = ({ viewer }: ConnectedProps<typeof connector>) => {
  const params = useParams();
  const [query] = useSearchParams();
  const expertRequestId = params.id!;
  const action = query.get('action') || undefined;
  const tags = [...query.getAll('t[]'), ...query.getAll('t')];

  const { data, error } = useQuery(GET_EXPERT_REQUEST, {
    variables: {
      id: expertRequestId,
    },
  });
  if (hasErrorCode(error, ERROR_CODES.EXPERT_REQUEST_NOT_FOUND)) return <NotFoundPage />;

  const expertRequest = data?.expertRequest;
  if (!expertRequest) return null;

  const {
    name,
    description,
    regions,
    sectors,
    focus_areas: focusAreas,
    public_group_name: publicGroupName,
    state,
    questions,
    qualifications,
    group_about: groupAbout,
    job_scope: jobScope,
    opportunity_location: opportunityLocation,
    disclosure,
    project,
    er_type: erType,
  } = expertRequest;

  if (state === 'closed') {
    return (
      <Layout verticalCenter>
        <Helmet>
          <title>{name}</title>
        </Helmet>
        <Body>
          <EmptyMessage
            border={false}
            style={{ padding: '30px 0 15px' }}
            icon={<FAIcon icon="archive" color={sand} size={52} />}
            title="This opportunity has been closed."
            body="Our client has concluded scheduling consultations for this opportunity."
            action={
              viewer && viewer.id ? (
                <Button size="large" href="/dashboard">
                  Go to Dashboard →
                </Button>
              ) : (
                <Button size="large" href={`/signup/expert${queryPart({ t: tags })}`}>
                  Sign Up to Receive More Opportunities
                </Button>
              )
            }
          />
        </Body>
      </Layout>
    );
  }

  const groupName =
    (disclosure === 'full' && project?.group ? project.group.name : publicGroupName?.trim()) ||
    undefined;

  return (
    <Layout contentClassName={s.layoutContent}>
      <Helmet>
        <meta name="description" content={expertRequest.description || undefined} />
        <meta property="og:title" content={expertRequest.name || undefined} />
        <meta property="og:description" content={expertRequest.description || undefined} />
        <meta property="og:image" content={promoImageURL} />
        <meta property="og:url" content={expertRequest.public_html_url} />
        <link rel="canonical" href={expertRequest.public_html_url} />
        <title>{name}</title>
      </Helmet>
      <Body className={s.body}>
        <div className={s.content}>
          <div className={s.details}>
            <h2 className={s.name}>{name}</h2>

            <div style={{ display: 'flex', marginTop: 10 }}>
              <IconText
                icon="map-marker-alt"
                text={opportunityLocation || undefined}
                style={{ marginRight: 30 }}
              />
              <IconText icon="briefcase" text={groupName} />
            </div>

            <div className={s.gutter} />

            <div>
              {(groupName || groupAbout) && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Client profile"
                >
                  {groupName && <span>{`Client at ${groupName}`}</span>}
                  {groupAbout && (
                    <div className={s.groupAbout}>
                      <LongText text={groupAbout} />
                    </div>
                  )}
                </ConfirmationRow>
              )}

              {!!jobScope && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Job Scope"
                >
                  <LongText text={jobScope} />
                </ConfirmationRow>
              )}

              <ConfirmationRow
                separatorBelow
                separator
                titleClassName={s.confirmationRowTitle}
                title={
                  expertRequest.er_type && isOpportunityType(expertRequest.er_type)
                    ? 'Opportunity details'
                    : 'Project details'
                }
              >
                <SectorList
                  sectors={(sectors || []).map((s) => s.name)}
                  style={{ marginTop: 10 }}
                />
                <RegionList regions={(regions || []).map((s) => s.name)} style={{ marginTop: 5 }} />
              </ConfirmationRow>

              {focusAreas && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Areas of discussion"
                >
                  {focusAreas.join(', ')}
                </ConfirmationRow>
              )}

              <ConfirmationRow
                separatorBelow
                separator
                titleClassName={s.confirmationRowTitle}
                title="Type of expert client is requesting"
              >
                <LongText text={description || undefined} />
              </ConfirmationRow>

              {questions && questions.length > 0 && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Client questions"
                >
                  {questions.map((q: any) => (
                    <div className={s.question} key={q.query}>
                      {q.query}
                    </div>
                  ))}
                </ConfirmationRow>
              )}

              {qualifications && qualifications.length > 0 && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Client desired qualifications"
                >
                  {qualifications.map((q: any) => (
                    <div className={s.question} key={q.query}>
                      {q.query}
                    </div>
                  ))}
                </ConfirmationRow>
              )}
            </div>

            <MediaQuery maxWidth={SCREEN_MD}>
              {(isMobileVersion: any) => (
                <div
                  className={cx(s.submit, {
                    [s.sticky]: isMobileVersion && action !== 'refer',
                  })}
                >
                  <div>
                    {`If this opportunity seems like a fit for your experience, click the button below and our research team will be notified.${
                      expertRequest.er_type && isOpportunityType(expertRequest.er_type)
                        ? ' Introduction calls are not paid.'
                        : ''
                    }`}
                  </div>

                  <Link
                    to={`${expertRequest.public_html_url}/request_add${queryPart({ t: tags })}`}
                  >
                    <Button size={isMobileVersion ? 'normal' : 'large'} style={{ marginTop: 10 }}>
                      I’m Interested
                    </Button>
                  </Link>
                </div>
              )}
            </MediaQuery>

            {erType && <FAQ erType={erType} />}
          </div>

          <div>
            <ExplainerBox expertRequest={expertRequest} viewer={viewer} tags={tags} />

            <ReferFriend
              expertRequestId={expertRequest.id}
              action={action as 'refer'}
              tags={tags}
            />
          </div>
        </div>
      </Body>

      <Customers />
    </Layout>
  );
};

export default connector(ExpertRequestPublic);
