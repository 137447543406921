import { Helmet } from 'react-helmet';
import { redirect } from 'react-router';

import { gengql } from '@/__generated__';
import { CandidateState, IsGoodMatch } from '@/__generated__/graphql';
import InaccessibleArchived from '@/components/InaccessibleArchived/InaccessibleArchived';
import LayoutPage from '@/components/Layout/LayoutPage';
import RequestProjectAccess from '@/components/RequestProjectAccess/RequestProjectAccess';
import { APIError, hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import { Viewer } from '@/core/viewer';
import {
  fetchExpertRequest,
  fetchExpertRequestCandidates,
  requestAddExpertRequestCandidate,
  updateExpertRequestCandidate,
} from '@/expertrequest/store';
import NotFoundPage from '@/pages/NotFoundPage';
import {
  ActionContext,
  LegacyRoute,
  redirectIfAgreementsNotAccepted,
  redirectIfEmailNotVerified,
} from '@/routes/routesMiddleware';
import { parseEnum } from '@/utils/types';

import { Candidate, ExpertRequest } from '..';
import ExpertRequestAddRequest from '../components/ExpertRequestAddRequest';
import ExpertAuthPage from './ExpertAuthPage';
import ReferralAuthPage from './ReferralAuthPage';
import ExpertRequestPrivate, { SectionType } from './expertRequestPrivate/ExpertRequestPrivate';
import ExpertRequestPublic from './expertRequestPublic/ExpertRequestPublic';

const path = '/expert_request/:id';
const ALREADY_ADDED = 'GraphQL Error: expert request candidate already added';

interface RequestAddPageProps {
  expertRequestId: string;
  viewer: Viewer;
  signup?: boolean;
  tags?: string[];
}

const RequestAddPage = ({
  expertRequestId,
  viewer,
  signup,
  tags,
}: RequestAddPageProps): JSX.Element => {
  if (!viewer.id) {
    return <ExpertAuthPage expertRequestId={expertRequestId} signup={signup} tags={tags} />;
  }

  return <ExpertRequestAddRequest expertRequestId={expertRequestId} />;
};

interface RequestProjectAccessPageProps {
  query: URLSearchParams;
  viewer: Viewer;
  expertRequestId: string;
}

const RequestProjectAccessPage = ({
  query,
  viewer,
  expertRequestId,
}: RequestProjectAccessPageProps): JSX.Element => {
  return (
    <LayoutPage showNav selected="expert_requests">
      <Helmet>
        <title>Request Project Access</title>
      </Helmet>
      <RequestProjectAccess
        viewer={viewer}
        path="expert_request"
        query={query}
        expertRequestId={expertRequestId}
      />
    </LayoutPage>
  );
};

async function privateExpertRequestAction(
  id: string,
  { store, location, query, permission }: ActionContext
) {
  const selectedSection = query.get('section') || undefined;
  const isGoodMatch = parseEnum(IsGoodMatch, query.get('is_good_match'));
  const candidateId = query.get('candidate_id') || undefined;

  const shouldRedirect =
    redirectIfEmailNotVerified(store) || redirectIfAgreementsNotAccepted(store, location);

  if (shouldRedirect) return shouldRedirect;

  const { viewer } = store.getState();
  if (!viewer.id)
    return <RequestProjectAccessPage query={query} viewer={viewer} expertRequestId={id} />;

  let expertRequest: ExpertRequest;
  try {
    [expertRequest] = await Promise.all(
      [
        fetchExpertRequest(id),
        fetchExpertRequestCandidates(id, 'suggested', true),
        fetchExpertRequestCandidates(id, 'matched', true),
      ].map(store.dispatch)
    );
  } catch (e: unknown) {
    if (hasErrorCode(e, ERROR_CODES.EXPERT_REQUEST_NOT_FOUND)) return <NotFoundPage />;
    if (!(e as APIError).isPermissionError) throw e;
    return <RequestProjectAccessPage query={query} viewer={viewer} expertRequestId={id} />;
  }

  const canViewArchived = await permission.allowed('expert_request', 'view_archived', id);

  if (expertRequest.archived && canViewArchived) {
    return (
      <>
        <Helmet>
          <title>{expertRequest.name}</title>
        </Helmet>
        <InaccessibleArchived selectedTab="expert_requests" entity="request" />
      </>
    );
  }

  if (candidateId && isGoodMatch) {
    try {
      const candidateData: Candidate = {
        id: candidateId,
        client_note: { is_good_match: isGoodMatch },
      };
      if (isGoodMatch === IsGoodMatch.Yes) {
        candidateData.state = CandidateState.Matched;
      }
      await store.dispatch(updateExpertRequestCandidate(id, candidateData));
    } catch (err) {
      console.warn(err);
    }
  }

  const targetSection = selectedSection || 'experts';

  const fetchMoreCandidates = async (
    type: 'matched' | 'suggested',
    pageInfo: Record<string, any>
  ) => {
    if (pageInfo?.hasNextPage) {
      await store.dispatch(
        fetchExpertRequestCandidates(id, type, false, pageInfo.cursor as string)
      );
    }
  };

  return (
    <ExpertRequestPrivate
      section={targetSection as SectionType}
      expertRequestId={expertRequest.id}
      expertRequest={expertRequest}
      candidateId={candidateId}
      isGoodMatch={isGoodMatch}
      fetchMoreCandidates={fetchMoreCandidates}
    />
  );
}

const route: LegacyRoute = {
  path,
  children: [
    {
      path,
      async action(context: ActionContext): Promise<Response | JSX.Element> {
        const { id = '' } = context.params;

        if (/^\d+$/.test(id)) {
          return await privateExpertRequestAction(id, context);
        }

        return <ExpertRequestPublic />;
      },
    },
    {
      path: `${path}/request_add/:auth?/:signupType?`,
      async action({ store, params, query }: ActionContext): Promise<Response | JSX.Element> {
        const { auth } = params;
        const id = params.id!;
        const tags = query.getAll('t');

        const { viewer } = store.getState();
        if (!viewer.id && !auth) {
          return redirect(`/expert_request/${id}/request_add/signup`);
        }

        if (viewer.id && auth) {
          return redirect(`/expert_request/${id}/request_add`);
        }

        if (!viewer.id && auth) {
          return (
            <RequestAddPage
              viewer={viewer}
              expertRequestId={id}
              signup={auth === 'signup'}
              tags={tags}
            />
          );
        }

        try {
          await store.dispatch(
            requestAddExpertRequestCandidate({
              expert_request_id: id,
            })
          );
        } catch (err: unknown) {
          if ((err as Error).message === ALREADY_ADDED) {
            return <RequestAddPage viewer={viewer} expertRequestId={id} />;
          }
          throw err;
        }

        return <RequestAddPage viewer={viewer} expertRequestId={id} />;
      },
    },
    {
      path: `${path}/refer/:auth?/:signupType?`,
      element: <ReferralAuthPage />,
      async action({
        store,
        params,
        graphqlClient,
      }: ActionContext): Promise<Response | JSX.Element | undefined> {
        const id = params.id!;
        const { auth } = params;
        const { viewer } = store.getState();

        try {
          const data = await graphqlClient.query(
            gengql(/* GraphQL */ `
              query expertRequestPageGetExpertRequest($id: String!) {
                expertRequest(id: $id) {
                  id
                  slug
                }
              }
            `),
            { id }
          );
          const expertRequest = data.expertRequest;
          if (!viewer.id && !auth) {
            return redirect(`/expert_request/${expertRequest.slug}/refer/signup`);
          }

          if (viewer.id) {
            return redirect(`/expert_request/${expertRequest.slug}`);
          }
        } catch (e: unknown) {
          if (hasErrorCode(e, ERROR_CODES.EXPERT_REQUEST_NOT_FOUND)) {
            return <NotFoundPage />;
          }
          throw e;
        }
      },
    },
  ],
};

export default route;
