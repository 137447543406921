import Typography from '@mui/material/Typography';
import { Link } from 'react-router';

import TagGroup from '@/componentsv2/TagGroup';
import { useProfileId } from '@/hooks/store';
import { useKnowledgesByTypeQuery } from '@/knowledge/queries/knowledge';
import { accrualSummarySelector } from '@/knowledge/store/accrualSummarySlice';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';
import { knowledgeCapitalized } from '@/utils/capitalization';

import ExpertTag from '../ExpertTag';
import { ProfileContainer } from '../ProfileContainer';

export const TITLE = 'Complementary Skills';
const PLACEHOLDER = `More experiences required to display ${TITLE}`;

const GeneralKnowledge = () => {
  const profileId = useProfileId();
  const { data, isLoading, isFetched, isRefetching } = useKnowledgesByTypeQuery({
    profileId,
    knowledgeTypes: ['Transversal Skill|Intrinsic Ability'],
    page: 1,
    ordering: '-value',
  });
  const hasData = data?.results?.length;
  const { isLoading: accrualIsLoading, accrualIsStale } = useAppSelector(accrualSummarySelector);

  return (
    <ProfileContainer>
      <ProfileContainer.Header
        showUpdatingIndicator={isRefetching || (accrualIsLoading && accrualIsStale)}
        to={PROFILE_ROUTES().knowledgeType('general')}
      >
        {TITLE}
      </ProfileContainer.Header>
      <ProfileContainer.Body>
        <TagGroup
          collapsible
          isLoading={isLoading}
          showPlaceholder={!hasData && isFetched}
          placeholder={
            <Typography variant="body2" color="grey.500">
              {PLACEHOLDER}
            </Typography>
          }
        >
          {data?.results?.map((knowledge) => {
            return (
              <Link key={knowledge.name} to={PROFILE_ROUTES().knowledge(knowledge.name ?? '')}>
                <ExpertTag value={knowledge.value} isClickable>
                  {knowledgeCapitalized(knowledge.name)}
                </ExpertTag>
              </Link>
            );
          })}
        </TagGroup>
      </ProfileContainer.Body>
    </ProfileContainer>
  );
};

export default GeneralKnowledge;
