import Divider from '@mui/material/Divider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ConnectedProps, connect } from 'react-redux';

import { SignupType, formatAuthPath, isValidPassword, signup } from '@/auth';
import AuthLayout from '@/auth/components/AuthLayout';
import { PasswordText } from '@/auth/components/PasswordTextField';
import Button from '@/components/Button';
import { Checkbox, PhoneInput, TextField } from '@/components/FormAdapters';
import Link from '@/components/Link';
import { ErrEmailAlreadyExists, ErrPhoneAlreadyExists } from '@/core/address';
import { policies } from '@/core/agreements';
import { APIError } from '@/core/api';
import { getTracking } from '@/core/tracking';
import { GroupDomain } from '@/group';
import { useApp } from '@/hooks/useAppContext';
import { RootState } from '@/store';
import { isEmailValid, isPhoneValid, normalizeSpace } from '@/utils';
import { hasOnlyLetters } from '@/utils/reducer';

import JoinNetwork, { useStyles } from './JoinNetwork';

interface SignupProps {
  domain?: GroupDomain;
  tags?: string[];
  signupType: SignupType;
  nextUrl?: string;
  signinLink?: string;
  signinNextUrl?: string;
  invite?: string;
  defaultEmail?: string;
  title?: string;
  shortAboutText?: string;
  longAboutText?: string;
  children?: React.ReactNode;
}

const connector = connect((state: RootState) => ({
  viewer: state.viewer,
}));

const EXPERT_PART_POLICY = policies['expert-participation-agreement'];
const TOU_POLICY = policies['terms-of-use'];
const PRIVACY_POLICY = policies.privacy;

const Signup = ({
  viewer,
  domain,
  tags,
  signupType,
  nextUrl,
  signinLink,
  signinNextUrl,
  invite,
  defaultEmail,
  title,
  shortAboutText,
  longAboutText,
  children,
}: SignupProps & ConnectedProps<typeof connector>) => {
  const { graphql } = useApp();

  const isExpertSignup = signupType === 'expert';
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const s = useStyles();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return Promise.resolve();
    }

    const token = await executeRecaptcha('signup');
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const initialValues = useMemo(
    () => ({
      email: defaultEmail,
    }),
    [defaultEmail]
  );

  const expertAgreements = useMemo(() => {
    return isExpertSignup
      ? [
          {
            policy_code: EXPERT_PART_POLICY.apiKey,
            policy_label: EXPERT_PART_POLICY.name,
            policy_url: EXPERT_PART_POLICY.url,
          },
          ...((domain && domain.agreements) || []),
        ]
      : [];
  }, [isExpertSignup, domain]);

  const handleSubmit = useCallback(
    async (values: any) => {
      const tracking = getTracking() || {};
      try {
        const now = new Date();
        await signup(
          graphql,
          {
            recaptcha_token: recaptchaToken,
            subdomain: domain && domain.subdomain,
            email: values.email,
            phone: values.phone,
            password: values.password,
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
            signup_type: signupType,
            landing_url: tracking.landing,
            invite,
            tags: tags ? (Array.isArray(tags) ? tags : [tags]) : [],
            //login: signupType === 'expert' || !domain?.group?.enforce_2fa,
            agreements: [
              {
                policy: TOU_POLICY.apiKey,
                accepted: !!values.accept_privacy_and_terms_of_use,
                updated_at: now,
              },
              {
                policy: PRIVACY_POLICY.apiKey,
                accepted: !!values.accept_privacy_and_terms_of_use,
                updated_at: now,
              },
              ...expertAgreements.map((a) => ({
                policy: a.policy_code,
                accepted: !!values[`accept_${a.policy_code}`],
                updated_at: now,
              })),
            ],
          },
          nextUrl || undefined
        );
      } catch (e: unknown) {
        handleReCaptchaVerify();
        const message = e instanceof APIError ? e.rawError[0]?.message : undefined;
        switch (message) {
          case ErrEmailAlreadyExists.message:
            return { email: 'Email address is already in use' };
          case ErrPhoneAlreadyExists.message:
            return { phone: 'Phone number is already in use' };
          case 'invalid first name':
            return { first_name: 'Invalid name' };
          case 'invalid last name':
            return { last_name: 'Invalid name' };
          default:
            throw e;
        }
      }
    },
    [
      graphql,
      recaptchaToken,
      domain,
      signupType,
      invite,
      tags,
      expertAgreements,
      nextUrl,
      handleReCaptchaVerify,
    ]
  );

  const validate = useCallback(
    (values: any) => {
      const errors: { [key: string]: string } = {};

      if (!(values.first_name || '').trim()) {
        errors.first_name = 'Required';
      }

      if (values.first_name && !hasOnlyLetters(values.first_name)) {
        errors.first_name = 'Only letters allowed';
      }

      if (!(values.last_name || '').trim()) {
        errors.last_name = 'Required';
      }

      if (values.last_name && !hasOnlyLetters(values.last_name)) {
        errors.last_name = 'Only letters allowed';
      }

      if (!values.email) {
        errors.email = 'Required';
      } else if (!isEmailValid(values.email)) {
        errors.email = 'Invalid email address';
      }

      if (isExpertSignup && !values.phone) {
        errors.phone = 'Required';
      }

      if (values.phone && !isPhoneValid(values.phone)) {
        errors.phone = 'Phone must be valid';
      }

      if (!isValidPassword(values.password)) {
        errors.password = 'Password does not meet the requirements';
      }

      if (!values.accept_privacy_and_terms_of_use) {
        errors.accept_privacy_and_terms_of_use = 'Required';
      }

      expertAgreements.forEach((a) => {
        const fieldName = `accept_${a.policy_code}`;
        if (!values[fieldName]) {
          errors[fieldName] = 'Required';
        }
      });

      return errors;
    },
    [expertAgreements, isExpertSignup]
  );

  const showJoin = viewer.id && !!domain;

  return (
    <AuthLayout
      headerChildren={children}
      domain={domain}
      title={title}
      shortAboutText={shortAboutText}
      longAboutText={longAboutText}
    >
      {showJoin ? (
        <JoinNetwork viewer={viewer} domain={domain} isExpertSignup={isExpertSignup} />
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          subscription={{
            submitting: true,
            values: true,
            initialValues: true,
            touched: true,
          }}
        >
          {({ handleSubmit }: any) => {
            return (
              <form onSubmit={(e: any) => handleSubmit(e)}>
                <Field
                  component={TextField}
                  name="first_name"
                  label="First Name"
                  variant="outlined"
                  placeholder="Please provide your full first name"
                  required={!isExpertSignup}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  changeOnBlur={false}
                  format={normalizeSpace}
                />
                <Field
                  component={TextField}
                  name="last_name"
                  label="Last Name"
                  variant="outlined"
                  placeholder="Please provide your full last name"
                  required={!isExpertSignup}
                  changeOnBlur={false}
                  format={normalizeSpace}
                />
                <Field
                  component={PhoneInput}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  showExampleOnError
                />
                <Field
                  component={TextField}
                  changeOnBlur={false}
                  name="email"
                  label="Email"
                  variant="outlined"
                  inputProps={{ autoComplete: 'off' }}
                  required={!isExpertSignup}
                />
                <Field
                  component={PasswordText}
                  name="password"
                  label="Password"
                  variant="outlined"
                  showHelp
                  inputProps={{ autoComplete: 'new-password' }}
                  required={!isExpertSignup}
                />
                <div className={s.agreements}>
                  <Field
                    type="checkbox"
                    component={Checkbox}
                    classes={{ root: s.checkbox }}
                    labelClasses={{ root: s.checkboxLabel }}
                    FormControlProps={{ margin: 'none' }}
                    name="accept_privacy_and_terms_of_use"
                    label={
                      <span>
                        I have read and agree to OnFrontiers{' '}
                        <Link newTab href={PRIVACY_POLICY.url}>
                          {PRIVACY_POLICY.name}
                        </Link>{' '}
                        &amp;{' '}
                        <Link newTab href={TOU_POLICY.url}>
                          {TOU_POLICY.name}
                        </Link>
                      </span>
                    }
                  />
                  {expertAgreements.map((a) => (
                    <Field
                      key={a.policy_code}
                      type="checkbox"
                      component={Checkbox}
                      classes={{ root: s.checkbox }}
                      labelClasses={{ root: s.checkboxLabel }}
                      FormControlProps={{ margin: 'none' }}
                      name={`accept_${a.policy_code}`}
                      label={
                        <span>
                          I have read and agree with&nbsp;
                          <Link newTab href={a.policy_url}>
                            {a.policy_label}
                          </Link>
                        </span>
                      }
                    />
                  ))}
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!recaptchaToken && !!executeRecaptcha}
                  classes={{ root: s.button }}
                >
                  Join
                </Button>
                <Divider className={s.loginDivider} />
                <div className={s.login}>
                  Already have an account?
                  <Link
                    to={formatAuthPath({
                      to: signinLink || '/login',
                      domain,
                      signupType,
                      invite,
                      next: signinNextUrl || nextUrl,
                    })}
                  >
                    {' '}
                    Sign in
                  </Link>
                </div>
              </form>
            );
          }}
        </Form>
      )}
    </AuthLayout>
  );
};

export default connector(Signup);
