import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigation } from 'react-router';

import { RootState } from '@/store';
import { setRuntimeVariable } from '@/store/runtime';
import { teal500 } from '@/theme/colors';

interface PageLoadingBarProps {
  children?: React.ReactNode;
}

const connector = connect((state: RootState) => ({ userContext: state.ui.userContext }), {
  setRuntimeVariable,
});

type Props = PageLoadingBarProps & ConnectedProps<typeof connector>;

const PageLoadingBar: FC<Props> = memo(({ userContext, children, setRuntimeVariable }: Props) => {
  const location = useLocation();
  const navigation = useNavigation();

  const [isVisible, setIsVisible] = useState(true);
  const [progress, setProgress] = useState(0);
  const initial = useMemo(() => progress === 10, [progress]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    switch (navigation.state) {
      case 'loading':
      case 'submitting':
        setProgress(10);
        setIsVisible(true);
        break;
      default:
        setProgress(100);
        timer = setTimeout(() => setIsVisible(false), 1000);
    }

    // Clear the timeout if the component unmounts or if the value changes
    return () => clearTimeout(timer);
  }, [progress, navigation, setProgress]); // This effect runs when the value prop changes

  useEffect(() => {
    window.scrollTo(0, 0);
    // Update referrer on push navigation
    setRuntimeVariable('referrer', `${window.location.origin}${location.pathname}`);
  }, [location.key, location.pathname, userContext, setProgress, setRuntimeVariable]);

  const barStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: `${progress}%`,
    height: '4px',
    backgroundColor: teal500,
  };

  if (!initial) {
    barStyle.transition = 'width 0.5s ease-in-out';
  }

  return (
    <>
      {isVisible && <div style={barStyle} />}
      {children}
    </>
  );
});
PageLoadingBar.displayName = 'PageLoadingBar';

export default connector(PageLoadingBar);
