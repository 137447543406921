import { redirect } from 'react-router';

import { approveGroupMember, denyGroupMember, fetchGroup } from '@/actions/group';
import { notify } from '@/actions/ui';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import LayoutPage from '@/components/Layout/LayoutPage';
import { isGroupContext } from '@/core/user';
import NotFoundPage from '@/pages/NotFoundPage';

import { ActionContext, LegacyRoute } from '../routesMiddleware';
import Team from './Team';

async function action({ store, params }: ActionContext) {
  let { slug } = params;
  const { userContext, userContextOptions } = store.getState().ui;
  const groupContexts = userContextOptions.filter((o: any) => isGroupContext(o.value));

  let redirectSlug;
  if (!slug || slug === 'default') {
    if (isGroupContext(userContext)) {
      redirectSlug = userContext;
    } else {
      if (!groupContexts.length) return <NotFoundPage />;
      redirectSlug = groupContexts[0].value;
    }
    slug = redirectSlug;
  }

  const [group] = await Promise.all([
    store.dispatch(
      fetchGroup(slug, {
        awaitingMembers: true,
        internalNetwork: true,
      })
    ),
  ]);

  if (!group) return <NotFoundPage />;

  if (redirectSlug) {
    return redirect(`/team/${group.slug}`);
  }

  document.title = `${group.name} Team`;
  return (
    <LayoutPage showNav selected="teams">
      <ConsultationsStarting />
      <Team groupId={group.id} />
    </LayoutPage>
  );
}

const route: LegacyRoute = {
  path: '/team/:slug?',

  action,
  children: [
    {
      path: 'member/:memberId/:op',

      async action(context) {
        const { store, params } = context;
        const { slug, memberId, op } = params;

        const group = await store.dispatch(
          fetchGroup(slug, {
            awaitingMembers: true,
          })
        );
        if (!group.members_awaiting || !group.members_awaiting.edges) return <NotFoundPage />;

        const member = group.members_awaiting.edges
          .map((e: any) => e.node)
          .find((m: any) => m.id === memberId);

        if (!member) {
          return action(context);
        }

        switch (op) {
          case 'approve':
            await store.dispatch(approveGroupMember(member.id));
            store.dispatch(notify(`${member.user.name} approved`));
            break;
          case 'deny':
            await store.dispatch(denyGroupMember(member.id));
            store.dispatch(notify(`${member.user.name} denied`));
            break;
          default:
            return <NotFoundPage />;
        }

        return action(context);
      },
    },
  ],
};

export default route;
