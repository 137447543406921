import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router';
import { useMediaQuery } from 'usehooks-ts';

import Box from '@/componentsv2/Box';
import Meter from '@/componentsv2/Meter';
import { mq } from '@/theme/screens';

import KnowledgeLevelLabel from '../KnowledgeLevelLabel';
import { IKnowledgeBarProps } from './types';

const testId = 'of-knowledge-bar';

const KnowledgeBar = ({
  className,
  label,
  oldValue,
  value,
  to,
  isLoading,
  loadAsLink,
  minDiff,
  maxDiff,
}: IKnowledgeBarProps) => {
  const labelId = `knowledge-bar-label-${label.replace(/\s+/g, '-').toLowerCase()}`;

  const isLink = !!to;
  let diff = oldValue ? value - oldValue : undefined;

  if (typeof maxDiff !== 'undefined') {
    diff = Math.min(diff ?? 0, maxDiff);
  }

  if (typeof minDiff !== 'undefined') {
    diff = Math.max(diff ?? 0, minDiff);
  }

  const isMobile = useMediaQuery(mq.smd);

  return (
    <Box
      className={clsx(className, 'w-full rounded-md border border-light-primary bg-white p-16', {
        flex: isLink || loadAsLink,
        'group cursor-pointer items-center hover:bg-gray-50': isLink,
      })}
      data-testid={testId}
      component={isLink ? Link : 'div'}
      {...(isLink ? { to } : {})}
    >
      <div className="grow">
        <div className="flex items-center justify-between">
          <label id={labelId} className="text-14 font-bold group-hover:text-brand-primary">
            {isLoading ? <Skeleton variant="text" width={100} /> : label}
          </label>
          <KnowledgeLevelLabel
            value={value}
            isLoading={isLoading}
            iconOnly={isMobile}
            className="ml-8"
          />
        </div>
        <Meter
          className="mt-16"
          value={isLoading ? 0 : value}
          diff={diff}
          ariaLabelledBy={labelId}
        />
      </div>
      {isLink || loadAsLink ? (
        <div className="shrink-0 pl-16 duration-200 group-hover:translate-x-4">
          <ChevronRight className="h-16 w-16 text-brand-tertiary" />
        </div>
      ) : null}
    </Box>
  );
};

export { testId as KnowledgeBarTestId };
export type { IKnowledgeBarProps };
export default KnowledgeBar;
