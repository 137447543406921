import Typography from '@mui/material/Typography';
import { Link } from 'react-router';

import TagGroup from '@/componentsv2/TagGroup';
import { useProfileId } from '@/hooks/store';
import { useLocationsQuery } from '@/knowledge/queries/knowledge';
import { accrualSummarySelector } from '@/knowledge/store/accrualSummarySlice';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';

import ExpertTag from '../ExpertTag';
import { ProfileContainer } from '../ProfileContainer';

export const TITLE = 'Experience by Countries';
const LOCATIONS_PLACEHOLDER = `More experiences required to display ${TITLE}`;

const Locations = () => {
  const profileId = useProfileId();
  const {
    data: locations,
    isLoading,
    isFetched,
    isRefetching,
  } = useLocationsQuery({
    profileId,
    page: 1,
    ordering: '-value',
  });
  const hasData = locations?.results?.length;
  const { isLoading: accrualIsLoading, accrualIsStale } = useAppSelector(accrualSummarySelector);

  return (
    <ProfileContainer>
      <ProfileContainer.Header
        showUpdatingIndicator={isRefetching || (accrualIsLoading && accrualIsStale)}
        to={PROFILE_ROUTES().locations}
      >
        {TITLE}
      </ProfileContainer.Header>
      <ProfileContainer.Body>
        <TagGroup
          collapsible
          isLoading={isLoading}
          showPlaceholder={!hasData && isFetched}
          placeholder={
            <Typography variant="body2" color="grey.500">
              {LOCATIONS_PLACEHOLDER}
            </Typography>
          }
        >
          {locations?.results?.map((location) => {
            return (
              <Link key={location.name} to={PROFILE_ROUTES().location(location.name ?? '')}>
                <ExpertTag value={location.value} isClickable>
                  {location.name}
                </ExpertTag>
              </Link>
            );
          })}
        </TagGroup>
      </ProfileContainer.Body>
    </ProfileContainer>
  );
};

export default Locations;
