import { SendbirdChatWith } from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelListOrder,
  GroupChannelListQuery,
  GroupChannelModule,
} from '@sendbird/chat/groupChannel';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { LoaderFunction, redirect, useLoaderData } from 'react-router';

import { renderTemplateId } from '@/actions/messageTemplate';
import { MiddlewareContext, mapQueryParams } from '@/routes/routesMiddleware';
import { getCache, setCache } from '@/utils';

import Messenger from '../components/Messenger';
import { channelIdToUrl, channelUrlToId } from '../sendbird';

async function fetchLastActiveChannel(
  sb: SendbirdChatWith<GroupChannelModule[]>
): Promise<GroupChannel | null> {
  const query: GroupChannelListQuery = sb.groupChannel.createMyGroupChannelListQuery({
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    limit: 1,
    includeEmpty: false,
  });

  if (query.hasNext) {
    const channels = await query.next();
    return channels[0];
  }

  return null;
}

interface MessagingLoaderData {
  channelUrl?: string;
  text?: string;
}

export function messagingMiddleware({ store, sendbird }: MiddlewareContext): LoaderFunction {
  return async ({ params, request }): Promise<Response | MessagingLoaderData> => {
    const query = mapQueryParams(request.url);
    const { viewer } = store.getState();
    const { channelId } = params;
    const channelUrl = channelId && channelIdToUrl(channelId);

    const requestId = query.get('request');
    const expertId = query.get('expert');
    const template = query.get('template');

    if (!viewer.id) {
      throw new Error('Not logged in');
    }

    const session = getCache('session') || {};

    if (!channelUrl) {
      let lastChannelUrl = session.lastChannelUrl;
      if (!lastChannelUrl) {
        const sb = await sendbird.getSession();
        if (sb) {
          const lastActiveChannel = await fetchLastActiveChannel(sb);
          lastChannelUrl = lastActiveChannel?.url;
        }
      }
      if (lastChannelUrl) return redirect(`/messaging/${channelUrlToId(lastChannelUrl)}`);
    }

    session.lastChannelUrl = channelUrl;
    setCache('session', session);

    let text;
    if (template && requestId && expertId) {
      const t = await store.dispatch(
        renderTemplateId(template, {
          senderId: viewer.id,
          requestId: requestId,
          expertId: expertId,
        })
      );
      text = t.rendered;
    }

    return {
      channelUrl,
      text,
    };
  };
}

const MessagingPage: FC = () => {
  const { channelUrl, text } = useLoaderData() as MessagingLoaderData;
  return (
    <>
      <Helmet>
        <title>Messages</title>
      </Helmet>
      <Messenger channelUrl={channelUrl} text={text} />
    </>
  );
};

export default MessagingPage;
