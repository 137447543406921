import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { EmailValidationAction } from '@/__generated__/graphql';
import { requestEmailValidation } from '@/actions/address';
import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import EmptyMessage from '@/components/EmptyMessage';
import LayoutPage from '@/components/Layout/LayoutPage';
import { RootState } from '@/store';
import { teal500 } from '@/theme/colors';

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    requestEmailValidation,
    notify,
  }
);

class AwaitingEmailValidation extends React.Component<ConnectedProps<typeof connector>> {
  handleResendVerification = () => {
    const { viewer, requestEmailValidation, notify } = this.props;
    requestEmailValidation(
      viewer?.profile?.id,
      viewer?.email?.address,
      EmailValidationAction.Verify
    ).then(() => notify('Verification email sent.'));
  };

  render() {
    const { viewer } = this.props;

    return (
      <LayoutPage hideSearch showReviewConsultation={false} showNewRequest={false}>
        <EmptyMessage
          border={false}
          iconName="mail"
          iconColor={teal500}
          title="Please check your inbox for a verification email"
          body={
            <div>
              <p>We need to verify ownership of your email address before continuing.</p>
              <p>{viewer?.email?.address}</p>
            </div>
          }
          action={<Button onClick={this.handleResendVerification}>Re-Send</Button>}
        />
      </LayoutPage>
    );
  }
}

export default connector(AwaitingEmailValidation);
