import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { ChangeEventHandler, FC, FormEventHandler, useCallback, useMemo } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { popup } from '@/actions/ui';
import { SignupType, formatAuthPath } from '@/auth';
import AuthLayout from '@/auth/components/AuthLayout';
import { PasswordTextField, PasswordTextFieldProps } from '@/auth/components/PasswordTextField';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Link from '@/components/Link';
import { GroupDomain } from '@/group';
import { borderColor, darkGray } from '@/theme/colors';
import { getJoinTitle } from '@/utils/reducer';

const useStyles = makeStyles({
  form: {
    textAlign: 'center',
    maxWidth: 400,
    margin: '0 auto',
  },
  title: {
    marginTop: 0,
  },
  linkedin: {
    cursor: 'pointer',
  },
  button: {
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 30,
    maxWidth: 200,
    padding: '8px 22px',
    fontSize: 15,
  },
  signup: {
    color: darkGray,
  },
  loginDivider: {
    backgroundColor: borderColor,
    margin: '40px 0 30px',
  },
});

interface CredentialsProps {
  nextUrl?: string;
  signupType?: SignupType;
  invite?: string;
  domain?: GroupDomain;
  signupLink?: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onEmailChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onPasswordChange: PasswordTextFieldProps['onChange'];
  email: string;
  password: string;
  errors: string[];
  buttonDisable: boolean;
  alertId?: string;
}

const connector = connect(undefined, { popup });

const Credentials: FC<CredentialsProps & ConnectedProps<typeof connector>> = ({
  nextUrl,
  signupType,
  invite,
  domain,
  signupLink,
  onSubmit,
  onEmailChange,
  onPasswordChange,
  email,
  password,
  errors,
  popup,
  buttonDisable,
  alertId,
}) => {
  const navigate = useNavigate();
  const s = useStyles();

  const handleLinkedInClick = useCallback(() => {
    popup({
      title: 'LinkedIn authentication is no longer available',
      contents: 'Please use your email and password to login.',
      buttonAlignment: 'space-between',
      buttons: [
        {
          label: 'Create new password',
          primary: true,
          callback: () => {
            navigate('/password_reset');
          },
        },
        { flat: true, label: 'Cancel' },
      ],
    });
  }, [navigate, popup]);

  const errorBlock =
    errors.length > 0 ? (
      <Alert type="error" center id={alertId}>
        {errors[0]}
      </Alert>
    ) : undefined;

  const form = (
    <form className={s.form} onSubmit={onSubmit}>
      {!domain && <h2 className={s.title}>Log In</h2>}

      <TextField
        id="loginEmail"
        variant="outlined"
        fullWidth
        type="email"
        label="Email address"
        inputProps={{
          autocomplete: 'username',
        }}
        value={email}
        onChange={onEmailChange}
      />
      <PasswordTextField
        id="loginPassword"
        endAdornmentId="loginEye"
        variant="outlined"
        fullWidth
        label="Password"
        value={password}
        onChange={onPasswordChange}
      />

      <Button
        id="loginButton"
        disabled={buttonDisable}
        type="submit"
        size="medium"
        classes={{ root: s.button }}
        fullWidth
      >
        Log in
      </Button>

      <div>
        <Link id="loginForgotPassword" to={`/password_reset?next=${nextUrl}`}>
          Forgot your password?
        </Link>

        <br />
        <br />

        <Link
          id="loginLinkedInLogin"
          className={s.linkedin}
          onClick={handleLinkedInClick}
          onKeyPress={handleLinkedInClick}
        >
          Missing LinkedIn login?
        </Link>
      </div>

      <Divider className={s.loginDivider} />

      <div className={s.signup}>
        Do not have an account?
        <Link
          id="loginSignUp"
          to={formatAuthPath({
            to: signupLink || '/signup',
            domain,
            signupType,
            invite,
            next: nextUrl,
          })}
        >
          {' '}
          Sign up
        </Link>
      </div>
    </form>
  );

  const title = useMemo(() => getJoinTitle(domain), [domain]);

  return (
    <div>
      {errorBlock}

      {domain ? (
        <AuthLayout
          domain={domain}
          title={title}
          shortAboutText={domain.expert_signup_about_mobile}
          longAboutText={domain.expert_signup_about}
        >
          {form}
        </AuthLayout>
      ) : (
        form
      )}
    </div>
  );
};

export default connector(Credentials);
