import { useMutation, useQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { JSX, useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ConnectedProps, connect } from 'react-redux';

import { gengql } from '@/__generated__';
import { CandidateQueryResponseInput, QueryResponseType } from '@/__generated__/graphql';
import { notify } from '@/actions/ui';
import Button from '@/components/Button/Button';
import EditDialog from '@/components/EditDialog';
import LayoutPage from '@/components/Layout/LayoutPage';
import MediaQuery from '@/components/MediaQuery';
import PhoneInput from '@/components/PhoneInput';
import { Query } from '@/components/Queries';
import ConfirmationRow from '@/components/Wizard/ConfirmationRow';
import { ERROR_CODES, hasErrorCode } from '@/core/api';
import NotFoundPage from '@/pages/NotFoundPage';
import { RootState } from '@/store';
import { borderColor } from '@/theme/colors';
import { SCREEN_XS } from '@/theme/screens';
import { isPhoneValid } from '@/utils';
import { DocumentNodeResult } from '@/utils/gql';

import { REQUEST_ADD_EXPERT_REQUEST_CANDIDATE } from '../store/queries';
import s from './ExpertRequestAddRequest.module.scss';
import ExpertRequestAddRequestSubmitted from './ExpertRequestAddRequestSubmitted';

const GET_EXPERT_REQUEST = gengql(/* GraphQL */ `
  query expertRequestAddRequestGetExpertRequest($id: String!) {
    expertRequest(id: $id) {
      id
      html_url
      name
      questions {
        id
        query
        response_type
        required
      }
      qualifications {
        id
        query
        response_type
        required
      }
    }
  }
`);

const SET_ADDRESS = gengql(/* GraphQL */ `
  mutation expertRequestAddRequestSetAddress(
    $userId: String!
    $transport: String!
    $address: String!
    $primary: Boolean!
  ) {
    setAddress(user_id: $userId, transport: $transport, address: $address, primary: $primary) {
      address
      display
      primary
      verified
    }
  }
`);

type ExpertRequest = DocumentNodeResult<typeof GET_EXPERT_REQUEST>['expertRequest'];
type Question = NonNullable<ExpertRequest['questions']>[number];
type Qualification = NonNullable<ExpertRequest['qualifications']>[number];

interface UpdatePhoneFormData {
  phone: string;
}

type UpdatePhoneFormErrors = FormikErrors<UpdatePhoneFormData>;

function validateUpdatePhoneForm(values: UpdatePhoneFormData): UpdatePhoneFormErrors {
  const errors: UpdatePhoneFormErrors = {};

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!isPhoneValid(values.phone)) {
    errors.phone = 'Phone must be valid.';
  }

  return errors;
}

interface UpdatePhoneProps {
  open?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

const updatePhoneConnector = connect(
  (state: RootState) => {
    return {
      viewer: state.viewer,
    };
  },
  {
    notify,
  }
);

const UpdatePhone = updatePhoneConnector(
  ({
    open,
    onClose,
    onSubmit,
    viewer,
    notify,
  }: UpdatePhoneProps & ConnectedProps<typeof updatePhoneConnector>): JSX.Element => {
    const [setAddress] = useMutation(SET_ADDRESS);

    const handleSubmitFinal = async (
      values: UpdatePhoneFormData,
      { setErrors }: FormikHelpers<UpdatePhoneFormData>
    ) => {
      try {
        await setAddress({
          variables: {
            userId: viewer.id,
            transport: 'phone',
            address: values.phone,
            primary: true,
          },
        });
        if (onSubmit) onSubmit();
      } catch (err: unknown) {
        if (hasErrorCode(err, ERROR_CODES.ADDRESS_ADDRESS_TAKEN)) {
          setErrors({
            phone: 'Phone number is already in use.',
          });
          return;
        }
        notify('An error occurred when updating the phone.', 'error');
        console.error(err);
      }
    };

    const formik = useFormik<UpdatePhoneFormData>({
      initialValues: {
        phone: viewer.phone || '',
      },
      validate: validateUpdatePhoneForm,
      onSubmit: handleSubmitFinal,
    });

    return (
      <EditDialog
        open={open}
        title="What is your preferred contact number?"
        onSubmit={formik.handleSubmit}
        disableSubmit={formik.isSubmitting}
        onClose={onClose}
      >
        <p className={s.updatePhoneText}>We may contact you with a few additional questions.</p>
        <PhoneInput
          id="communicationPhone"
          type="tel"
          name="phone"
          value={formik.values.phone}
          onChange={(phone: string) => {
            formik.setFieldValue('phone', phone);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          showExampleOnError
        />
      </EditDialog>
    );
  }
);

interface FormData {
  match_experience: string;
  question_answers: CandidateQueryResponseInput[];
  qualification_responses: CandidateQueryResponseInput[];
}

type FormErrors = FormikErrors<FormData>;
type QuestionError = FormikErrors<CandidateQueryResponseInput>;

// formik thinks any key in the form error is an error even if the value is undefined so this
// function filters those elements out.
function removeEmptyElements<T>(v: T): T | undefined {
  if (v === undefined || v === null || v === '') {
    return undefined;
  }

  if (Array.isArray(v)) {
    const mapped = v.map(removeEmptyElements);
    // bit of a type hack here
    return mapped.every((v) => v === undefined) ? undefined : (mapped as T);
  }

  if (typeof v === 'object') {
    const filtered = Object.entries(v)
      .map(([k, v]) => [k, removeEmptyElements(v)])
      .filter(([_, v]) => v !== undefined);

    return filtered.length === 0 ? undefined : (Object.fromEntries(filtered) as T);
  }

  return v;
}

function validateQueryResponse(
  q: Question | Qualification,
  qa: CandidateQueryResponseInput
): QuestionError {
  const qaError: QuestionError = {};
  if (!q.required) return qaError;

  if (q.response_type === QueryResponseType.YesNo && qa.can_answer === undefined) {
    qaError.can_answer = 'Required';
  } else if (q.response_type === QueryResponseType.FreeForm && !qa.text_response) {
    qaError.text_response = 'Required';
  }

  return qaError;
}

function createValidator(
  questions: Question[],
  qualifications: Qualification[]
): (values: FormData) => FormErrors | undefined {
  return (values: FormData): FormErrors | undefined => {
    const errors: FormErrors = {};

    errors.question_answers = values.question_answers.map((qa) => {
      const q = questions.find((q) => q.id === qa.query_id);
      if (!q) return {};

      return validateQueryResponse(q, qa);
    });

    errors.qualification_responses = values.qualification_responses.map((qa) => {
      const q = qualifications.find((q) => q.id === qa.query_id);
      if (!q) return {};

      return validateQueryResponse(q, qa);
    });

    return removeEmptyElements(errors);
  };
}

export interface ExpertRequestAddRequestProps {
  expertRequestId: string;
}

const connector = connect((state: RootState) => ({ viewer: state.viewer }), {
  notify,
});

const ExpertRequestAddRequest = ({
  expertRequestId,
  viewer,
  notify,
}: ExpertRequestAddRequestProps & ConnectedProps<typeof connector>): JSX.Element | null => {
  const [done, setDone] = useState(false);
  const [updatePhoneOpen, setUpdatePhoneOpen] = useState(false);

  const { data, error } = useQuery(GET_EXPERT_REQUEST, { variables: { id: expertRequestId } });
  const [requestAddExpertRequestCandidate] = useMutation(REQUEST_ADD_EXPERT_REQUEST_CANDIDATE);

  const expertRequest = data?.expertRequest;
  const questions = useMemo(() => expertRequest?.questions || [], [expertRequest]);
  const qualifications = useMemo(() => expertRequest?.qualifications || [], [expertRequest]);

  const initialValues = useMemo(
    () => ({
      match_experience: '',
      question_answers: questions.map((q) => ({
        ...(q.response_type === QueryResponseType.YesNo ? {} : { text_response: '' }),
        query_id: q.id,
      })),
      qualification_responses: qualifications.map((q) => ({
        ...(q.response_type === QueryResponseType.YesNo ? {} : { text_response: '' }),
        query_id: q.id,
      })),
    }),
    [questions, qualifications]
  );

  const validate = useMemo(
    () => createValidator(questions, qualifications),
    [questions, qualifications]
  );

  const onSubmit = useCallback(
    async (values: FormData) => {
      try {
        await requestAddExpertRequestCandidate({
          variables: {
            expert_request_id: expertRequestId,
            ...values,
          },
        });
        if (viewer.phone) {
          setDone(true);
        } else {
          setUpdatePhoneOpen(true);
        }
      } catch (e: unknown) {
        notify('An error occurred when submitting.', 'error');
        console.error(e);
      }
    },
    [expertRequestId, notify, requestAddExpertRequestCandidate, viewer.phone]
  );

  const formik = useFormik<FormData>({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit,
  });

  if (hasErrorCode(error, ERROR_CODES.EXPERT_REQUEST_NOT_FOUND)) {
    return <NotFoundPage />;
  }

  const hasQuestions = questions.length > 0;
  const hasQualifications = qualifications.length > 0;

  if (!expertRequest) {
    return null;
  }

  if (done) {
    return <ExpertRequestAddRequestSubmitted viewer={viewer} />;
  }

  return (
    <LayoutPage hideSearch>
      <Helmet>
        <title>{expertRequest.name}</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit} noValidate>
        {hasQuestions || hasQualifications ? (
          <div className={s.queries}>
            <h4 className={s.name}>{expertRequest.name}</h4>
            {hasQuestions && (
              <>
                <h3 className={s.title}>Are you able to discuss these questions?</h3>
                <h4 className={s.subTitle}>
                  If you are able to discuss these areas without conflict then there is an increased
                  likelihood you will be engaged for a consultation.
                </h4>

                <ConfirmationRow
                  separator={false}
                  separatorColor={borderColor}
                  bodyClassName={s.confirmationRowBody}
                >
                  {/* <FieldArray name="question_answers" component={Queries} queries={questions} /> */}
                  {formik.values.question_answers.map((qa, index) => {
                    const query = questions[index];
                    const touched = formik.touched.question_answers?.[index];
                    const error = (formik.errors.question_answers || [])[index] as
                      | QuestionError
                      | undefined;
                    return (
                      <Query
                        key={qa.query_id}
                        index={index}
                        name={`question_answers[${index}]`}
                        query={query.query}
                        responseType={query.response_type}
                        required={query.required}
                        value={qa}
                        onChange={({ can_answer, text_response }) => {
                          formik.setFieldValue('question_answers', [
                            ...formik.values.question_answers.slice(0, index),
                            { can_answer, text_response, query_id: query.id },
                            ...formik.values.question_answers.slice(index + 1),
                          ]);
                        }}
                        error={touched && (!!error?.can_answer || !!error?.text_response)}
                        helperText={touched && (error?.can_answer || error?.text_response)}
                        onBlur={formik.handleBlur}
                      />
                    );
                  })}
                </ConfirmationRow>
              </>
            )}
            {hasQualifications && (
              <>
                <h3 className={s.title}>Do you have these qualifications?</h3>
                <h4 className={s.subTitle}>
                  If you have these desired qualifications then there is an increased likelihood you
                  will be engaged for a consultation.
                </h4>
                <ConfirmationRow
                  separator={false}
                  separatorColor={borderColor}
                  bodyClassName={s.confirmationRowBody}
                >
                  {formik.values.qualification_responses.map((qa, index) => {
                    const query = qualifications[index];
                    const touched = formik.touched.qualification_responses?.[index];
                    const error = (formik.errors.qualification_responses || [])[index] as
                      | QuestionError
                      | undefined;
                    return (
                      <Query
                        key={qa.query_id}
                        index={index}
                        name={`qualification_responses[${index}]`}
                        query={query.query}
                        responseType={query.response_type}
                        required={query.required}
                        value={qa}
                        onChange={({ can_answer, text_response }) => {
                          formik.setFieldValue('qualification_responses', [
                            ...formik.values.qualification_responses.slice(0, index),
                            { can_answer, text_response, query_id: query.id },
                            ...formik.values.qualification_responses.slice(index + 1),
                          ]);
                        }}
                        error={touched && (!!error?.can_answer || !!error?.text_response)}
                        helperText={touched && (error?.can_answer || error?.text_response)}
                        onBlur={formik.handleBlur}
                      />
                    );
                  })}
                </ConfirmationRow>
              </>
            )}
          </div>
        ) : (
          <h3 className={s.title}>{expertRequest.name}</h3>
        )}

        <div>
          <h3 className={s.title}>Additional relevant work experience</h3>
          <h4 className={s.subTitle}>
            Describe how your work experience qualifies you to discuss this subject matter.
          </h4>

          <ConfirmationRow
            separator={false}
            separatorColor={borderColor}
            bodyClassName={s.confirmationRowBody}
          >
            <MediaQuery maxWidth={SCREEN_XS}>
              {(isMobileVersion: boolean) => (
                <TextField
                  id="describeExperience"
                  fullWidth
                  multiline
                  name="match_experience"
                  label="Add Note"
                  minRows={isMobileVersion ? 2 : 1}
                  maxRows={10}
                />
              )}
            </MediaQuery>
          </ConfirmationRow>
        </div>

        <Button type="submit" size="large" style={{ marginTop: 30 }} disabled={formik.isSubmitting}>
          Submit
        </Button>
      </form>
      <UpdatePhone
        onSubmit={() => setDone(true)}
        onClose={() => setUpdatePhoneOpen(false)}
        open={updatePhoneOpen}
      />
    </LayoutPage>
  );
};

export default connector(ExpertRequestAddRequest);
