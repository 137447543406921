import MaterialButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { toQueryString } from '@/search';
import { RootState } from '@/store';
import { darkBlue, grey50, grey100 } from '@/theme/colors';

import Form from '../Form';
import MaterialIcon from '../Icon/MaterialIcon';

const useSearchStyles = makeStyles((theme) => ({
  search: {
    border: `1px solid ${grey100}`,
    borderRadius: 4,
    paddingLeft: 12,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('xl')]: {
      backgroundColor: grey50,
    },
  },
  searchIcon: {
    fontSize: 26,
    marginRight: '6px !important',
  },
}));

const connector = connect((state: RootState) => ({
  query: state.search.query,
}));

const Search = ({ query }: ConnectedProps<typeof connector>) => {
  const navigate = useNavigate();
  const s = useSearchStyles();

  const [text, setText] = useState(query?.text || '');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xl'));

  const handleChange = useCallback(({ target: { value } }: any) => {
    setText(value);
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate(
      `/search?${toQueryString({
        ...query,
        text,
        sort: undefined,
        ascending: undefined,
      })}`
    );
  };

  return (
    <Form onSubmit={handleSubmit} className={s.search} id="searchForm">
      <MaterialIcon icon="search" className={s.searchIcon} />

      <TextField
        id="search"
        name="q"
        placeholder="Search Your Knowledge Network"
        className="m-0 text-10"
        InputProps={{
          disableUnderline: true,
        }}
        margin="none"
        value={text}
        onChange={handleChange}
        fullWidth
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={mobile}
      />

      {mobile && (
        <MaterialButton type="submit" size="small" style={{ color: darkBlue, fontSize: 16 }}>
          Go
        </MaterialButton>
      )}
    </Form>
  );
};

export default connector(Search);
