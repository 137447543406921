import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { track } from '@/actions/tracking';
import { white } from '@/theme/colors';

import Dialog from '../Dialog';
import TeamAccountPromo from './TeamAccountPromo';

const TeamAccountPromoDialog = (props: any) => {
  const wasOpen = useRef(props.open);
  const navigate = useNavigate();
  const {
    open,
    track,
    returnTo,
    promoProps,
    clickEventTrack,
    showEventTrack = 'promo.show.team_account.expert_request',
    ...other
  } = props;

  const handleClose = () => {
    const { returnTo, onClose } = props;
    if (returnTo) navigate(returnTo);
    if (onClose) onClose();
  };

  useEffect(() => {
    if (open && showEventTrack) track(showEventTrack);
  }, []);

  useEffect(() => {
    if (!wasOpen.current && open && showEventTrack) track(showEventTrack);
    wasOpen.current = open;
  }, [open, track, showEventTrack]);

  return (
    <Dialog promo open={open} {...other} onClose={handleClose}>
      <TeamAccountPromo
        clickEventTrack={clickEventTrack}
        onContactSales={handleClose}
        style={{ padding: 20 }}
        titleStyle={{ color: white, fontSize: 20 }}
        bodyStyle={{ color: white, fontSize: 16 }}
        iconProps={{
          color: 'rgba(255, 255, 255, .52)',
          size: 30,
        }}
        buttonProps={{
          primary: false,
          backgroundColor: 'rgba(255, 255, 255, .2)',
          style: { backgroundColor: 'transparent', color: white },
        }}
        {...promoProps}
      />
    </Dialog>
  );
};

export default connect(undefined, {
  track,
})(TeamAccountPromoDialog);
