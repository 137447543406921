import { Box } from '@mui/material';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router';

const MobileItem = ({
  children,
  path,
  onClick,
  className,
  boxClassName,
}: PropsWithChildren<{
  path?: LinkProps['to'];
  onClick?: () => void;
  className?: string;
  boxClassName?: string;
}>) => {
  return (
    <li className={className}>
      <Box
        component={path ? Link : 'button'}
        to={path}
        onClick={onClick}
        className={clsx('flex w-full items-center gap-12 py-10 text-brand-tertiary', boxClassName)}
      >
        {children}
      </Box>
    </li>
  );
};

export default MobileItem;
