import { useFeatureIsOn } from '@growthbook/growthbook-react';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useRef } from 'react';
import { Check, Code, Plus } from 'react-feather';
import { Link, LinkProps, useParams } from 'react-router';
import { useIntersectionObserver } from 'usehooks-ts';

import Avatar from '@/auth/components/Avatar';
import Button from '@/componentsv2/Button';
import { OneLineList } from '@/componentsv2/OneLineList';
import { Skeletons as TagGroupSkeleton } from '@/componentsv2/TagGroup';
import { segmentTracking } from '@/core/analytics';
import { SearchResponse, ShortlistProfile } from '@/openapi';
import APP_ROUTES from '@/routes/APP_ROUTES';
import { useAddToShortlist, useRemoveFromShortlist } from '@/searchv2/mutations/shortlist';
import { createOrEditSearch } from '@/searchv2/store/searchSlice';
import { useAppDispatch } from '@/store';
import { mq } from '@/theme/screens';

import HorizontalScroller from './HorizontalScroller';
import MatchedTags, { MatchedTagsTrackingPayload } from './MatchedTags';
import ResultActionMenu from './ResultActionMenu';
import StartConversationButton from './StartConversationButton';

export const ResultSkeleton = ({ index }: { index: number }) => {
  return (
    <li className="rounded-md border border-grey-300 bg-white" style={{ opacity: 1 - index * 0.2 }}>
      <div className="flex items-start gap-16 p-16">
        <Skeleton variant="circular" width={64} height={64} />
        <div className="grow">
          <p className="hd-6">
            <Skeleton variant="text" width={200} height={30} />
          </p>
          <OneLineList className="mt-4 text-grey-600 subtitle-1" mq={mq.lg}>
            <OneLineList.Item>
              <Skeleton variant="text" width={100} />
            </OneLineList.Item>
            <OneLineList.Item>
              <Skeleton variant="text" width={100} />
            </OneLineList.Item>
          </OneLineList>
        </div>
      </div>
      <div className="border-t border-grey-400 p-16">
        <ul className="flex flex-wrap gap-8">
          <TagGroupSkeleton tagSize="sm" number={5} />
        </ul>
      </div>
    </li>
  );
};

const ProfileLink = ({
  children,
  result,
  trackingPayload,
  ...rest
}: Partial<LinkProps> & {
  result: SearchResponse;
  trackingPayload: MatchedTagsTrackingPayload;
}) => {
  return (
    <Link
      to={APP_ROUTES.profile(result.profile_id ?? '')}
      state={{
        searchContext: {
          matchedFilters: result.matched_filters,
          knowledges: result.knowledges,
          trackingPayload,
        },
      }}
      onClick={() => {
        segmentTracking('pressed-click-view-profile', {
          ...trackingPayload,
          triggered_by: 'search results',
        });
      }}
      {...rest}
    >
      {children}
    </Link>
  );
};

const mapResultToShortlist = (result: SearchResponse): ShortlistProfile => ({
  full_name: result.full_name ?? '',
  profile_id: result.profile_id ?? '',
  location: result.country ?? '',
  picture_url: result.picture_url ?? '',
});

const Result = ({
  result,
  isAddedToShortlist,
  isShortlistLoading,
  showDebugDialog,
  trackingPayload: baseTrackingPayload,
}: {
  result: SearchResponse;
  isAddedToShortlist?: boolean;
  isShortlistLoading?: boolean;
  showDebugDialog: () => void;
  trackingPayload: MatchedTagsTrackingPayload;
}) => {
  const dispatch = useAppDispatch();
  const enableSearchDebugging = useFeatureIsOn('web-search-debugging');
  const { savedSearchId } = useParams();
  const { mutate: addToShortlist } = useAddToShortlist();
  const { mutate: removeFromShortlist } = useRemoveFromShortlist();
  const matchedFilters = result.matched_filters.filter((match) => match.match_type !== 'fulltext');
  const cardRef = useRef<HTMLDivElement>(null);
  const trackingObserver = useIntersectionObserver(cardRef, {
    freezeOnceVisible: true,
    threshold: 1,
  });

  const handleShortlistToggle = () => {
    if (!savedSearchId) {
      dispatch(
        createOrEditSearch({
          initialShortlist: [mapResultToShortlist(result)],
        })
      );
      return;
    }

    if (isAddedToShortlist) {
      removeFromShortlist({
        id: savedSearchId,
        profileId: result.profile_id ?? '',
      });
      return;
    }

    addToShortlist({
      id: savedSearchId,
      profiles: [mapResultToShortlist(result)],
    });
  };

  const trackingPayload = {
    ...baseTrackingPayload,
    search_relevance: matchedFilters,
  };

  useEffect(() => {
    if (trackingObserver?.isIntersecting) {
      segmentTracking('profile-result-seen', {
        ...trackingPayload,
        triggered_by: 'search results',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingObserver?.isIntersecting]);

  return (
    <li className="relative rounded-md border border-light-primary bg-white shadow-5">
      <div ref={cardRef} className="inset-y-0 left-0 absolute w-1" />
      <div className="flex items-start gap-16 p-16">
        <ProfileLink result={result} trackingPayload={trackingPayload} tabIndex={-1}>
          <Avatar
            fullName={result.full_name ?? ''}
            src={result.picture_url ?? ''}
            sx={{ width: 64, height: 64 }}
          />
        </ProfileLink>
        <div className="grow">
          <p className="hd-6">
            <ProfileLink result={result} trackingPayload={trackingPayload}>
              {result.full_name}
            </ProfileLink>
          </p>
          <OneLineList className="mt-4 text-grey-600 subtitle-1" mq={mq.lg}>
            <OneLineList.Item>{result.job_title}</OneLineList.Item>
            <OneLineList.Item>{result.country}</OneLineList.Item>
            <OneLineList.Item>{result.department}</OneLineList.Item>
          </OneLineList>
        </div>
        <div className="flex items-center gap-4">
          {enableSearchDebugging ? (
            <Button
              variant="tertiary"
              onClick={() => showDebugDialog()}
              srText="Debug"
              startIcon={Code}
            />
          ) : null}
          <ResultActionMenu
            profileId={result.profile_id ?? ''}
            email={result.email ?? ''}
            handleShortlistToggle={handleShortlistToggle}
            isAddedToShortlist={isAddedToShortlist}
          />
          <StartConversationButton email={result.email ?? ''} />
          {isAddedToShortlist ? (
            <Button
              variant="secondary"
              startIcon={Check}
              size="small"
              disabled={!!savedSearchId && isShortlistLoading}
              onClick={handleShortlistToggle}
              className="hidden sm:flex md:hidden lg:flex"
            >
              Added to Shortlist
              <span className="sr-only">Remove from shortlist</span>
            </Button>
          ) : (
            <Button
              className="hidden sm:flex md:hidden lg:flex"
              variant="tertiary"
              endIcon={Plus}
              size="small"
              disabled={!!savedSearchId && isShortlistLoading}
              onClick={handleShortlistToggle}
            >
              Add to Shortlist
            </Button>
          )}
        </div>
      </div>
      {matchedFilters.length ? (
        <div className="border-t border-grey-400">
          <HorizontalScroller>
            <MatchedTags
              currentRoute="search"
              profileId={result.profile_id ?? ''}
              matchedFilters={matchedFilters}
              trackingPayload={{
                ...trackingPayload,
                triggered_by: 'search results',
              }}
            />
          </HorizontalScroller>
        </div>
      ) : null}
    </li>
  );
};

export default Result;
