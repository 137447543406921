import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { redirect } from 'react-router';

import { fetchProject, updateProjectMember } from '@/actions/project';
import LayoutPage from '@/components/Layout/LayoutPage';
import RequestProjectAccess from '@/components/RequestProjectAccess';
import { hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import { Viewer } from '@/core/viewer';
import NotFoundPage from '@/pages/NotFoundPage';

import { ActionContext, LegacyRoute } from '../routesMiddleware';
import ProjectDetails from './ProjectDetails';

function requestAdd(query: URLSearchParams, viewer: Viewer, id: string) {
  return (
    <LayoutPage showNav selected="expert_requests">
      <Helmet>
        <title>Request Project Access</title>
      </Helmet>
      <RequestProjectAccess viewer={viewer} path="project" query={query} projectId={id} />
    </LayoutPage>
  );
}

const routes: LegacyRoute[] = [
  {
    // private project page
    path: '/project/:id',

    async action({
      store,
      params,
      query: queryRaw,
    }: ActionContext): Promise<JSX.Element | Response> {
      const query = Object.fromEntries(queryRaw.entries());
      const id = params.id!;
      const { token, approve_member: approve, deny_member: deny } = query;
      if (/(.+-[0-9]+)$/.test(id!)) {
        return redirect(`/expert_request/${id}?${queryString.stringify(query)}`);
      }

      const { viewer } = store.getState();
      if (!viewer.id) return requestAdd(queryRaw, viewer, id);

      let project;
      try {
        project = await store.dispatch(fetchProject(id));
      } catch (e: any) {
        if (hasErrorCode(e, ERROR_CODES.PROJECT_NOT_FOUND)) return <NotFoundPage />;

        if (!e.isPermissionError) throw e;
        return requestAdd(queryRaw, viewer, id);
      }

      if (token && (approve || deny)) {
        try {
          await store.dispatch(
            updateProjectMember(id, {
              id: approve || deny,
              state: approve ? 'active' : 'denied',
            })
          );
        } catch (e: unknown) {
          console.warn(e);
        }
        // we don't want to keep the token in the URL since user can share link
        return redirect(`/project/${id}`);
      }

      document.title = project.name;
      return <ProjectDetails projectId={id} />;
    },
  },
  {
    // legacy new project wizard flow
    path: '/projects/new',
    async action(): Promise<Response> {
      return redirect('/request_expert');
    },
  },
];

export default routes;
