import { useMemo } from 'react';
import { useParams } from 'react-router';

import { userSelector } from '@/auth/store/userSlice';
import { profileSelector } from '@/profilev2/store/profileSlice';
import { useAppSelector } from '@/store';

interface ProfileRouterParams {
  [index: string]: string;
}

export const useProfileId = (profileId?: string) => {
  const { data: userData } = useAppSelector(userSelector);
  const { id } = useParams<ProfileRouterParams>();
  return profileId || id || userData?.id || '';
};

export const useSelf = () => {
  const { data: userData } = useAppSelector(userSelector);
  const { data: profileData } = useAppSelector(profileSelector);
  const { id } = useParams<ProfileRouterParams>();
  return useMemo(
    () => !id || (!!id && userData?.id === profileData?.id),
    [id, profileData?.id, userData?.id]
  );
};
