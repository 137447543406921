import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { defaultNext, loginAs } from '@/auth';
import { useApp } from '@/hooks/useAppContext';

interface LoginAsProps {
  username: string;
  next?: string;
}

const LoginAs = ({ next, username }: LoginAsProps) => {
  const navigate = useNavigate();
  const { graphql, store } = useApp();

  useEffect(() => {
    const performLogin = async () => {
      try {
        await loginAs(graphql, store, username);
        window.location.assign(next || defaultNext);
      } catch {
        navigate(`/profile/${username}`, { replace: true });
      }
    };

    performLogin();
  }, [navigate, graphql, store, username, next]);

  return null;
};
export default LoginAs;
