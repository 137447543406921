import { useSearchParams } from 'react-router';

import { useProfileId } from '@/hooks/store';
import DetailsDrawerBase from '@/knowledge/components/KnowledgeDetailsDrawer/Base';
import { useLocationQuery } from '@/knowledge/queries/knowledge';
import { profileSelector } from '@/profilev2/store/profileSlice';
import APP_ROUTES, { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';

const LocationDetailsDrawer = ({ disableEditing }: { disableEditing?: boolean }) => {
  const profileId = useProfileId();
  const [searchParams] = useSearchParams();
  const { data: profileData } = useAppSelector(profileSelector);
  const parsedLocationName = decodeURIComponent(searchParams.get('q') ?? '');

  const { data, isFetched, isLoading } = useLocationQuery({
    profileId,
    name: parsedLocationName ?? '',
    includeExpanded: true,
  });

  const locationData = data?.results?.[0];

  return (
    <DetailsDrawerBase
      disableEditing={disableEditing}
      knowledgeData={locationData}
      type="location"
      isFetched={isFetched}
      isLoading={isLoading}
      breadcrumbs={[
        {
          title: profileData?.full_name ?? '',
          to: '../' + APP_ROUTES.profile(profileId),
        },
        {
          title: 'Locations',
          to: '../' + PROFILE_ROUTES().locations,
        },
        {
          title: parsedLocationName ?? '',
        },
      ]}
    />
  );
};

export default LocationDetailsDrawer;
