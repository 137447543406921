import cx from 'classnames';

import Link from '../Link';
import s from './CountBox.module.scss';

interface CountBoxProps {
  count?: number;
  label?: string;
  className?: string;
  grayOutZero?: boolean;
  linkTo?: string;
  labelClassName?: string;
  selected?: boolean;
  warn?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
}

const CountBox = ({
  count = 0,
  grayOutZero,
  label,
  warn,
  linkTo,
  onClick,
  className,
  labelClassName,
  selected,
}: CountBoxProps): JSX.Element => {
  const grayOut = grayOutZero && count === 0;

  const contents = (
    <div
      className={cx(s.root, className, {
        [s.grayOut]: grayOut,
        [s.selected]: selected,
        [s.warn]: warn,
      })}
    >
      <div className={s.count}>{count}</div>
      {label && (
        <span
          className={cx(s.label, {
            [labelClassName || '']: !!labelClassName,
          })}
        >
          {label}
        </span>
      )}
    </div>
  );

  return linkTo || onClick ? (
    <Link to={linkTo || '#'} onClick={onClick}>
      {' '}
      {contents}{' '}
    </Link>
  ) : (
    contents
  );
};

export default CountBox;
