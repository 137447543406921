import { Helmet } from 'react-helmet';
import { redirect } from 'react-router';

import { GroupDomain, fetchSignupDomain } from '@/actions/domain';
import { acceptInvitation } from '@/actions/invitation';
import SelectSignupType from '@/auth/components/SelectSignupType';
import LayoutPage from '@/components/Layout/LayoutPage';
import { marketingLinks } from '@/components/MarketingLayout/marketingLinks';
import NotFoundPage from '@/pages/NotFoundPage';
import { LegacyRoute } from '@/routes/routesMiddleware';
import { fetchUser } from '@/store/user';
import { queryPart, rewriteUrl } from '@/utils';
import { getJoinTitle } from '@/utils/reducer';

import ClientRegisterPage from './ClientRegisterPage';
import ExpertRegisterPage from './ExpertRegisterPage';

const APPLICATION_PATH = '/signup/expert/basic-information';

const route: LegacyRoute = {
  //subdomain: '*',
  path: '/:subdomain?/signup/:type?',
  async action({ store, query, params }) {
    let domain: GroupDomain | null = null;
    if (params.subdomain) {
      domain = await store.dispatch(fetchSignupDomain(params.subdomain));
      if (!domain) return <NotFoundPage />;
    }

    const { stepPath = '', type } = params;
    const invite = query.get('invite');
    const tags = query.getAll('t');
    const next = query.get('next');
    const email = query.get('email');
    const { viewer } = store.getState();
    const defaultRedirect = next || '/dashboard';

    const links = marketingLinks({
      showLogin: !domain,
      domain: params.subdomain,
    });

    if (!type) {
      if (viewer.id) {
        return redirect(defaultRedirect);
      }

      return (
        <LayoutPage headerBorder={false} marketingLinks={links}>
          <Helmet>
            <title>Create OnFrontiers account</title>
          </Helmet>
          <SelectSignupType subdomain={params.subdomain} nextUrl={next} />
        </LayoutPage>
      );
    }

    if (viewer.id && invite) {
      try {
        const redirectTo = await store.dispatch(acceptInvitation(invite));
        if (redirectTo) {
          return redirect(rewriteUrl(redirectTo));
        }
        return redirect(defaultRedirect);
      } catch (e) {
        console.error(e);
        return redirect(defaultRedirect);
      }
    }

    const defaultEmail = email && decodeURIComponent(email);

    switch (type) {
      case 'member':
        return signupMember({
          stepPath,
          invite,
          domain,
          tags,
          next,
          viewer,
          defaultEmail,
          links,
        });
      case 'expert':
        if (domain) {
          return joinExpert({
            invite,
            domain,
            tags,
            next: next ?? APPLICATION_PATH,
            viewer,
            store,
            defaultEmail,
            links,
          });
        }

        return signupExpert({
          invite,
          tags,
          next: next ?? APPLICATION_PATH,
          viewer,
          store,
          defaultEmail,
          links,
        });
      default:
        return redirect('/signup');
    }
  },
};

async function signupMember({
  stepPath,
  invite,
  domain,
  tags,
  next,
  viewer,
  defaultEmail,
  links,
}: any) {
  // if (!invite && !domain) {
  //   return {
  //     redirect: `/select_domain${queryPart(query)}`,
  //   };
  // }

  // Path should be empty, redirect to correct path
  if (stepPath) {
    return redirect(`/signup/member${queryPart({ next, invite })}`);
  }

  // User already logged in should not land on this page.
  // Redirect to dashboard
  if (viewer.id) {
    return redirect(`/dashboard`);
  }

  const title = 'Join your team at OnFrontiers';
  document.title = title;
  return (
    <ClientRegisterPage
      marketingLinks={links}
      domain={domain}
      title={title}
      tags={tags}
      invite={invite}
      defaultEmail={defaultEmail}
      nextUrl={next}
    />
  );
}

async function signupExpert({ next, tags, invite, viewer, defaultEmail, links }: any) {
  const title = 'Join the OnFrontiers Expert Marketplace';
  document.title = title;

  // Go to application
  if (viewer.id) {
    return redirect(APPLICATION_PATH);
  }

  return (
    <ExpertRegisterPage
      marketingLinks={links}
      title={title}
      tags={tags}
      invite={invite}
      defaultEmail={defaultEmail}
      nextUrl={next}
    />
  );
}

async function joinExpert({ domain, next, tags, invite, viewer, store, defaultEmail, links }: any) {
  const title = getJoinTitle(domain);

  if (viewer.id) {
    await store.dispatch(
      fetchUser(viewer.username, {
        signupSubdomain: true,
        internalNetworks: true,
      })
    );
  }

  document.title = title;
  return (
    <ExpertRegisterPage
      marketingLinks={links}
      domain={domain}
      title={title}
      tags={tags}
      invite={invite}
      defaultEmail={defaultEmail}
      nextUrl={next}
    />
  );
}

export default route;
