import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  fetchExpertShowcase,
  removeExpertShowcase,
  updateExpertShowcase,
} from '@/actions/expertShowcase';
import { notify } from '@/actions/ui';

import ModelForm from '../ModelForm';

function ExpertShowcaseModel({
  id,
  model,
  fetchExpertShowcase,
  updateExpertShowcase,
  removeExpertShowcase,
  notify,
  expertShowcase,
}: any) {
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      fetchExpertShowcase({ id }).then((page: any) => {
        if (!page) {
          notify('Not found', 'warning');
          navigate(`/admin/${model.path}`);
        }
      });
    }
  }, [id]);

  const editableFields = useMemo(
    () => [
      { name: 'subdomain', type: 'text', required: true },
      { name: 'path', type: 'text', required: true },
      { name: 'page_title', type: 'text' },
      { name: 'title', type: 'text' },
      { name: 'subtitle', type: 'textarea' },
      { name: 'header_note', type: 'textarea' },
      { name: 'background_url', type: 'file' },
      { name: 'header_logo_url', type: 'file' },
      { name: 'header_logo_link_url', type: 'url' },
      { name: 'meta_image_url', type: 'file' },
      { name: 'google_tag_manager_id', type: 'text' },
      { name: 'show_footer', type: 'checkbox' },
      {
        name: 'ctas',
        allRequired: true,
        fields: [
          { name: 'label', type: 'text', maxLength: 40 },
          { name: 'url', type: 'text' },
          { name: 'order', type: 'number' },
        ],
      },
      {
        name: 'categories',
        fields: [
          { name: 'name', type: 'text', required: true },
          { name: 'order', type: 'number', required: true },
          {
            name: 'id',
            type: 'edit_link',
            getURL: (id: any) => {
              return id ? `/admin/expert_showcase_categories/${id}` : undefined;
            },
          },
        ],
      },
    ],
    []
  );

  const handleSubmit = useCallback(async (values: any) => {
    values.categories?.forEach((c: any) => {
      delete c.sections;
    });
    const page = await updateExpertShowcase(values);
    navigate(`/admin/${model.path}/${page.id}`);
  }, []);

  const handleDelete = useCallback(() => removeExpertShowcase(id), []);

  return (
    <ModelForm
      title={id ? `Expert Showcase ${id} - ${expertShowcase.title}` : 'Create new expert showcase'}
      id={id}
      model={model}
      onDelete={handleDelete}
      onSubmit={handleSubmit}
      initialModel={expertShowcase}
      fields={editableFields}
      returnPath={model.path}
    />
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'ExpertShowcaseModel' because it ... Remove this comment to see the full error message
ExpertShowcaseModel = connect(
  (state, ownProps) => {
    return {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      expertShowcase: state.expertShowcase.full[ownProps.id] || {},
    };
  },
  {
    fetchExpertShowcase,
    updateExpertShowcase,
    removeExpertShowcase,
    notify,
  }
)(ExpertShowcaseModel);

export default ExpertShowcaseModel;
