import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { fetchLandingPages } from '@/actions/landing';

import ListItems from '../ListItems';
import LandingPageModel from './LandingPageModel';

export default function LandingPageModels({ fetchLandingPages, landingPages, model, id }: any) {
  const navigate = useNavigate();
  useEffect(() => {
    fetchLandingPages();
  }, []);

  const items = landingPages.map((page: any) => ({
    id: page.id,
    name: `${page.id} ${page.subdomain} - ${page.title}`,
  }));

  return id ? (
    <LandingPageModel model={model} id={id !== 'new' ? id : undefined} />
  ) : (
    <ListItems model={model} items={items} onCreate={() => navigate(`/admin/${model.path}/new`)} />
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'LandingPageModels' because it is... Remove this comment to see the full error message
LandingPageModels = connect(
  (state) => {
    return {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      landingPages: state.landing.all || [],
    };
  },
  {
    fetchLandingPages,
  }
)(LandingPageModels);
