import { useQuery } from '@apollo/client';
import { useParams, useSearchParams } from 'react-router';

import { gengql } from '@/__generated__';
import { SignupType } from '@/auth';
import AuthPage from '@/auth/components/AuthPage';
import EmptyMessage from '@/components/EmptyMessage';
import { hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import NotFoundPage from '@/pages/NotFoundPage';
import { rewriteUrl } from '@/utils';

const GET_EXPERT_REQUEST = gengql(/* GraphQL */ `
  query referralAuthPageGetExpertRequest($id: String!) {
    expertRequest(id: $id) {
      id
      public_html_url
      slug
    }
  }
`);

const ReferralAuthPage = (): JSX.Element | null => {
  const params = useParams();
  const expertRequestId = params.id!;
  const signup = params.auth === 'signup';
  const [query] = useSearchParams();
  const tags = [...query.getAll('t[]'), ...query.getAll('t')];

  const { data, error } = useQuery(GET_EXPERT_REQUEST, {
    variables: {
      id: expertRequestId,
    },
  });

  if (hasErrorCode(error, ERROR_CODES.EXPERT_REQUEST_NOT_FOUND)) {
    return <NotFoundPage />;
  }

  const expertRequest = data?.expertRequest;
  if (!expertRequest) {
    return null;
  }

  return (
    <AuthPage
      signupType={SignupType.Expert}
      nextUrl={rewriteUrl(expertRequest.public_html_url)}
      signup={signup}
      signupLink={`/expert_request/${expertRequest.slug}/refer/signup`}
      signinLink={`/expert_request/${expertRequest.slug}/refer/signin`}
      tags={tags}
    >
      <EmptyMessage
        border={false}
        style={{ padding: 15 }}
        title="Refer friends for paid calls on OnFrontiers."
        body="You’ll receive a $100 bonus to your account once they book a call."
      />
    </AuthPage>
  );
};

export default ReferralAuthPage;
