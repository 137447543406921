import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import { useRef } from 'react';
import { Trash2 } from 'react-feather';
import { Link, useParams } from 'react-router';

import Avatar from '@/auth/components/Avatar';
import Button from '@/componentsv2/Button';
import { Drawer } from '@/componentsv2/Drawer';
import APP_ROUTES from '@/routes/APP_ROUTES';
import { useRemoveFromShortlist } from '@/searchv2/mutations/shortlist';
import { useSavedSearchShortlistQuery } from '@/searchv2/queries/shortlist';
import { closeShortlistDrawer, searchSelector } from '@/searchv2/store/searchSlice';
import { useAppDispatch, useAppSelector } from '@/store';
import { arrayFromRange } from '@/utils/array';

const ItemSkeleton = ({ index }: { index: number }) => {
  return (
    <li className="py-16" style={{ opacity: 1 - index * 0.15 }}>
      <div className="flex items-center gap-12 text-18">
        <Skeleton variant="circular" width={32} height={32} />
        <Skeleton variant="text" width={200} />
      </div>
    </li>
  );
};

const ShortlistContent = ({ handleClose }: { handleClose: () => void }) => {
  const { savedSearchId } = useParams();
  const scrollableListRef = useRef<HTMLDivElement>(null);
  const { mutate: removeFromShortlist } = useRemoveFromShortlist();

  const { data, isLoading, isPreviousData } = useSavedSearchShortlistQuery({
    id: savedSearchId ?? '',
  });
  const shortlistProfiles = data?.results ?? [];

  return (
    <Drawer.Main ref={scrollableListRef} bgClassName="bg-grey-50">
      {!!savedSearchId && (shortlistProfiles.length || isLoading) ? (
        <ul
          className={clsx('divide-y divide-grey-400', {
            'opacity-50': isPreviousData,
          })}
        >
          {shortlistProfiles.length
            ? shortlistProfiles.map((profile) => {
                return (
                  <li key={profile.profile_id} className="flex items-center justify-between py-16">
                    <Link
                      to={APP_ROUTES.profile(profile.profile_id)}
                      className="flex items-center gap-12 text-18 font-medium text-brand-tertiary hover:text-brand-secondary"
                      onClick={handleClose}
                    >
                      <Avatar
                        fullName={profile.full_name}
                        src={profile.picture_url}
                        sx={{
                          width: 32,
                          height: 32,
                        }}
                      />
                      <span className="underline">{profile.full_name}</span>
                    </Link>
                    <Button
                      variant="tertiary"
                      className="shrink-0"
                      srText="delete"
                      onClick={() => {
                        removeFromShortlist({
                          id: savedSearchId,
                          profileId: profile.profile_id,
                        });
                      }}
                      startIcon={Trash2}
                    />
                  </li>
                );
              })
            : arrayFromRange({ max: 5 }).map((i) => {
                return <ItemSkeleton key={i} index={i} />;
              })}
        </ul>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center gap-8">
          <p className="text-18 font-medium">
            You haven’t added any profiles to this shortlist yet.
          </p>
          <p className="text-14 text-grey-500">Add profiles here to easily access them later.</p>
        </div>
      )}
    </Drawer.Main>
  );
};
const ShortlistDrawer = () => {
  const dispatch = useAppDispatch();
  const { shortlistDrawerIsOpen } = useAppSelector(searchSelector);
  const handleClose = () => dispatch(closeShortlistDrawer());

  return (
    <Drawer open={shortlistDrawerIsOpen} onClose={handleClose} size="sm">
      <Drawer.Header breadcrumbs={[{ title: 'Shortlist' }]} onClose={handleClose} />
      {shortlistDrawerIsOpen ? <ShortlistContent handleClose={handleClose} /> : null}
    </Drawer>
  );
};

export default ShortlistDrawer;
