import { useQuery } from '@apollo/client';

import { gengql } from '@/__generated__';
import { SignupType } from '@/auth';
import AuthPage from '@/auth/components/AuthPage';
import EmptyMessage from '@/components/EmptyMessage';
import { hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import NotFoundPage from '@/pages/NotFoundPage';
import { rewriteUrl } from '@/utils';

interface ExpertAuthPageProps {
  expertRequestId: string;
  signup?: boolean;
  tags?: string[];
}

export const GET_EXPERT_REQUEST = gengql(/* GraphQL */ `
  query expertAuthPageGetExpertRequest($id: String!) {
    expertRequest(id: $id) {
      id
      name
      public_html_url
      slug
    }
  }
`);

const ExpertAuthPage = ({
  expertRequestId,
  signup,
  tags,
}: ExpertAuthPageProps): JSX.Element | null => {
  const { data, error } = useQuery(GET_EXPERT_REQUEST, { variables: { id: expertRequestId } });

  if (hasErrorCode(error, ERROR_CODES.EXPERT_REQUEST_NOT_FOUND)) {
    return <NotFoundPage />;
  }

  const expertRequest = data?.expertRequest;
  if (!expertRequest) {
    return null;
  }

  return (
    <AuthPage
      signupType={SignupType.Expert}
      nextUrl={`${rewriteUrl(expertRequest.public_html_url)}/request_add`}
      signup={signup}
      signupLink={`/expert_request/${expertRequest.slug}/request_add/signup`}
      signinLink={`/expert_request/${expertRequest.slug}/request_add/signin`}
      tags={tags}
    >
      <EmptyMessage
        border={false}
        style={{ padding: 15 }}
        title="We’re glad this project seems like a good match."
        body="We just need you to complete our quick application."
      />
    </AuthPage>
  );
};

export default ExpertAuthPage;
