import makeStyles from '@mui/styles/makeStyles';
import { ConnectedProps, connect } from 'react-redux';
import { Field, InjectedFormProps, formValueSelector, reduxForm } from 'redux-form';

import { IsGoodMatch } from '@/__generated__/graphql';
import { notify } from '@/actions/ui';
import EditDialog, { EditDialogProps } from '@/components/EditDialog';
import { RadioGroup, TextField } from '@/components/FormAdapters';
import { Candidate } from '@/expertrequest';
import { RootState } from '@/store';

type Profile = NonNullable<Candidate['profile']>;

const useStyles = makeStyles(() => ({
  isGoodMatchField: {
    marginTop: 20,
  },
}));

export interface FormData {
  id: string;
  client_note: {
    note?: string;
    is_good_match?: IsGoodMatch;
  };
}

export interface EditClientNoteProps extends EditDialogProps {
  initialValues: FormData;
  onSubmit: (values: FormData) => void | Promise<void>;
  noteValue?: string;
  profile: Profile;
  showMatchOptions?: boolean;
}

const formName = `candidateClientNote`;
const connector = connect(
  (state: RootState) => {
    return {
      form: formName,
      noteValue: formValueSelector(formName)(state, 'client_note.note'),
    };
  },
  {
    notify,
  }
);

const EditClientNote = ({
  onSubmit,
  handleSubmit,
  reset,
  noteValue,
  profile,
  showMatchOptions = false,
  notify,
  ...other
}: EditClientNoteProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<FormData, EditClientNoteProps>) => {
  const s = useStyles();

  const finalSubmit = async (values: FormData) => {
    try {
      const ret = onSubmit(values);
      if (ret) {
        await ret;
      }
      notify('Note to Research Manager updated.', 'success');
    } catch (err: unknown) {
      notify('Error while updating note to Research Manager.', 'error');
      console.error(err);
    }
  };

  return (
    <EditDialog
      {...other}
      onSubmit={handleSubmit(finalSubmit)}
      onReset={() => reset()}
      // bogus attribute (force dialog position update on text change)
      fieldValue={noteValue}
    >
      <Field
        id="client_note.note"
        component={TextField}
        fullWidth
        multiline
        name="client_note.note"
        label="Note to Research Manager"
        maxRows={15}
      />
      {showMatchOptions && (
        <Field
          name="client_note.is_good_match"
          component={RadioGroup}
          label={`Is ${profile.first_name} a good match?`}
          className={s.isGoodMatchField}
          style={{ flexDirection: 'row' }}
          options={[
            { label: 'No', value: IsGoodMatch.No },
            { label: 'Maybe', value: IsGoodMatch.Maybe },
            { label: 'Yes', value: IsGoodMatch.Yes },
          ]}
        />
      )}
    </EditDialog>
  );
};

export default reduxForm<FormData, EditClientNoteProps>({
  enableReinitialize: true,
  form: formName,
})(connector(EditClientNote));
