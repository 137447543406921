import { Button as MaterialButton } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { track } from '@/actions/tracking';
import { notify, popup } from '@/actions/ui';
import { Viewer } from '@/core/viewer';
import { channelUrlToId } from '@/messaging/sendbird';
import { createChannel } from '@/messaging/store';
import { RootState } from '@/store';

import MaterialIcon from '../Icon/MaterialIcon';
import { promoPopup } from './promo';

const CREATE_CHANNEL_AUTH_ERROR = 'GraphQL Error: basic accounts cannot create messaging channels';

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    popup,
    notify,
    track,
    createChannel,
  }
);

interface SendMessageButtonProps {
  viewer: Viewer;
  userId: string;
  [key: string]: any;
}

const SendMessageButton: FC<SendMessageButtonProps & ConnectedProps<typeof connector>> = ({
  viewer,
  userId,
  notify,
  createChannel,
  popup,
  track,
  ...other
}) => {
  const navigate = useNavigate();
  const [creating, setCreating] = useState(false);

  const handleSendMessage = useCallback(() => {
    setCreating(true);
    createChannel([viewer.id, userId])
      .then((channel) => {
        navigate(`/messaging/${channelUrlToId(channel.url)}`);
      })
      .catch((err: any) => {
        setCreating(false);

        if (err.message === CREATE_CHANNEL_AUTH_ERROR) {
          return promoPopup(popup, track);
        }

        notify('An error occurred when starting chat with user.', 'error');
        throw err;
      });
  }, [createChannel, navigate, notify, popup, track, userId, viewer.id]);

  return (
    <MaterialButton
      size="small"
      startIcon={<MaterialIcon icon="chat" />}
      onClick={handleSendMessage}
      disabled={creating}
      {...other}
    >
      Send Message
    </MaterialButton>
  );
};

export default connector(SendMessageButton);
