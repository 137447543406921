import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useNavigate, useSearchParams } from 'react-router';

import Button from '@/componentsv2/Button';
import APP_ROUTES from '@/routes/APP_ROUTES';
import { useCreateSavedSearch, useUpdateSavedSearch } from '@/searchv2/mutations/savedSearch';
import { useAddToShortlist } from '@/searchv2/mutations/shortlist';
import { useSavedSearchQuery } from '@/searchv2/queries/savedSearch';
import {
  closeCreateOrEditSearch,
  searchSelector,
  showCollaboratorsDialog,
} from '@/searchv2/store/searchSlice';
import { useAppDispatch, useAppSelector } from '@/store';

const DialogForm = ({ handleClose }: { handleClose: () => void }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { editId: savedSearchId, initialShortlistOnSearchCreation } =
    useAppSelector(searchSelector);
  const isSaved = !!savedSearchId;
  const { data: savedSearch, isLoading: isSavedSearchLoading } = useSavedSearchQuery(
    savedSearchId ?? ''
  );
  const [name, setName] = useState(savedSearch?.name ?? '');

  useEffect(() => {
    if (savedSearch) {
      setName(savedSearch.name);
    }
  }, [savedSearch]);

  const { mutate: createSavedSearch, isLoading: isCreateLoading } = useCreateSavedSearch();
  const { mutate: updateSavedSearch, isLoading: isUpdateLoading } = useUpdateSavedSearch();
  const { mutate: addToShortlist } = useAddToShortlist();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = { name, hash: searchParams.get('hash') || '' };

    if (savedSearchId) {
      updateSavedSearch(
        { ...payload, id: savedSearchId },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    } else {
      createSavedSearch(payload, {
        onSuccess: (data) => {
          navigate(APP_ROUTES.savedSearch(data.id, 'saved'));
          const snackbarMessage = [`${payload.name} has been saved.`];
          if (initialShortlistOnSearchCreation?.length) {
            addToShortlist({
              id: data.id,
              profiles: initialShortlistOnSearchCreation,
            });
            const [addedProfile] = initialShortlistOnSearchCreation;
            snackbarMessage.push(`${addedProfile.full_name} has been added to your shortlist.`);
          }
          enqueueSnackbar(snackbarMessage.join(' '), { variant: 'success' });
          dispatch(showCollaboratorsDialog());
          handleClose();
        },
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>
        <div className="flex items-center justify-between">
          <h3 className="hd-6">{isSaved ? 'Edit' : 'Save'} Search</h3>
          <Button
            variant="tertiary"
            onClick={handleClose}
            srText="close"
            startIcon={X}
            size="large"
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          disabled={isSaved && isSavedSearchLoading}
          required
          className="w-full"
          id="name"
          label="Name"
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isUpdateLoading || isCreateLoading}
          loading={isUpdateLoading || isCreateLoading}
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

const SavedSearchCreateOrEditDialog = () => {
  const dispatch = useAppDispatch();
  const { createOrEditDialogIsOpen } = useAppSelector(searchSelector);
  const handleClose = () => dispatch(closeCreateOrEditSearch());

  return (
    <Dialog
      open={createOrEditDialogIsOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { width: '100%', maxWidth: 400 },
      }}
    >
      {createOrEditDialogIsOpen ? <DialogForm handleClose={handleClose} /> : null}
    </Dialog>
  );
};

export default SavedSearchCreateOrEditDialog;
