import clsx from 'clsx';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useRef, useState } from 'react';
import { Edit2, Trash2 } from 'react-feather';
import { Link, useNavigate, useParams } from 'react-router';

import { Pagination } from '@/componentsv2';
import Button from '@/componentsv2/Button';
import AreYouSureDialog from '@/componentsv2/ConfirmDialog/AreYouSureDialog';
import { Drawer } from '@/componentsv2/Drawer';
import { useProfileId } from '@/hooks/store';
import APP_ROUTES from '@/routes/APP_ROUTES';
import { useRemoveSearchCollaborator } from '@/searchv2/mutations/searchCollaborators';
import {
  DEFAULT_SAVED_SEARCHES_LIMIT,
  useSavedSearchesQuery,
} from '@/searchv2/queries/savedSearch';
import {
  closeSavedSearchesDrawer,
  createOrEditSearch,
  searchSelector,
} from '@/searchv2/store/searchSlice';
import { useAppDispatch, useAppSelector } from '@/store';

import CollaboratorsList from './CollaboratorsList';

const SEARCHES_LIMIT = DEFAULT_SAVED_SEARCHES_LIMIT;

const SavedSearchesDrawer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { savedSearchId } = useParams();
  const profileId = useProfileId();
  const { savedSearchesDrawerIsOpen } = useAppSelector(searchSelector);
  const scrollableListRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [confirmRemoveId, setConfirmRemoveId] = useState<string | null>(null);
  const { mutate: removeSearchCollaborator } = useRemoveSearchCollaborator();

  const { data, isLoading, isPreviousData } = useSavedSearchesQuery({
    limit: SEARCHES_LIMIT,
    page,
  });
  const savedSearches = data?.results || [];

  const handleClose = () => dispatch(closeSavedSearchesDrawer());

  const handlePageChange = useCallback((page: number) => {
    setPage(page);
    scrollableListRef.current?.scrollTo(0, 0);
  }, []);

  return (
    <Drawer open={savedSearchesDrawerIsOpen} onClose={handleClose} size="sm">
      <Drawer.Header breadcrumbs={[{ title: 'My Saved Searches' }]} onClose={handleClose} />
      <Drawer.Main ref={scrollableListRef} bgClassName="bg-grey-50">
        {savedSearches.length ? (
          <>
            <ul
              className={clsx({
                'opacity-50': isPreviousData,
              })}
            >
              {savedSearches.map((search) => {
                return (
                  <li
                    key={search.id}
                    className="flex items-center justify-between border-b border-grey-400 py-16"
                  >
                    <Link
                      to={APP_ROUTES.savedSearch(search.id, 'drawer')}
                      className="text-18 font-medium text-brand-tertiary underline hover:text-brand-secondary"
                      onClick={handleClose}
                    >
                      {search.name}
                    </Link>
                    <div className="ml-16 flex gap-2">
                      <Button
                        variant="tertiary"
                        srText="edit"
                        onClick={() => {
                          dispatch(createOrEditSearch({ savedSearchId: search.id }));
                        }}
                        startIcon={Edit2}
                      />
                      <Button
                        variant="tertiary"
                        srText="delete"
                        onClick={() => {
                          setConfirmRemoveId(search.id);
                        }}
                        startIcon={Trash2}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
            {data?.count && data.count > SEARCHES_LIMIT ? (
              <div className="mt-auto">
                <Pagination
                  disabled={isLoading}
                  page={page}
                  count={Math.ceil(data.count / SEARCHES_LIMIT)}
                  handlePageChange={(_, page) => handlePageChange(page)}
                />
              </div>
            ) : null}
            <AreYouSureDialog
              open={!!confirmRemoveId}
              onCancel={() => setConfirmRemoveId(null)}
              onConfirm={() => {
                if (!confirmRemoveId) return;
                if ((data?.count ?? 0) % SEARCHES_LIMIT === 1 && page > 1) {
                  setPage(page - 1);
                }
                removeSearchCollaborator({ id: confirmRemoveId, profileId });
                setConfirmRemoveId(null);
                enqueueSnackbar('Search removed', { variant: 'success' });
                if (confirmRemoveId === savedSearchId) {
                  handleClose();
                  navigate(APP_ROUTES.search);
                }
              }}
              cancelButtonLabel="Cancel"
              confirmButtonLabel="Remove"
              subtitle={
                <div className="space-y-16 body-1">
                  <p>
                    You will <strong>permanently lose access</strong> to the saved search criteria
                    and shortlist, unless an existing collaborator shares it with you again.
                  </p>
                  <p>The following people will continue to have access after this action:</p>
                  <div className="rounded-sm border border-grey-400 p-8">
                    <CollaboratorsList
                      disableRemove
                      savedSearchId={confirmRemoveId ?? ''}
                      fallbackMessage="No current collaborators"
                    />
                  </div>
                </div>
              }
            />
          </>
        ) : (
          <div className="flex h-full w-full flex-col items-center justify-center gap-8">
            <p className="text-18 font-medium">You have no saved searches.</p>
            <p className="text-14 text-grey-500">Save your search to easily find it later.</p>
          </div>
        )}
      </Drawer.Main>
    </Drawer>
  );
};

export default SavedSearchesDrawer;
