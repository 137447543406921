import { useQuery } from '@apollo/client';
import queryString from 'query-string';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { gengql } from '@/__generated__';
import { requestAddProjectMember } from '@/actions/project';
import { notify } from '@/actions/ui';
import AuthChoice from '@/auth/components/AuthChoice';
import { Viewer } from '@/core/viewer';
import { darkBlue, red500 } from '@/theme/colors';

import Button from '../Button/Button';
import EmptyMessage from '../EmptyMessage';
import MaterialIcon from '../Icon/MaterialIcon';
import s from './RequestProjectAccess.module.scss';

const ALREADY_ADDED = 'GraphQL Error: project member already added';

interface RequestProjectAccessProps {
  viewer: Viewer;
  path: string;
  query: URLSearchParams;
  expertRequestId?: string;
  projectId?: string;
}

const GET_MEMBERSHIP_APPROVAL = gengql(/* GraphQL */ `
  query requestProjectAccessGetMembershipApproval($projectId: String, $expertRequestId: String) {
    awaitingProjectMembershipApproval(project_id: $projectId, expert_request_id: $expertRequestId)
  }
`);

const connector = connect(undefined, {
  requestAddProjectMember,
  notify,
});

const RequestProjectAccess = ({
  viewer,
  expertRequestId,
  projectId,
  path,
  query,
  requestAddProjectMember,
  notify,
}: RequestProjectAccessProps & ConnectedProps<typeof connector>) => {
  const { data } = useQuery(GET_MEMBERSHIP_APPROVAL, {
    variables: { projectId, expertRequestId },
    skip: !viewer.id,
  });

  const accessRequested = data?.awaitingProjectMembershipApproval;

  const [accessRequestedState, setAccessRequestedState] = useState(accessRequested || false);

  const isSignedIn = !!viewer.id;
  const queryPart = query ? `?${queryString.stringify(query)}` : '';
  const nextUrl = `/${path}/${expertRequestId || projectId}${queryPart}`;

  const actionText = isSignedIn ? 'Request' : 'Log in or request';

  const handleRequestAdd = async () => {
    try {
      await requestAddProjectMember({ expertRequestId, projectId });
      setAccessRequestedState(true);
    } catch (err: unknown) {
      if ((err as Error).message === ALREADY_ADDED) {
        return setAccessRequestedState(true);
      }
      notify('An error occurred when requesting access to project.', 'error');
    }
  };

  return (
    <div className={s.root}>
      {accessRequestedState ? (
        <EmptyMessage
          border={false}
          style={{ padding: '30px 0 15px' }}
          icon={<MaterialIcon icon="access_time" color={darkBlue} size={52} />}
          title="You have requested access to this project"
          body="We will notify you when the project owner accepts your request."
        />
      ) : (
        <EmptyMessage
          border={false}
          style={{ padding: '30px 0 15px' }}
          icon={<MaterialIcon icon="block" color={red500} size={52} />}
          title="You do not have access to this project"
          body={`${actionText} access from the project owner to view and add experts, receive updates, and get calendar invitations for booked calls.`}
          action={
            isSignedIn && (
              <Button size="large" onClick={handleRequestAdd}>
                Request Access
              </Button>
            )
          }
        />
      )}
      {!isSignedIn && <AuthChoice nextUrl={nextUrl} />}
    </div>
  );
};

export default connector(RequestProjectAccess);
