import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Edit2, Trash2 } from 'react-feather';
import { Navigate, useNavigate, useParams } from 'react-router';

import { useProjectQuery } from '@/assignment/queries/project';
import { useProjectKnowledgeQuery } from '@/assignment/queries/projectKnowledge';
import { useRolesForUserByProjectIdQuery } from '@/assignment/queries/projectMembers';
import { editProject, editRoles } from '@/assignment/store/manageProjectSlice';
import Button from '@/componentsv2/Button';
import AreYouSureDialog from '@/componentsv2/ConfirmDialog/AreYouSureDialog';
import { Drawer } from '@/componentsv2/Drawer';
import { Breadcrumb } from '@/componentsv2/Drawer/types';
import { segmentTracking } from '@/core/analytics';
import { useProfileId, useSelf } from '@/hooks/store';
import useCloseDrawer from '@/hooks/useCloseDrawer';
import useProfileBreadcrumbs from '@/hooks/useProfileBreadcrumbs';
import KnowledgeColumn from '@/knowledge/components/KnowledgeColumn';
import { accrualUpdate, markAccrualStale } from '@/knowledge/store/accrualSummarySlice';
import Overview from '@/profilev2/components/ExperienceDetailsDrawer/Overview';
import { addDelete, batchUpdateExperiences } from '@/profilev2/store/manageExperiencesSlice';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppDispatch } from '@/store';
import { safeFormatDate } from '@/utils/date';
import { formatCurrencyValue } from '@/utils/texts';

import { TITLE_DICT } from '../ContractsDrawer';
import ProjectRolesTable from './ProjectRolesTable';
import WorkedWith from './WorkedWith';

const testId = 'of-project-details-drawer';

const PROJECT_LIST_BREADCRUMB: Record<string, Breadcrumb> = {
  Project: {
    title: TITLE_DICT.Project,
    to: '../' + PROFILE_ROUTES().projects,
  },
  Proposal: {
    title: TITLE_DICT.Proposal,
    to: '../' + PROFILE_ROUTES().proposals,
  },
};

const ProjectDetailsDrawer = ({ disableEditing }: { disableEditing?: boolean }) => {
  const profileId = useProfileId();
  const isSelf = useSelf();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleCloseDrawer = useCloseDrawer();
  const queryClient = useQueryClient();
  const [showConfirmDeleteAllRoles, setShowConfirmDeleteAllRoles] = useState(false);

  const { projectId } = useParams();

  const {
    data: project,
    isLoading: isProjectLoading,
    isFetched: isProjectFetched,
  } = useProjectQuery({
    projectId: projectId ?? '',
    profileId,
  });
  const { data: roles, isLoading: isRolesLoading } = useRolesForUserByProjectIdQuery({
    projectId,
    profileId,
    limit: 100,
  });
  const {
    data: knowledges,
    isLoading: isKnowledgesLoading,
    isFetched: isKnowledgesFetched,
  } = useProjectKnowledgeQuery(
    { profileId, projectId: projectId ?? '' },
    { staleTime: Infinity, refetchOnMount: 'always' }
  );

  useEffect(() => {
    if (projectId) {
      const segmentPayload = {
        event_type: 'Project',
        event_name: 'View Project',
        project_id: projectId,
      };
      segmentTracking('view-project', segmentPayload);
    }
  }, [projectId, dispatch, profileId]);

  const createDetailsMain = () => {
    const detailsArr = [];
    if (project?.funding_organization) {
      detailsArr.push(`Funded by: ${project?.funding_organization}`);
    }
    if (project?.prime_country) {
      detailsArr.push(project?.prime_country);
    }
    if (project?.regions.length) {
      detailsArr.push(project?.regions[0].name);
    }
    if (project?.start_date || project?.end_date) {
      detailsArr.push(
        `${safeFormatDate(project?.start_date ?? false)} -
        ${safeFormatDate(project?.end_date ?? false, 'Present')}`
      );
    }
    return detailsArr;
  };

  const createDetailsSecondary = () => {
    const detailsArr = [];
    if (project?.contract_type) {
      detailsArr.push(project?.contract_type);
    }
    if (project?.project_status) {
      detailsArr.push(project?.project_status);
    }
    if (project?.contract_value) {
      detailsArr.push(
        formatCurrencyValue(project?.contract_value_currency, project?.contract_value)
      );
    }
    return detailsArr;
  };

  const breadcrumbs = useProfileBreadcrumbs([
    PROJECT_LIST_BREADCRUMB[project?.project_type ?? 'Project'],
    {
      title: project?.prime_organization ?? '',
    },
  ]);

  if (!projectId || (isProjectFetched && !project)) {
    return <Navigate to=".." />;
  }

  return (
    <>
      <Drawer.Header
        breadcrumbs={breadcrumbs}
        onClose={handleCloseDrawer}
        slotActions={
          isSelf && !disableEditing ? (
            <div className="flex gap-10">
              {project ? (
                <>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setShowConfirmDeleteAllRoles(true);
                    }}
                    startIcon={Trash2}
                  >
                    Leave Project
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      dispatch(editRoles(project));
                    }}
                    startIcon={Edit2}
                  >
                    Edit Roles
                  </Button>
                </>
              ) : null}
              {project?.can_edit ? (
                <Button
                  size="small"
                  onClick={() => {
                    dispatch(editProject(project));
                  }}
                  startIcon={Edit2}
                >
                  Edit Project
                </Button>
              ) : null}
            </div>
          ) : null
        }
      />
      <Drawer.Main paddingClassName="p-0">
        <main className="h-full overflow-auto lg:flex lg:overflow-visible">
          <div className="grow overflow-auto p-20">
            <Overview
              isLoading={isProjectLoading}
              title={project?.full_name || ''}
              organization={project?.prime_organization || project?.issuing_organization}
              detailsMain={createDetailsMain()}
              detailsSecondary={createDetailsSecondary()}
              description={project?.description || ''}
            />
            <ProjectRolesTable
              isLoading={isRolesLoading}
              project={project}
              projectMemberResults={roles?.results ?? []}
            />
            <WorkedWith projectId={projectId} />
          </div>
          <aside
            className={clsx(
              'mx-20 mb-20 shrink-0 rounded-md border border-light-primary bg-white shadow-5',
              'lg:m-0 lg:rounded-0 lg:border-0 lg:h-full lg:w-[460px] lg:border-l lg:border-grey-400 lg:shadow-none'
            )}
          >
            <KnowledgeColumn
              key={isKnowledgesFetched ? 1 : 0}
              knowledges={knowledges?.results ?? []}
              isLoading={isKnowledgesLoading}
            />
          </aside>
        </main>
      </Drawer.Main>
      <AreYouSureDialog
        open={showConfirmDeleteAllRoles}
        onCancel={() => setShowConfirmDeleteAllRoles(false)}
        onConfirm={async () => {
          setShowConfirmDeleteAllRoles(false);
          dispatch(markAccrualStale());
          await dispatch(
            batchUpdateExperiences({
              profileId,
              payload: {
                projectmembers: addDelete(roles?.results, profileId),
              },
            })
          );
          queryClient.invalidateQueries(['colleagues', { profileId }]);
          dispatch(
            accrualUpdate({
              profileId,
              onAccrualFinish: () => {
                queryClient.invalidateQueries(['jobs']);
              },
            })
          );
          navigate('..');
        }}
        title="Remove this project and roles entirely?"
        subtitle="This will delete all your roles and immediately remove this project from your profile."
        confirmButtonProps={{ color: 'error' }}
        confirmButtonLabel="Remove project from profile"
      />
    </>
  );
};

export { testId as ProjectDetailsDrawerTestId };
export default ProjectDetailsDrawer;
