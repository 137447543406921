import queryString from 'query-string';

import { ActionFetchExpertRequestQuery, CandidateFieldsFragment } from '@/__generated__/graphql';

export type Candidate = CandidateFieldsFragment;
export type ExpertRequest = ActionFetchExpertRequestQuery['expertRequest'];

export function buildRefUrl(expertRequest: any, source: string, userId?: string): string {
  const params = {
    utm_source: source,
    utm_medium: 'referral',
    utm_campaign: `expert_request${expertRequest.id}`,
  } as Record<string, string>;
  if (userId) {
    params.ref = userId;
  }

  return `${expertRequest.public_html_url}?${queryString.stringify(params)}`;
}
