import { BaseChannel, ConnectionState, MetaData } from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelCollection,
  GroupChannelEventContext,
  GroupChannelFilter,
  GroupChannelListOrder,
} from '@sendbird/chat/groupChannel';
import json2mq from 'json2mq';
import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMediaQuery } from 'usehooks-ts';

import { useAuth } from '@/auth';
import Body from '@/components/Body/Body';
import CircularProgress from '@/components/CircularProgress';
import EmptyMessage from '@/components/EmptyMessage';
import Layout from '@/components/Layout/Layout';
import { useApp } from '@/hooks/useAppContext';
import { channelUrlToId } from '@/messaging/sendbird';
import { darkBlue } from '@/theme/colors';
import { SCREEN_SM } from '@/theme/screens';

import ChannelList from './ChannelList';
import ChannelListItem from './ChannelListItem';
import Chat from './Chat';
import s from './Messenger.module.scss';

async function fetchMetaData(channel: GroupChannel): Promise<MetaData> {
  const cachedMeta = channel.cachedMetaData as MetaData;
  if (Object.keys(cachedMeta).length === 0) return cachedMeta;

  return await channel.getAllMetaData();
}

interface MessengerProps {
  channelUrl?: string;
  text?: string;
}

interface MessengerState {
  loading: boolean;
  connectionState: ConnectionState;
  selectedUrl?: string;
  channelCollection: GroupChannelCollection | null;
  channels: GroupChannel[];
  metadata: Record<string, MetaData>;
}

function mergeChannels(channels: GroupChannel[], newChannels: GroupChannel[]) {
  const urls = new Set<string>();
  return channels.concat(newChannels).filter((c) => {
    if (urls.has(c.url)) return false;
    urls.add(c.url);
    return true;
  });
}

const Messenger: FC<MessengerProps> = ({ channelUrl, text }) => {
  const navigate = useNavigate();
  const { sendbird } = useApp();
  const { userContext } = useAuth();

  const isMobileVersion = useMediaQuery(
    json2mq({
      maxWidth: SCREEN_SM,
    })
  );
  const [state, setState] = useState<MessengerState>({
    connectionState: ConnectionState.CONNECTING,
    loading: true,
    channelCollection: null,
    selectedUrl: channelUrl,
    channels: [],
    metadata: {},
  });
  const [showChannels, setShowChannels] = useState(false);

  const { connectionState, loading, selectedUrl, channels, metadata } = state;

  const handleChannelSelect = useCallback(
    (channel: GroupChannel) => {
      setShowChannels(false);
      //setState((state) => ({ ...state, selectedChannel: channel }));
      // KT: this will come back around as channelUrl prop
      navigate(`/messaging/${channelUrlToId(channel.url)}`);
    },
    [navigate]
  );

  const handleChannelsAdded = useCallback(
    (_context: GroupChannelEventContext, channels: BaseChannel[]) => {
      console.log('channels added', channels);
    },
    []
  );

  useEffect(() => {
    let col: GroupChannelCollection | null = null;
    (async function setup() {
      const sb = await sendbird.getSession();
      if (!sb) {
        setState((state) => ({
          ...state,
          connectionState: ConnectionState.CLOSED,
        }));
        return;
      }

      const filter = new GroupChannelFilter({ includeEmpty: false });
      col = sb.groupChannel.createGroupChannelCollection({
        filter: filter,
        order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      });
      col.setGroupChannelCollectionHandler({
        onChannelsAdded: handleChannelsAdded,
      });
      let channels: GroupChannel[] = [];
      if (col.hasMore) {
        channels = await col.loadMore();
      }

      const metadata: Record<string, MetaData> = {};
      for (const c of channels) {
        metadata[c.url] = await fetchMetaData(c);
      }

      setState((state) => ({
        ...state,
        connectionState: sb.connectionState,
        loading: false,
        channels: mergeChannels(state.channels, channels),
        metadata: { ...state.metadata, ...metadata },
        channelCollection: col,
      }));
    })();

    return () => {
      if (col) col.dispose();
    };
  }, [handleChannelsAdded, sendbird]);

  useEffect(() => {
    (async function fetchChannel() {
      if (!channelUrl) return;
      const sb = await sendbird.getSession();
      if (!sb) {
        setState((state) => ({
          ...state,
          connectionState: ConnectionState.CLOSED,
        }));
        return;
      }

      const channel = await sb.groupChannel.getChannel(channelUrl);
      const metadata = await fetchMetaData(channel);
      setState((state) => {
        const exists = state.channels.find((c) => c.url === channel.url);
        if (exists) return state;

        return {
          ...state,
          channels: [channel, ...state.channels],
          metadata: { ...state.metadata, [channel.url]: metadata },
        };
      });
    })();
    setState((state) => ({ ...state, selectedUrl: channelUrl }));
    // KT: should be batched
    setShowChannels(!channelUrl);
  }, [channelUrl, sendbird]);

  const isEmpty = !loading && channels.length === 0;
  const selectedChannel = selectedUrl ? channels.find((c) => c.url === selectedUrl) : null;
  const realShowChannels = showChannels || !isMobileVersion;
  const showChat = selectedChannel && (!showChannels || !isMobileVersion);

  const emptyBody =
    userContext === 'expert'
      ? 'Communicate with clients to clarify your experience or answer scoping questions prior to consultation.'
      : 'Communicate with any Expert to clarify their experience or ask scoping questions prior to requesting a consultation.';

  return (
    <Layout
      showNav
      showFooter={false}
      verticalCenter={
        isEmpty || [ConnectionState.CLOSED, ConnectionState.CONNECTING].includes(connectionState)
      }
      contentClassName={s.layoutContent}
      selected="messaging"
    >
      {(() => {
        if (connectionState === ConnectionState.CONNECTING) {
          return (
            <div style={{ marginBottom: 100 }} className={s.loading}>
              <CircularProgress />
            </div>
          );
        } else if (connectionState === ConnectionState.CLOSED) {
          return (
            <EmptyMessage
              style={{
                padding: 10,
                margin: '0 auto 100px',
                maxWidth: 600,
              }}
              border={false}
              iconName="cloud_off"
              iconColor={darkBlue}
              title="Sorry, messaging is temporarily unavailable."
            />
          );
        }

        if (isEmpty) {
          return (
            <EmptyMessage
              style={{
                padding: 10,
                margin: '0 auto 100px',
                maxWidth: 600,
              }}
              border={false}
              iconName="chat"
              iconColor={darkBlue}
              title="No messages here."
              body={emptyBody}
            />
          );
        }

        return (
          <Body className={s.layoutBody}>
            {realShowChannels && (
              <ChannelList>
                <Suspense>
                  {channels.map((c) => (
                    <ChannelListItem
                      key={c.url}
                      channel={c}
                      metadata={metadata[c.url]}
                      selected={!isMobileVersion && c.url === selectedChannel?.url}
                      onSelect={handleChannelSelect}
                    />
                  ))}
                  {loading && (
                    <div className={s.loading}>
                      <CircularProgress />
                    </div>
                  )}
                </Suspense>
              </ChannelList>
            )}
            {showChat && (
              <Chat
                key={selectedChannel.url}
                channel={selectedChannel}
                metadata={metadata[selectedChannel.url]}
                isMobileVersion={isMobileVersion}
                text={text}
                onBackToChannels={() => setShowChannels(true)}
              />
            )}
          </Body>
        );
      })()}
    </Layout>
  );
};
Messenger.displayName = 'Messenger';

export default Messenger;
