import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import moment from 'moment-timezone';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { notify } from '@/actions/ui';
import Button from '@/components/Button/Button';
import Dialog from '@/components/Dialog/Dialog';
import Divider from '@/components/Divider';
import EditDialog from '@/components/EditDialog';
import EditIcon from '@/components/EditIcon';
import EditableList from '@/components/EditableList';
import { Checkbox as FormAdapterCheckbox, TextField } from '@/components/FormAdapters';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import Image from '@/components/Image';
import KeywordInput from '@/components/KeywordInput';
import MonthPicker from '@/components/MonthPicker';
import PhoneInput from '@/components/PhoneInput';
import SelectLocation from '@/components/SelectLocation';
import SelectSector from '@/components/SelectSector';
import SelectTimezone from '@/components/SelectTimezone';
import YearPicker from '@/components/YearPicker';
import { presignAttachmentURL } from '@/core/attachment';
import { formatExperiencePeriod } from '@/core/profile';
import { useApp } from '@/hooks/useAppContext';
import { mergeProfiles } from '@/profile/store';
import { RootState } from '@/store';
import { black, darkGray, darkGreen, red500 } from '@/theme/colors';
import { parseId, rewriteUrl } from '@/utils';

import s from './ProfileMerge.module.scss';
import {
  compareEducations,
  compareExperiences,
  equalEducation,
  equalExperience,
  experienceDateToString,
  experienceStringToDate,
  formatDate,
  listEquals,
} from './helper';

const EXISTING = 'existing';
const CONFLICTING = 'conflicting';

interface Editing {
  side: string;
  section: string;
  index: number;
}

interface ProfileMergeProps {
  existing: Readonly<Record<string, any>>;
  conflicting: Readonly<Record<string, any>>;
}

const connector = connect(
  (state: RootState) => ({
    groups: ((state.groups.networks || {}).edges || []).map((g: any) => g.node),
    expertRequests: (state.expertRequests.all.edges || []).map((e: any) => e.node),
  }),
  {
    mergeProfiles,
    notify,
  }
);

const ProfileMerge = ({
  existing,
  conflicting,
  groups,
  expertRequests,
  mergeProfiles,
  notify,
}: ProfileMergeProps & ConnectedProps<typeof connector>) => {
  const navigate = useNavigate();
  const [conflicts, setConflicts] = useState<Record<string, any>>({});
  const [editing, setEditing] = useState<Editing | null>(null);
  const [saveConfirmationOpen, setSaveConfirmationOpen] = useState(false);

  const addFieldsConflict = useCallback(
    (section: any, fields: any, opts: any) => {
      if (
        !opts.conflictsWithEmpty &&
        fields.every((f: any) => conflicting[f] === '' || conflicting[f] === null)
      )
        return;

      if (fields.every((f: any) => existing[f] === conflicting[f])) return;

      const existingValues = {};
      const conflictingValues = {};
      fields.forEach((f: any) => {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        existingValues[f] = existing[f];
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        conflictingValues[f] = conflicting[f];
      });

      setConflicts((conflicts) => ({
        ...conflicts,
        [section]: {
          merge: 'exclusive',
          editDialog: true,
          ...opts,
          existing: {
            selected: false,
            value: existingValues,
          },
          conflicting: {
            selected: true,
            value: conflictingValues,
          },
        },
      }));
    },
    [existing, conflicting]
  );

  const addSimpleFieldConflict = useCallback(
    (field: any, opts: any) => addFieldsConflict(field, [field], opts),
    [addFieldsConflict]
  );

  const addListFieldConflict = useCallback(
    (field: any, opts: any) => {
      if (!opts.conflictsWithEmpty && (!conflicting[field] || conflicting[field].length === 0))
        return;
      const valueMapper = opts.valueMapper || ((x: any) => x);

      const existingField = valueMapper(existing[field]);
      const conflictingField = valueMapper(conflicting[field]);

      if (listEquals(existingField, conflictingField)) return;

      setConflicts((conflicts) => ({
        ...conflicts,
        [field]: {
          merge: 'inclusive',
          editDialog: true,
          ...opts,
          existing: {
            selected: true,
            value: { [field]: existingField },
          },
          conflicting: {
            selected: true,
            value: { [field]: conflictingField },
          },
        },
      }));
    },
    [existing, conflicting]
  );

  const addExperiencesConflict = useCallback(() => {
    const existingExperiences = existing.experiences || [];
    const conflictingExperiences = conflicting.experiences || [];

    const existingExperiences2: any = [];
    const newExperiences: any = [];

    // Put all experiences in a single array so they can be sorted
    const allExperiences = [...existingExperiences, ...conflictingExperiences].sort(
      compareExperiences
    );

    let i = 0;
    let showExperiences = false;

    allExperiences.forEach((e) => {
      const isExisting = existingExperiences.includes(e);
      const isNew = conflictingExperiences.includes(e);

      // Experience has already been added as a conflict of another experience,
      // let's skip.
      // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
      if (isExisting && existingExperiences2.some((x) => x && x.value === e)) return;
      // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
      if (isNew && newExperiences.some((x) => x && x.value === e)) return;

      const conflictFn = (cExp: any) =>
        e.organization === cExp.organization &&
        e.title === cExp.title &&
        formatDate(e.start_date) === formatDate(cExp.start_date);

      if (isNew) {
        // Conflicting side is selected by default
        newExperiences[i] = { value: e, selected: true };
        // New experience conflicts with existing experience
        const conflict = existingExperiences.find(conflictFn);
        if (conflict) {
          existingExperiences2[i] = { value: conflict, selected: false };
          // If experience is exactly the same, no need to show the Experiences section
          if (!equalExperience(e, conflict)) showExperiences = true;
        } else {
          // No conflict, the other side is empty
          existingExperiences2[i] = undefined;
          // Existing and New are not the same, let's show the Experience section
          showExperiences = true;
        }
      } else {
        // Existing side is selected by default unless there is a conflict
        existingExperiences2[i] = { value: e, selected: true };
        // Existing experience conflicts with new experience
        const conflict = conflictingExperiences.find(conflictFn);
        if (conflict) {
          newExperiences[i] = { value: conflict, selected: true };
          existingExperiences2[i].selected = false;
          if (!equalExperience(e, conflict)) showExperiences = true;
        } else {
          newExperiences[i] = undefined;
          showExperiences = true;
        }
      }
      i++;
    });

    if (showExperiences) {
      setConflicts((conflicts) => ({
        ...conflicts,
        experiences: {
          title: 'Experiences',
          existing: existingExperiences2,
          conflicting: newExperiences,
          merge: 'exclusive',
          editDialog: true,
          viewComponent: Experience,
          editComponent: EditExperience,
        },
      }));
    }
  }, [existing, conflicting]);

  const addEducationConflict = useCallback(() => {
    const existingEducation = existing.education || [];
    const conflictingEducation = conflicting.education || [];

    const existingEducation2: any = [];
    const newEducation: any = [];

    // Put all education in a single array so they can be sorted
    const allEducation = [...existingEducation, ...conflictingEducation].sort(compareEducations);

    let i = 0;
    let showEducation = false;

    allEducation.forEach((e) => {
      const isExisting = existingEducation.includes(e);
      const isNew = conflictingEducation.includes(e);

      // Education has already been added as a conflict of another education,
      // let's skip.
      // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
      if (isExisting && existingEducation2.some((x) => x && x.value === e)) return;
      // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
      if (isNew && newEducation.some((x) => x && x.value === e)) return;

      const conflictFn = (cExp: any) =>
        e.school === cExp.school &&
        e.degree === cExp.degree &&
        formatDate(e.start_date) === formatDate(cExp.start_date);

      if (isNew) {
        // Conflicting side is selected by default
        newEducation[i] = { value: e, selected: true };
        // New education conflicts with existing education
        const conflict = existingEducation.find(conflictFn);
        if (conflict) {
          existingEducation2[i] = { value: conflict, selected: false };
          // If education is exactly the same, no need to show the Education section
          if (!equalEducation(e, conflict)) showEducation = true;
        } else {
          // No conflict, the other side is empty
          existingEducation2[i] = undefined;
          // Existing and New are not the same, let's show the Education section
          showEducation = true;
        }
      } else {
        // Existing side is selected by default unless there is a conflict
        existingEducation2[i] = { value: e, selected: true };
        // Existing education conflicts with new education
        const conflict = conflictingEducation.find(conflictFn);
        if (conflict) {
          newEducation[i] = { value: conflict, selected: true };
          existingEducation2[i].selected = false;
          if (!equalEducation(e, conflict)) showEducation = true;
        } else {
          newEducation[i] = undefined;
          showEducation = true;
        }
      }
      i++;
    });

    if (showEducation) {
      setConflicts((conflicts) => ({
        ...conflicts,
        education: {
          title: 'Education',
          existing: existingEducation2,
          conflicting: newEducation,
          merge: 'exclusive',
          editDialog: true,
          viewComponent: Education,
          editComponent: EditEducation,
        },
      }));
    }
  }, [existing, conflicting]);

  const extractExpertRequestCandidates = useCallback(
    (profile: any) => {
      const requests = profile.expert_request_candidates || [];

      if (profile.expert_request_id) {
        requests.push({
          request_id: profile.expert_request_id,
        });
      }

      return requests
        .map((req: any) => ({
          ...req,
          expert_request: expertRequests.find((er: any) => er.id === req.request_id),
        }))
        .filter((req: any) => !!req.expert_request);
    },
    [expertRequests]
  );

  const addExpertRequestsConflict = useCallback(() => {
    const existingRequests = extractExpertRequestCandidates(existing);
    const conflictingRequests = extractExpertRequestCandidates(conflicting);

    const existingRequests2: any = [];
    const newRequests: any = [];

    // Put all networks in a single array so they can be sorted
    const allRequests = [...existingRequests, ...conflictingRequests];

    let i = 0;
    let showRequests = false;

    allRequests.forEach((request) => {
      const conflictFn = (cReq: any) => request.request_id === cReq.request_id;
      const existFn = (x: any) => x && x.value && request.request_id === x.value.request_id;

      const isExisting = existingRequests.some(conflictFn);
      const isNew = conflictingRequests.some(conflictFn);

      // Network has already been added as a conflict of another request,
      // let's skip.
      if (isExisting && existingRequests2.some(existFn)) return;
      if (isNew && newRequests.some(existFn)) return;

      if (isNew) {
        // Conflicting side is selected by default
        const conflict = conflictingRequests.find(conflictFn);
        newRequests[i] = { value: conflict, selected: true };
        // New request conflicts with existing request
        const current = existingRequests.find(conflictFn);
        if (current && current.id) {
          existingRequests2[i] = { value: current, selected: false };
        } else {
          // No conflict, the other side is empty
          existingRequests2[i] = undefined;
          // Existing and New are not the same, let's show the Networks section
          showRequests = true;
        }
      } else {
        // Existing side is selected by default unless there is a conflict
        existingRequests2[i] = { value: request, selected: true };
        // Existing request conflicts with new request
        const conflict = conflictingRequests.find(conflictFn);
        if (conflict) {
          newRequests[i] = { value: conflict, selected: true };
          existingRequests2[i].selected = false;
        } else {
          newRequests[i] = undefined;
          showRequests = true;
        }
      }
      i++;
    });

    if (showRequests) {
      setConflicts((conflicts) => ({
        ...conflicts,
        expertRequests: {
          title: 'Expert Requests',
          existing: existingRequests2,
          conflicting: newRequests,
          merge: 'exclusive',
          editDialog: false,
          viewComponent: ExpertRequest,
        },
      }));
    }
  }, [existing, extractExpertRequestCandidates, conflicting]);

  const extractInternalNetworks = useCallback(
    (profile: any) => {
      const networks = groups.map((g: any) => g.internal_network).filter(Boolean);
      const ines = profile.expert_internal_networks || [];
      let sourcesNetwork = [];

      if (profile.sources) {
        sourcesNetwork = profile.sources
          .filter((source: any) => source.network_id)
          .map((source: any) => ({
            network: networks.find((g: any) => g.id === source.network_id),
          }))
          .filter((ine: any) => !!ine.network);
      }

      return [...ines, ...sourcesNetwork];
    },
    [groups]
  );

  const addNetworksConflict = useCallback(() => {
    const existingInes = extractInternalNetworks(existing);
    const conflictingInes = extractInternalNetworks(conflicting);

    const existingNetworks: any = [];
    const newNetworks: any = [];

    // Put all networks in a single array so they can be sorted
    const allNetworks = [...existingInes, ...conflictingInes];

    let i = 0;
    let showNetworks = false;

    allNetworks.forEach((ine) => {
      const { network } = ine;

      const conflictFn = (cIne: any) => network.id === cIne.network.id;
      const existFn = (x: any) =>
        x && x.value && x.value.network && network.id === x.value.network.id;

      const isExisting = existingInes.some(conflictFn);
      const isNew = conflictingInes.some(conflictFn);

      // Network has already been added as a conflict of another network,
      // let's skip.
      if (isExisting && existingNetworks.some(existFn)) return;
      if (isNew && newNetworks.some(existFn)) return;

      if (isNew) {
        // Conflicting side is selected by default
        const conflict = conflictingInes.find(conflictFn);
        newNetworks[i] = { value: conflict, selected: true };
        // New network conflicts with existing network
        const current = existingInes.find(conflictFn);
        if (current && current.id) {
          existingNetworks[i] = { value: current, selected: false };
        } else {
          // No conflict, the other side is empty
          existingNetworks[i] = undefined;
          // Existing and New are not the same, let's show the Networks section
          showNetworks = true;
        }
      } else {
        // Existing side is selected by default unless there is a conflict
        existingNetworks[i] = { value: ine, selected: true };
        // Existing network conflicts with new network
        const conflict = conflictingInes.find(conflictFn);
        if (conflict) {
          newNetworks[i] = { value: conflict, selected: true };
          existingNetworks[i].selected = false;
        } else {
          newNetworks[i] = undefined;
          showNetworks = true;
        }
      }
      i++;
    });

    if (showNetworks) {
      setConflicts((conflicts) => ({
        ...conflicts,
        internalNetworks: {
          title: 'Networks',
          existing: existingNetworks,
          conflicting: newNetworks,
          merge: 'exclusive',
          editDialog: false,
          viewComponent: Network,
        },
      }));
    }
  }, [existing, extractInternalNetworks, conflicting]);

  useEffect(() => {
    addSimpleFieldConflict('raw_picture_url', {
      title: 'Profile Photo',
      editDialog: false,
      viewComponent: ProfilePicture,
    });

    addSimpleFieldConflict('available_marketplace', {
      title: 'Available Marketplace',
      editDialog: false,
      viewComponent: AvailableMarketplace,
    });

    addSimpleFieldConflict('first_name', {
      title: 'First Name',
    });

    addSimpleFieldConflict('last_name', {
      title: 'Last Name',
    });

    addFieldsConflict('headline', ['title', 'summary'], {
      title: 'Headline & Bio',
      viewComponent: HeadlineAndBio,
      editComponent: EditHeadlineAndBio,
    });

    addFieldsConflict('location', ['country', 'city'], {
      title: 'Location',
      viewComponent: CountryAndCity,
      editComponent: EditCountryAndCity,
    });

    addSimpleFieldConflict('internal_cv_url', {
      title: 'Attached CV',
      editDialog: false,
      link: true,
    });

    addFieldsConflict('linkedin', ['linkedin_url', 'linkedin_username'], {
      title: 'LinkedIn',
      viewComponent: LinkedIn,
      editComponent: EditLinkedIn,
    });

    addSimpleFieldConflict('skype', {
      title: 'Skype',
    });

    addSimpleFieldConflict('timezone', {
      title: 'Time Zone',
      editComponent: EditTimezone,
    });

    if (!existing.user) {
      addListFieldConflict('emails', {
        title: 'Email Addresses',
        viewComponent: List,
        editComponent: EditEmails,
        valueMapper: (emails: any) => emails && emails.map((e: any) => e.display),
      });

      addListFieldConflict('phones', {
        title: 'Phones',
        viewComponent: List,
        editComponent: EditPhones,
        valueMapper: (phones: any) => phones && phones.map((p: any) => p.display),
      });
    }

    addListFieldConflict('sector_ids', {
      title: 'Sectors',
      viewComponent: ListSectors,
      editComponent: EditSectors,
    });

    addListFieldConflict('region_ids', {
      title: 'Regions',
      viewComponent: ListRegions,
      editComponent: EditRegions,
    });

    addListFieldConflict('keywords', {
      title: 'Keywords',
      viewComponent: List,
      editComponent: EditKeywords,
    });

    addNetworksConflict();

    addExpertRequestsConflict();

    addExperiencesConflict();

    addEducationConflict();
  }, [
    addEducationConflict,
    addExperiencesConflict,
    addExpertRequestsConflict,
    addFieldsConflict,
    addListFieldConflict,
    addNetworksConflict,
    addSimpleFieldConflict,
    existing.user,
  ]);

  const handleSelect = (side: any, section: any, merge: any, index: any) => {
    const tempConflicts = { ...conflicts };
    const otherSide = side === EXISTING ? CONFLICTING : EXISTING;
    const value = index > -1 ? tempConflicts[section][side][index] : tempConflicts[section][side];
    const otherSideValue =
      index > -1 ? tempConflicts[section][otherSide][index] : tempConflicts[section][otherSide];

    value.selected = !value.selected;
    if (merge === 'exclusive' && otherSideValue) {
      otherSideValue.selected = !value.selected;
    }
    setConflicts(tempConflicts);
  };

  const handleEditDialog = (side: any, section: any, index: any) => {
    setEditing({ side, section, index });
  };

  const handleSelectAll = (side: any) => {
    const tempConflicts = { ...conflicts };
    const otherSide = side === EXISTING ? CONFLICTING : EXISTING;

    Object.keys(tempConflicts).forEach((section) => {
      const c = tempConflicts[section];

      if (Array.isArray(c[side])) {
        c[side].forEach((_v: any, i: any) => {
          if (c[side][i]) c[side][i].selected = true;
          if (c[otherSide][i]) c[otherSide][i].selected = false;
        });
        return;
      }

      c[side].selected = true;
      c[otherSide].selected = false;
    });

    setConflicts(tempConflicts);
  };

  const allSelected = (existing: any) => {
    const side = existing ? 'existing' : 'conflicting';

    return Object.keys(conflicts).every((section) => {
      const c = conflicts[section];

      if (Array.isArray(c[side])) {
        return c[side].every((_v: any, i: any) => !c[side][i] || c[side][i].selected);
      }

      return c[side].selected;
    });
  };

  const handleSave = async () => {
    const merged = {
      destination_id: existing.id,
      source_id: conflicting.id,
    };

    Object.keys(conflicts).forEach((section) => {
      const c = conflicts[section];

      if (Array.isArray(c.existing)) {
        const mergedArray = c.existing
          .map((_e: any, i: any) => {
            const existing = c.existing[i];
            const conflicting = c.conflicting[i];
            if (existing && existing.selected) return existing.value;
            if (conflicting && conflicting.selected) return conflicting.value;
          })
          .filter(Boolean);
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        merged[section] = mergedArray;
        return;
      }

      Object.keys(c.existing.value).forEach((attr) => {
        const existingValue = c.existing.value[attr];
        const conflictingValue = c.conflicting.value[attr];

        if (Array.isArray(existingValue || conflictingValue)) {
          const mergedArray = {};
          if (c.existing.selected)
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            existingValue.forEach((v: any) => (mergedArray[v] = true));
          if (c.conflicting.selected)
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            conflictingValue.forEach((v: any) => (mergedArray[v] = true));
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          merged[attr] = Object.keys(mergedArray);
        } else {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (c.existing.selected) merged[attr] = existingValue;
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (c.conflicting.selected) merged[attr] = conflictingValue;
        }
      });
    });

    closeSaveConfirmation();

    // @ts-expect-error TS(2339) FIXME: Property 'internal_cv_url' does not exist on type ... Remove this comment to see the full error message
    if (merged.internal_cv_url) {
      // @ts-expect-error TS(2339) FIXME: Property 'cv_url' does not exist on type '{ destin... Remove this comment to see the full error message
      merged.cv_url = merged.internal_cv_url;
      // @ts-expect-error TS(2339) FIXME: Property 'internal_cv_url' does not exist on type ... Remove this comment to see the full error message
      delete merged.internal_cv_url;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'internalNetworks' does not exist on type... Remove this comment to see the full error message
    if (merged.internalNetworks) {
      // Internal Network Expert attached to Profile
      // @ts-expect-error TS(2339) FIXME: Property 'internal_networks_ids' does not exist on... Remove this comment to see the full error message
      merged.internal_networks_ids = merged.internalNetworks
        .filter((ine: any) => !!ine.id)
        .map((ine: any) => ine.id);

      // Network from Profile Sources
      // @ts-expect-error TS(2339) FIXME: Property 'networks_ids' does not exist on type '{ ... Remove this comment to see the full error message
      merged.networks_ids = merged.internalNetworks
        .filter((ine: any) => !ine.id)
        .map((ine: any) => ine.network.id);

      // @ts-expect-error TS(2339) FIXME: Property 'internalNetworks' does not exist on type... Remove this comment to see the full error message
      delete merged.internalNetworks;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'expertRequests' does not exist on type '... Remove this comment to see the full error message
    if (merged.expertRequests) {
      // Expert Request Candidates attached to Profile
      // @ts-expect-error TS(2339) FIXME: Property 'expert_request_candidate_ids' does not e... Remove this comment to see the full error message
      merged.expert_request_candidate_ids = merged.expertRequests
        .filter((er: any) => !!er.id)
        .map((er: any) => er.id);

      // Expert Request carried from import
      // @ts-expect-error TS(2339) FIXME: Property 'expertRequests' does not exist on type '... Remove this comment to see the full error message
      const er = merged.expertRequests.find((er: any) => !er.id);
      // @ts-expect-error TS(2339) FIXME: Property 'expert_request_id' does not exist on typ... Remove this comment to see the full error message
      merged.expert_request_id = er ? er.request_id : undefined;

      // @ts-expect-error TS(2339) FIXME: Property 'expertRequests' does not exist on type '... Remove this comment to see the full error message
      delete merged.expertRequests;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'picture_url' does not exist on type '{ d... Remove this comment to see the full error message
    merged.picture_url = merged.raw_picture_url;
    // @ts-expect-error TS(2339) FIXME: Property 'raw_picture_url' does not exist on type ... Remove this comment to see the full error message
    delete merged.raw_picture_url;

    try {
      const profile = await mergeProfiles(merged);
      if (profile.html_url) navigate(rewriteUrl(profile.html_url));
    } catch (err) {
      notify(
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        `An error occurred when merging profiles. (error ${err.message})`,
        'error'
      );
      throw err;
    }
  };

  const closeEditForm = () => setEditing(null);

  const handleEdit = (values: any) => {
    if (!editing) {
      return;
    }

    const { section, side, index } = editing;

    if (Array.isArray(conflicts[section][side])) {
      conflicts[section][side][index].value = values;
    } else {
      conflicts[section][side].value = values;
    }
    setConflicts(conflicts);
  };

  const openSaveConfirmation = () => setSaveConfirmationOpen(true);

  const closeSaveConfirmation = () => setSaveConfirmationOpen(false);

  const existingSource = existing.sources[existing.sources.length - 1];
  const conflictingSource = conflicting.sources[conflicting.sources.length - 1];

  return (
    <div>
      <ProfileEditForm
        editing={editing}
        conflicts={conflicts}
        onClose={closeEditForm}
        onSubmit={handleEdit}
      />

      <Dialog
        open={saveConfirmationOpen}
        title="Merge Profiles"
        onCancel={closeSaveConfirmation}
        onConfirm={handleSave}
        confirmLabel="Merge"
        cancelLabel="Cancel"
      >
        All selected fields will be merged with the existing profile. The conflicting profile will
        be deleted.
      </Dialog>

      <div className={s.header}>
        <div className={s.headerText}>Merging Profiles</div>
        <div>
          <Button label="Save" onClick={openSaveConfirmation} size="medium" />
        </div>
      </div>

      <div className={s.helpText}>
        Please decide which version of this profile you’d like to use.
      </div>

      <Divider />

      <div className={s.sources}>
        <Source
          side={EXISTING}
          checked={allSelected(true)}
          title="Original"
          href={existing.html_url}
          source={existingSource}
          onClick={handleSelectAll}
        />
        <Source
          side={CONFLICTING}
          checked={allSelected(false)}
          title="Update"
          href={conflicting.html_url}
          source={conflictingSource}
          onClick={handleSelectAll}
        />
      </div>

      <Divider />

      {Object.keys(conflicts).map((section) => {
        const c = conflicts[section];
        return (
          <ConflictSection key={section} title={c.title}>
            {Array.isArray(c.existing) ? (
              c.existing.map((_e: any, i: any) => (
                // assuming existing and conflicting arrays are the same size
                <Conflict
                  key={`${section}${JSON.stringify(
                    c.existing[i]
                  )}${JSON.stringify(c.conflicting[i])}`}
                  section={section}
                  index={i}
                  existing={c.existing[i]}
                  conflicting={c.conflicting[i]}
                  component={c.viewComponent}
                  merge={c.merge}
                  onSelect={handleSelect}
                  onEditDialog={c.editDialog ? handleEditDialog : undefined}
                  onEdit={handleEdit}
                  link={c.link}
                />
              ))
            ) : (
              <Conflict
                section={section}
                existing={c.existing}
                conflicting={c.conflicting}
                component={c.viewComponent}
                merge={c.merge}
                onSelect={handleSelect}
                onEditDialog={c.editDialog ? handleEditDialog : undefined}
                onEdit={handleEdit}
                link={c.link}
              />
            )}
          </ConflictSection>
        );
      })}
    </div>
  );
};

function ConflictSection({ title, children }: any) {
  return (
    <div className={s.conflicts}>
      <div className={s.conflictsTitle}>{title}</div>
      <div>{children}</div>
    </div>
  );
}

function Conflict({
  section,
  existing,
  conflicting,
  index,
  component,
  merge,
  onSelect,
  onEditDialog,
  onEdit,
  link,
}: any) {
  return (
    <div className={s.conflict}>
      <ConflictSide
        side={EXISTING}
        section={section}
        conflict={existing}
        index={index}
        component={component}
        merge={merge}
        onSelect={onSelect}
        onEditDialog={onEditDialog}
        onEdit={onEdit}
        link={link}
      />
      <ConflictSide
        side={CONFLICTING}
        section={section}
        conflict={conflicting}
        index={index}
        component={component}
        merge={merge}
        onSelect={onSelect}
        onEditDialog={onEditDialog}
        onEdit={onEdit}
        link={link}
      />
    </div>
  );
}

const useConflictStyles = makeStyles(() => ({
  labelRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0 10px',
    marginRight: 0,
  },
  // @ts-expect-error TS(2339) FIXME: Property 'checked' does not exist on type '{}'.
  labelContent: ({ checked }) => ({
    paddingLeft: 15,
    color: checked ? black : darkGray,
    fontWeight: checked ? 500 : 400,
    wordBreak: 'break-word',
  }),
  checkboxRoot: {
    padding: 0,
  },
}));

function Checkbox({ checked, onChange, label }: any) {
  const classes = useConflictStyles({ checked });

  return (
    <FormControlLabel
      control={
        <MaterialCheckbox
          checked={checked}
          onChange={onChange}
          classes={{ root: classes.checkboxRoot }}
        />
      }
      label={label}
      classes={{ root: classes.labelRoot, label: classes.labelContent }}
    />
  );
}

function ConflictSide({
  side,
  section,
  index,
  conflict,
  component,
  merge,
  onSelect,
  onEditDialog,
  onEdit,
  link,
}: any) {
  const isLeft = side === EXISTING;

  if (!conflict) {
    return <div className={cx(s.conflictSideEmpty, { [s.conflictSideLeft]: isLeft })} />;
  }

  const Component = component;

  const content = Component ? (
    <Component side={side} section={section} index={index} value={conflict.value} onEdit={onEdit} />
  ) : (
    conflict.value[section]
  );

  return (
    <div className={cx(s.conflictSide, { [s.conflictSideLeft]: isLeft })}>
      <div className={s.conflictSideSelect}>
        <Checkbox
          checked={conflict.selected}
          onChange={() => onSelect(side, section, merge, index)}
          label={content}
        />
      </div>
      {(onEditDialog || link) && (
        <div className={s.conflictSideAction}>
          {onEditDialog && <EditIcon onClick={() => onEditDialog(side, section, index)} />}
          {link && (
            <a
              href={conflict.value[section]}
              target="_blank"
              rel="noreferrer"
              style={{ display: 'inline-block', marginLeft: 5 }}
            >
              <MaterialIcon icon="open_in_new" color={darkGreen} size={22} />
            </a>
          )}
        </div>
      )}
    </div>
  );
}

function Source({ side, title, href, source, onClick, checked }: any) {
  const isLeft = side === EXISTING;

  const sourceId =
    source &&
    ((source.agent_id &&
      source.agent_profile_id &&
      `${source.agent_id}:${source.agent_profile_id}`) ||
      source.source_id ||
      source.agent_id);

  return (
    <div className={cx(s.source, { [s.sourceLeft]: isLeft })}>
      <div className={s.sourceTitle}>
        {title}
        {href && (
          <a className={s.profileLink} href={href} target="_blank" rel="noreferrer">
            <MaterialIcon icon="launch" />
          </a>
        )}
      </div>
      <p className={s.sourceSubTitle}>Pick all changes</p>
      <div>
        <Checkbox
          checked={checked}
          onChange={() => onClick(side)}
          label={`${sourceId || title} ${
            source ? moment(source.created_at).format('YYYY-MM-DD hh:mma') : ''
          }`}
        />
      </div>
    </div>
  );
}

function EditTimezone() {
  return <Field fullWidth component={SelectTimezone} name="timezone" label="Time Zone" />;
}

function ProfilePicture({ value, section, side, onEdit }: any) {
  const { graphql } = useApp();

  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async function () {
      const url = await presignAttachmentURL(graphql.client, value.raw_picture_url);
      setImageUrl(url);
    })();
  }, [graphql.client, value.raw_picture_url]);

  return (
    <Image
      // @ts-ignore
      editable
      src={imageUrl}
      dimensions={{ height: 220, width: 220 }}
      style={{ zIndex: 999, width: 220, height: 220, boxSizing: 'border-box' }}
      onChange={(file: any) => onEdit({ raw_picture_url: file.url }, section, side)}
    />
  );
}

function AvailableMarketplace({ value, section }: any) {
  return value[section] ? (
    <div>
      <FAIcon icon="user" size={16} /> In Marketplace
    </div>
  ) : (
    <div>
      <div>
        <FAIcon icon="user-slash" size={16} color={red500} /> Not in Marketplace
      </div>
    </div>
  );
}

function HeadlineAndBio({ value }: any) {
  return (
    <div>
      <div className={s.headline}>{value.title}</div>
      <div>{value.summary}</div>
    </div>
  );
}

function EditHeadlineAndBio() {
  return (
    <div>
      <Field component={TextField} fullWidth name="title" label="Headline" />
      <Field
        component={TextField}
        fullWidth
        name="summary"
        label="Bio"
        multiline
        minRows={3}
        maxRows={10}
      />
    </div>
  );
}

function LinkedIn({ value }: any) {
  return (
    <div>
      <div>{value.linkedin_username}</div>
      <div className={s.linkedInUrl}>
        {value.linkedin_url && (
          <a className={s.linkedInLink} href={value.linkedin_url} target="_blank" rel="noreferrer">
            {value.linkedin_url}
          </a>
        )}
      </div>
    </div>
  );
}

function EditLinkedIn() {
  return (
    <div>
      <Field component={TextField} fullWidth name="linkedin_username" label="Username" />
      <Field component={TextField} fullWidth name="linkedin_url" label="URL" />
    </div>
  );
}

function CountryAndCity({ value }: any) {
  return (
    <div>
      {value.country}
      {value.country ? ', ' : ''}, {value.city}
    </div>
  );
}

function EditCountryAndCity({ allCountries }: any) {
  return (
    <div>
      <Field
        component={SelectLocation}
        multiple={false}
        name="country"
        label="Country"
        countries={allCountries}
        format={(country: any) => allCountries.find((c: any) => c.name === country)}
        parse={(country: any) => (country && country.name) || ''}
      />
      <Field component={TextField} fullWidth name="city" label="City" />
    </div>
  );
}

function Experience({ value }: any) {
  return (
    <div className={s.experience}>
      <div>
        {value.organization} • {value.location}
      </div>
      <div>
        {value.title} • (
        <span className={s.experiencePeriod}>
          {formatExperiencePeriod(value.start_date, value.end_date, value.current, {
            duration: false,
          })}
        </span>
        )
      </div>
    </div>
  );
}

// @ts-expect-error TS(7031) FIXME: Binding element 'expertRequest' implicitly has an ... Remove this comment to see the full error message
function ExpertRequest({ value: { expert_request: expertRequest } }) {
  return (
    <div className={s.experience}>
      <div>{expertRequest.name}</div>
    </div>
  );
}

function Network({ value: { network } }: any) {
  return (
    <div className={s.experience}>
      <div>{network.name}</div>
    </div>
  );
}

function Education({ value }: any) {
  return (
    <div className={s.experience}>
      <div>
        {value.school} • {value.field_of_study}
      </div>
      <div>
        {value.degree} • (
        <span className={s.experiencePeriod}>
          {formatExperiencePeriod(value.start_date, value.end_date, false, {
            duration: false,
          })}
        </span>
        )
      </div>
    </div>
  );
}

function EditExperience({ change }: any) {
  return (
    <div>
      <Field component={TextField} fullWidth name="title" label="Title" />
      <Field component={TextField} fullWidth name="location" label="Location" />
      <Field component={TextField} fullWidth name="organization" label="Organization" />
      <Field component={TextField} fullWidth name="linkedin_url" label="LinkedIn" />
      <div className={s.editExperienceDates}>
        <Field
          style={{ flex: 1 }}
          component={MonthPickerInput}
          fullWidth
          name="start_date"
          label="Start Date"
          format={(value: any) => experienceStringToDate(value)}
          parse={(value: any) => experienceDateToString(value)}
        />
        <Field
          style={{ flex: 1, marginLeft: 20 }}
          component={MonthPickerInput}
          fullWidth
          name="end_date"
          label="End Date"
          format={(value: any) => experienceStringToDate(value)}
          parse={(value: any) => experienceDateToString(value)}
        />
        <div style={{ flex: 1 }}>
          <Field
            style={{ marginLeft: 20 }}
            component={FormAdapterCheckbox}
            name="current"
            type="checkbox"
            label="I still work here"
            onChange={(_e, value) => {
              change('end_date', null);
              change('current', value);
            }}
          />
        </div>
      </div>
      <Field
        component={TextField}
        fullWidth
        name="description"
        label="Description"
        multiline
        minRows={3}
        maxRows={10}
      />
    </div>
  );
}

function EditEducation() {
  return (
    <div>
      <Field component={TextField} fullWidth name="degree" label="Degree" />
      <Field component={TextField} fullWidth name="field_of_study" label="Field of Study" />
      <Field component={TextField} fullWidth name="school" label="School" />
      <div className={s.editExperienceDates}>
        <YearPicker
          current
          offset={0}
          name="start_date"
          label="Start year"
          style={{ flex: 1, marginRight: 20 }}
        />

        <YearPicker
          current={false}
          offset={8}
          name="end_date"
          label="End year (or expected)"
          style={{ flex: 1 }}
        />
      </div>
      <Field
        component={TextField}
        fullWidth
        name="description"
        label="Description"
        multiline
        minRows={3}
        maxRows={10}
      />
    </div>
  );
}

function MonthPickerInput({ input, ...other }: any) {
  return (
    <MonthPicker
      {...other}
      {...input}
      maxDate={new Date()}
      onChange={(date: any) => input.onChange(date)}
      placeholder="Example: 06/2010"
    />
  );
}

function List({ section, value }: any) {
  return value[section].map((v: any) => <div key={v}>{v}</div>);
}

function ListSectors({ section, value, sectors }: any) {
  return value[section].map((id: any) => {
    const sector = sectors.find((s: any) => s.id === id);
    return <div key={id}>{sector.name}</div>;
  });
}
// @ts-expect-error TS(2630) FIXME: Cannot assign to 'ListSectors' because it is a fun... Remove this comment to see the full error message
ListSectors = connect((state) => ({ sectors: state.sectors.all }))(ListSectors);

function ListRegions({ section, value, regions }: any) {
  return value[section].map((id: any) => {
    const region = regions.find((s: any) => s.id === id);
    return <div key={id}>{region.name}</div>;
  });
}
// @ts-expect-error TS(2630) FIXME: Cannot assign to 'ListRegions' because it is a fun... Remove this comment to see the full error message
ListRegions = connect((state) => ({ regions: state.countries }))(ListRegions);

function EditEmails() {
  return <FieldArray name="emails" addButtonLabel="Email Address" component={EditableList} />;
}

function EditPhones({ allCountries }: any) {
  return (
    <FieldArray
      name="phones"
      component={EditableList}
      addButtonLabel="Phone Number"
      inputComponent={PhoneField}
      inputProps={{ allCountries }}
    />
  );
}

function PhoneField({ name, allCountries }: any) {
  return (
    <Field
      component={PhoneInput}
      type="tel"
      name={name}
      className={s.phone}
      allCountries={allCountries}
      showExampleOnError
    />
  );
}

function EditKeywords({ array }: any) {
  return (
    <Field
      component={KeywordInput}
      maxLength={64}
      name="keywords"
      placeholder="Click to add keywords"
      label="Keywords"
      onRequestAdd={(v: any) => array.push('keywords', v)}
      onRequestDelete={(_v: any, i: any) => array.remove('keywords', i)}
    />
  );
}

function metaFormatter(meta: any) {
  return function (values: any) {
    return (
      values &&
      values.map((value: any) => meta.find((v: any) => v.id.toString() === value)).filter(Boolean)
    );
  };
}

function EditSectors({ allSectors }: any) {
  return (
    <Field
      component={SelectSector}
      format={metaFormatter(allSectors)}
      parse={parseId}
      name="sector_ids"
      sectors={allSectors}
      placeholder="Click to add sectors"
      label="Sectors"
    />
  );
}

function EditRegions({ allCountries }: any) {
  return (
    <Field
      component={SelectLocation}
      multiple
      format={metaFormatter(allCountries)}
      parse={parseId}
      name="region_ids"
      countries={allCountries}
      TextFieldProps={{
        label: 'Regions',
        placeholder: 'Click to add regions',
      }}
    />
  );
}

interface ProfileEditFormProps {
  editing: any;
  conflicts: any;
  onClose: () => void;
  onSubmit: (values: any) => void;
}

const profileEditFormConnector = connect((state: RootState) => {
  //const conflictSide = conflicts[editing.section][editing.side];
  return {
    allCountries: state.countries,
    allSectors: state.sectors.all,
    // initialValues: Array.isArray(conflictSide)
    //   ? conflictSide[editing.index].value
    //   : conflictSide.value,
  };
});

interface ProfileEditFormData {
  unknown: any;
}

const ProfileEditFormBare: FC<
  ProfileEditFormProps &
    ConnectedProps<typeof profileEditFormConnector> &
    InjectedFormProps<ProfileEditFormData, ProfileEditFormProps>
> = memo(
  ({
    handleSubmit,
    editing,
    conflicts,
    array,
    change,
    allCountries,
    allSectors,
    onClose,
    onSubmit,
    reset,
  }) => {
    const handleReset = useCallback(() => reset(), [reset]);

    const conflict = editing && conflicts[editing.section];
    const Component = conflict && conflict.editComponent;
    const title = conflict && `Edit ${conflict.title}`;

    return (
      <EditDialog
        title={title}
        onSubmit={handleSubmit(onSubmit)}
        onReset={handleReset}
        open={!!editing}
        onClose={onClose}
      >
        {conflict &&
          (Component ? (
            <Component
              section={editing.section}
              array={array}
              change={change}
              allCountries={allCountries}
              allSectors={allSectors}
            />
          ) : (
            <Field
              component={TextField}
              fullWidth
              // if no edit component is specified, assuming the section is the
              // same as the field name
              name={editing.section}
              label={conflict.title}
            />
          ))}
      </EditDialog>
    );
  }
);
ProfileEditFormBare.displayName = 'ProfileEditFormBare';

const ProfileEditForm = reduxForm<ProfileEditFormData, ProfileEditFormProps>({
  form: 'profileEditForm',
  enableReinitialize: true,
})(profileEditFormConnector(ProfileEditFormBare));

export default connector(ProfileMerge);
