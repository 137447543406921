import FormControl from '@mui/material/FormControl';
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText';
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel';
import Input, { InputProps } from '@mui/material/Input';
import { CSSProperties, ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';

import { QueryResponseType } from '@/__generated__/graphql';
import RadioGroup from '@/components/RadioGroup';

import { Field, HelperText, RadioGroup as WrappedRadioGroup } from '../FormAdapters';
import LabelledOutline from '../LabelledOutline';

interface FreeFormInputProps extends InputProps {
  label?: string;
  helperText?: string;
  FormLabelProps?: FormLabelProps;
  FormHelperTextProps?: FormHelperTextProps;
}

// TODO: Move this to its own components file if we plan to use it in other
// locations
const FreeFormInput = ({
  label,
  helperText,
  required,
  error,
  disabled,
  FormLabelProps,
  inputProps,
  FormHelperTextProps,
  ...other
}: FreeFormInputProps): JSX.Element => {
  return (
    <FormControl required={required} error={error} disabled={disabled}>
      {label && <FormLabel {...FormLabelProps}>{label}</FormLabel>}

      <Input {...other} />

      {helperText && <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

// TODO: move this to FormAdapters
const WrappedFreeformInput = ({ input, meta, ...rest }: any) => {
  const [value, setValue] = useState(input.value);

  useEffect(() => {
    setValue(input.value);
  }, [input.value]);

  return (
    <FreeFormInput
      {...rest}
      {...HelperText(meta, rest)}
      name={input.name}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      onBlur={(e: any) => {
        input.onChange(e);
        input.onBlur(e);
      }}
    />
  );
};

export interface QueryResponse {
  can_answer?: boolean | null;
  text_response?: string | null;
}

export interface QueryProps {
  name: string;
  query: string;
  responseType: QueryResponseType;
  required: boolean;
  index: number;
  helperText?: string;
  error?: boolean;
  last?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  value: QueryResponse;
  onChange?: (value: QueryResponse) => void;
  onBlur?: (e: FocusEvent<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const Query = ({
  name,
  query,
  responseType,
  required,
  index,
  value,
  helperText,
  error = false,
  last = false,
  disabled = false,
  style = {},
  onChange,
  onBlur,
}: QueryProps): JSX.Element => {
  const rootStyle = { ...style };

  if (disabled) {
    rootStyle.cursor = 'default';
  }
  if (!last) {
    rootStyle.marginBottom = '15px';
  }

  let component;
  if (responseType === QueryResponseType.YesNo) {
    component = (
      <RadioGroup
        FormControlProps={{
          required,
          disabled,
        }}
        error={error}
        helperText={helperText}
        //allowNull
        //disabled={disabled}
        name={`${name}.can_answer`}
        label={`${index + 1}. ${query}`}
        style={{
          flexDirection: 'row',
          fill: 'var(--primary) !important',
        }}
        // Field converts booleans to strings with the RadioGroup component
        // so coerce them back to booleans on input while allowing null
        // format={(v: any) =>
        //   v === undefined || v === null ? null : typeof v === 'boolean' ? v : v === 'true'
        // }
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
        value={value.can_answer}
        onChange={(value: string) => {
          if (!onChange) return;
          console.log(value);
          onChange({ can_answer: value === 'true' });
        }}
        onBlur={onBlur}
      />
    );
  } else if (responseType === QueryResponseType.FreeForm) {
    component = (
      <FreeFormInput
        required={required}
        disabled={disabled}
        multiline
        error={error}
        helperText={helperText}
        name={`${name}.text_response`}
        label={`${index + 1}. ${query}`}
        placeholder="Please limit your response to one or two sentences"
        inputProps={{ maxLength: 255 }}
        value={value.text_response}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (!onChange) return;
          onChange({ text_response: e.target.value });
        }}
        onBlur={onBlur}
      />
    );
  } else {
    throw new Error(`Can't render response type: ${responseType}`);
  }

  return (
    <LabelledOutline
      key={name}
      style={{
        ...rootStyle,
      }}
    >
      {component}
    </LabelledOutline>
  );
};

export interface QueriesProps {
  fields: any;
  field?: any;
  queries: any[];
  disabled?: boolean;
  style?: CSSProperties;
}

const Queries = ({ fields, field, queries, disabled, style }: QueriesProps): JSX.Element => {
  const FieldComponent = field || Field;
  // patch for react-final-form
  if (fields.get === undefined) {
    fields.get = (index: any) => fields.value[index];
  }

  const validationFunctions = useMemo(
    () =>
      queries.map((q: any) => {
        return (value: any) => {
          if ((value === null || value === undefined || value === '') && q.required) {
            return 'Required';
          }
        };
      }),
    [queries]
  );

  return fields
    .map((name: any, index: any) => {
      const query = queries.find(
        (q: any) =>
          q.id === fields.get(index)?.query_id ||
          // preserve compatibility with both input formats
          q.id === fields.get(index)?.query?.id
      );

      if (!query) return;

      const last = index === fields.length - 1;

      const rootStyle = style || {};

      if (disabled) {
        rootStyle.cursor = 'default';
      }
      if (!last) {
        rootStyle.marginBottom = '15px';
      }

      let component;
      if (query.response_type === 'yes_no') {
        component = (
          <FieldComponent
            component={WrappedRadioGroup}
            validate={validationFunctions[index]}
            FormControlProps={{
              required: query.required,
              disabled,
            }}
            allowNull
            disabled={disabled}
            name={`${name}.can_answer`}
            label={`${index + 1}. ${query.query}`}
            style={{
              flexDirection: 'row',
              fill: 'var(--primary) !important',
            }}
            // Field converts booleans to strings with the RadioGroup component
            // so coerce them back to booleans on input while allowing null
            format={(v: any) =>
              v === undefined || v === null ? null : typeof v === 'boolean' ? v : v === 'true'
            }
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        );
      }
      if (query.response_type === 'free_form') {
        component = (
          <FieldComponent
            component={WrappedFreeformInput}
            validate={validationFunctions[index]}
            required={query.required}
            disabled={disabled}
            multiline
            name={`${name}.text_response`}
            label={`${index + 1}. ${query.query}`}
            placeholder="Please limit your response to one or two sentences"
            inputProps={{ maxLength: 255 }}
          />
        );
      }
      return (
        <LabelledOutline
          key={name}
          style={{
            ...rootStyle,
          }}
        >
          {component}
        </LabelledOutline>
      );
    })
    .filter(Boolean);
};

export default Queries;
