import { Link } from 'react-router';

import { IBreadcrumbsItemProps } from '../types';

const testId = 'of-breadcrumb-item';

const Item = ({ children, to, ...rest }: IBreadcrumbsItemProps) => {
  if (to) {
    return (
      <Link
        className="block text-brand-primary underline underline-offset-2 body-2"
        data-testid={testId}
        to={to}
        {...rest}
      >
        {children}
      </Link>
    );
  }
  return (
    <p className="body-2" {...rest}>
      {children}
    </p>
  );
};

export default Item;
