import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  fetchExpertShowcaseCategory,
  removeExpertShowcaseCategory,
  updateExpertShowcaseCategory,
} from '@/actions/expertShowcase';
import { notify } from '@/actions/ui';

import ModelForm from '../ModelForm';

function ExpertShowcaseCategoryModel({
  id,
  model,
  fetchExpertShowcaseCategory,
  updateExpertShowcaseCategory,
  removeExpertShowcaseCategory,
  notify,
  category,
}: any) {
  const navigate = useNavigate();
  const returnPath = `expert_showcases/${category.showcase_id}`;

  useEffect(() => {
    if (id) {
      fetchExpertShowcaseCategory(id).then((page: any) => {
        if (!page) {
          notify('Not found', 'warning');
          navigate(`/admin/expert_showcases`);
        }
      });
    }
  }, [id]);

  const validateSections = useCallback((sections: any) => {
    const slugs = sections?.map((p: any) => p.slug);
    return sections?.map((p: any) => {
      const errors = {
        icon: required(p.icon),
        name: required(p.name),
        slug: required(p.slug),
        order: required(p.order),
        cta_label: validateSectionCtaLabel(p.cta_label, p.cta_url),
        cta_url: validateSectionCtaUrl(p.cta_label, p.cta_url),
      };
      if (slugs.indexOf(p.slug) !== slugs.lastIndexOf(p.slug)) {
        errors.slug = `Slug is duplicated`;
      }
      return errors;
    });
  }, []);

  const validateSectionCtaLabel = (ctaLabel: any, ctaUrl: any) => {
    if (!ctaLabel && ctaUrl) {
      return 'Required (CTA URL is informed)';
    }

    if (ctaLabel && !ctaLabel.trim()) {
      return 'Invalid value';
    }

    return undefined;
  };

  const validateSectionCtaUrl = (ctaLabel: any, ctaUrl: any) => {
    if (!ctaUrl && ctaLabel) {
      return 'Required (CTA Label is informed)';
    }

    if (ctaUrl && !ctaUrl.trim()) {
      return 'Invalid value';
    }

    return undefined;
  };

  const editableFields = useMemo(
    () => [
      { name: 'name', type: 'text', required: true },
      { name: 'order', type: 'number', required: true },
      {
        name: 'sections',
        validate: validateSections,
        fields: [
          { name: 'icon', type: 'text' },
          { name: 'name', type: 'text' },
          { name: 'slug', type: 'text' },
          { name: 'order', type: 'number' },
          {
            name: 'cta_label',
            label: 'CTA Label',
            type: 'text',
            maxLength: 40,
          },
          {
            name: 'cta_url',
            label: 'CTA URL',
            type: 'text',
            maxLength: 256,
          },
          {
            name: 'id',
            type: 'edit_link',
            getURL: (id: any) => {
              return id ? `/admin/expert_showcase_sections/${id}` : undefined;
            },
          },
        ],
      },
    ],
    []
  );

  const handleSubmit = useCallback(async (values: any) => {
    const category = await updateExpertShowcaseCategory(values);
    navigate(`/admin/${model.path}/${category.id}`);
  }, []);

  const handleDelete = useCallback(() => removeExpertShowcaseCategory(id), []);

  const required = useCallback((value: any) => (value ? undefined : 'Required'), []);

  return (
    <ModelForm
      title={`Category ${id} - ${category.name}`}
      id={id}
      model={model}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      initialModel={category}
      fields={editableFields}
      returnPath={returnPath}
    />
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'ExpertShowcaseCategoryModel' bec... Remove this comment to see the full error message
ExpertShowcaseCategoryModel = connect(
  (state, ownProps) => {
    return {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      category: state.expertShowcase.categories[ownProps.id] || {},
    };
  },
  {
    fetchExpertShowcaseCategory,
    updateExpertShowcaseCategory,
    removeExpertShowcaseCategory,
    notify,
  }
)(ExpertShowcaseCategoryModel);

export default ExpertShowcaseCategoryModel;
