import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { fetchExpertShowcases } from '@/actions/expertShowcase';

import ListItems from '../ListItems';
import ExpertShowcase from './ExpertShowcaseModel';

export default function ExpertShowcaseModels({
  fetchExpertShowcases,
  expertShowcases,
  model,
  id,
}: any) {
  const navigate = useNavigate();
  useEffect(() => {
    fetchExpertShowcases();
  }, []);

  const items = expertShowcases.map((page: any) => ({
    id: page.id,
    name: `${page.id} ${page.subdomain} - ${page.title}`,
  }));

  return id ? (
    <ExpertShowcase model={model} id={id !== 'new' ? id : undefined} />
  ) : (
    <ListItems model={model} items={items} onCreate={() => navigate(`/admin/${model.path}/new`)} />
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'ExpertShowcaseModels' because it... Remove this comment to see the full error message
ExpertShowcaseModels = connect(
  (state) => {
    return {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      expertShowcases: state.expertShowcase.all || [],
    };
  },
  {
    fetchExpertShowcases,
  }
)(ExpertShowcaseModels);
