import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import AreYouSureDialog from '@/componentsv2/ConfirmDialog/AreYouSureDialog';
import { useProfileId } from '@/hooks/store';
import { KnowledgeTypeToCompleteType } from '@/knowledge/addKnowledge';
import { KnowledgeType } from '@/knowledge/queries/knowledge';
import { accrualUpdate, markAccrualStale } from '@/knowledge/store/accrualSummarySlice';
import { AssignmentTypesEnum, DeleteKnowledgeService, ProfileKnowledgeScore } from '@/openapi';
import { useAppDispatch, useAppSelector } from '@/store';
import { hideDialog, uiSelector } from '@/ui/store/uiSlice';

const ProfileDeleteKnowledge = ({
  knowledge,
  skipNavigate,
}: {
  knowledge?: ProfileKnowledgeScore;
  skipNavigate?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const profileId = useProfileId();
  const ui = useAppSelector(uiSelector);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <AreYouSureDialog
      onCancel={() => dispatch(hideDialog())}
      onConfirm={() => {
        setIsDeleting(true);
        dispatch(markAccrualStale());

        DeleteKnowledgeService.deleteKnowledgeCreate({
          assignment_types: Object.values(AssignmentTypesEnum),
          type: KnowledgeTypeToCompleteType[knowledge?.type as KnowledgeType],
          knowledge_id: knowledge?.source?.object_id ?? '',
        })
          .then(async () => {
            dispatch(
              accrualUpdate({
                profileId,
                onAccrualFinish: () => {
                  queryClient.invalidateQueries([
                    'knowledge',
                    {
                      profileId,
                    },
                  ]);
                },
              })
            );
            enqueueSnackbar('Knowledge deleted from profile', {
              variant: 'success',
            });
            dispatch(hideDialog());

            if (!skipNavigate) navigate('..');
          })
          .catch(() => {
            enqueueSnackbar('Something went wrong, contact us.', {
              variant: 'error',
            });
          })
          .finally(() => {
            setIsDeleting(false);
          });
      }}
      title="Delete knowledge?"
      subtitle="Are you sure you want to remove this knowledge from your profile? It will be unlinked from the listed experiences."
      confirmButtonLabel="Delete"
      confirmButtonProps={{
        color: 'danger',
        loading: isDeleting,
        disabled: isDeleting,
      }}
      cancelButtonProps={{
        color: 'inherit',
      }}
      open={ui.data.dialog === 'DELETE_KNOWLEDGE'}
    />
  );
};

export default ProfileDeleteKnowledge;
