import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { ReactNode } from 'react';
import { Link, NavLink } from 'react-router';

import Avatar from '@/auth/components/Avatar';
import { segmentTracking } from '@/core/analytics';
import APP_ROUTES, { PROFILE_ROUTES } from '@/routes/APP_ROUTES';

import { IProfileCardProps, ProfileCardTrackingPayload } from './types';

const testId = 'of-profile-card';

const ProfileLink = ({
  className,
  tabIndex,
  children,
  profileId,
  trackingPayload = {},
}: {
  className?: string;
  tabIndex?: 0 | -1;
  children: ReactNode;
  profileId: string;
  trackingPayload?: ProfileCardTrackingPayload;
}) => (
  <NavLink
    className={className}
    to={APP_ROUTES.profile(profileId)}
    tabIndex={tabIndex}
    onClick={() => {
      segmentTracking('pressed-click-view-profile', {
        subject_profile_id: profileId,
        triggered_by: 'colleagues',
        ...trackingPayload,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }}
  >
    {children}
  </NavLink>
);

const ProfileCard = ({
  className,
  profile,
  useColleagueLink,
  colleagueUrl = PROFILE_ROUTES().colleague,
  trackingPayload,
}: IProfileCardProps) => {
  if (!profile) {
    return (
      <div className="flex items-center gap-8 p-[13px]">
        <Skeleton variant="circular" width={40} height={40} />
        <div className="body-2">
          <Skeleton variant="rectangular" width={90} sx={{ borderRadius: 1 }} />
          <Skeleton variant="rectangular" width={60} sx={{ borderRadius: 1, mt: '4px' }} />
          <Skeleton variant="rectangular" width={110} sx={{ borderRadius: 1, mt: '4px' }} />
        </div>
      </div>
    );
  }

  const mutuals = profile.mutual_projects_count;
  const experienceText = mutuals ? `${mutuals} mutual ${pluralize('experience', mutuals)}` : '';

  return (
    <li className={clsx(className, 'flex items-start gap-8')} data-testid={testId}>
      <ProfileLink tabIndex={-1} profileId={profile.id} trackingPayload={trackingPayload}>
        <Avatar fullName={profile.full_name} src={profile.picture_url} />
      </ProfileLink>
      <div>
        <p className="leading-none">
          <ProfileLink
            className="text-12 font-semibold text-brand-tertiary underline-offset-4 hover:underline"
            profileId={profile.id}
            trackingPayload={trackingPayload}
          >
            {profile.full_name}
          </ProfileLink>
        </p>
        <p className="mt-2 body-2">{profile.project_member_title || 'Participant'}</p>
        {useColleagueLink ? (
          <p className="mt-4 leading-none">
            <Link
              to={colleagueUrl(profile.id)}
              className="text-12 font-semibold text-brand-tertiary underline-offset-4 hover:underline"
            >
              {experienceText}
            </Link>
          </p>
        ) : (
          <p className="capitalize body-2">{experienceText}</p>
        )}
      </div>
    </li>
  );
};

export type { IProfileCardProps };
// export { profileCardClasses };
export { testId as ProfileCardTestId };
export default ProfileCard;
