import makeStyles from '@mui/styles/makeStyles';
import { useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import ChangePasswordForm from '@/auth/components/ChangePasswordForm';
import { RootState } from '@/store';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 28,
    margin: '25px 0',
  },
});

interface ChangePasswordProps {
  next?: string;
}

const connector = connect((state: RootState) => ({ viewer: state.viewer }));

const ChangePassword = ({
  viewer,
  next,
}: ChangePasswordProps & ConnectedProps<typeof connector>) => {
  const s = useStyles();
  const navigate = useNavigate();

  const handleUpdate = useCallback(() => {
    navigate(next || '/dashboard');
  }, [navigate, next]);

  return (
    <div className={s.root}>
      <h1 className={s.title}>
        To increase security, we ask users to update their password periodically
      </h1>
      <ChangePasswordForm userId={viewer.id} onSubmit={handleUpdate} />
    </div>
  );
};

export default connector(ChangePassword);
