import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import Button from '@/components/Button';
import EmptyMessage from '@/components/EmptyMessage';
import LayoutPage from '@/components/Layout/LayoutPage';
import Link from '@/components/Link';
import { RootState } from '@/store';
import { teal500 } from '@/theme/colors';
import { isUserApplying } from '@/utils';

const connector = connect((state: RootState) => ({
  viewer: state.viewer,
}));

class EmailValidated extends React.Component<ConnectedProps<typeof connector>> {
  render() {
    const { viewer } = this.props;
    const action =
      viewer.signup_type === 'expert' && isUserApplying(viewer) ? (
        <Link to="/signup/expert">
          <Button style={{ marginTop: 15 }}>Continue to Expert Application →</Button>
        </Link>
      ) : (
        <Link to="/dashboard">
          <Button size="large" style={{ marginTop: 15 }}>
            Go to Dashboard →
          </Button>
        </Link>
      );

    return (
      <LayoutPage hideSearch showReviewConsultation={false} showNewRequest={false}>
        <EmptyMessage
          border={false}
          iconName="check_circle"
          iconColor={teal500}
          title="Your email has been verified"
          action={action}
        />
      </LayoutPage>
    );
  }
}

export default connector(EmailValidated);
