import isEqual from 'lodash.isequal';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { fetchSiteSettings, updateSiteSettings } from '@/actions/siteSettings';
import SettingsSection from '@/components/SettingsSection';
import { usePermissions } from '@/hooks/useAppContext';
import { RootState } from '@/store';

import ModelForm from '../ModelForm';

type SiteSettingsProps = {
  id: string;
  model: any;
};

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    fetchSiteSettings,
    updateSiteSettings,
  }
);

const SiteSettings: FC<SiteSettingsProps & ConnectedProps<typeof connector>> = ({
  id,
  model,
  fetchSiteSettings,
  updateSiteSettings,
}) => {
  const [isSuperOwner] = usePermissions([
    { service: 'profile', action: 'super_owner', resource: 'dummy' },
  ]);

  const navigate = useNavigate();
  const [siteSettings, setSiteSettings] = useState(undefined);
  useEffect(() => {
    async function loadSiteSettings() {
      const settings = await fetchSiteSettings();
      setSiteSettings(settings);
    }
    loadSiteSettings();
  }, [fetchSiteSettings]);

  const readOnly = !isSuperOwner;

  const carrierOptions = useMemo(
    () => [
      { value: 'twilio', label: 'Twilio' },
      { value: 'zoom', label: 'Zoom' },
    ],
    []
  );

  const carrierDescription = (
    <p>
      The Default Conference Carrier specifies the sitewide default carrier used by the API&apos;s
      conference service.
    </p>
  );

  const aboutPageTextDescription = (
    <p>
      The OnFrontiers About Page Text is presented in the&nbsp;
      <a href="/team/onfrontiers/about/" target="_blank" rel="noreferrer">
        about page
      </a>
      . This page is enabled and further configured via the&nbsp;
      <a href="/team/onfrontiers/settings/about_page/" target="_blank" rel="noreferrer">
        team preferences.
      </a>
    </p>
  );

  const settingsSection = (
    <SettingsSection
      title="Site Settings"
      text={
        <div>
          <ul>
            <li>{aboutPageTextDescription}</li>

            <li>{carrierDescription}</li>
          </ul>
        </div>
      }
    />
  );

  const editableFields = useMemo(
    () => [
      {
        name: 'group_about_page_onfrontiers_text',
        label: 'OnFrontiers About Page Text',
        type: 'textarea',
        required: true,
        maxLength: 10000,
        readOnly,
        columnWidth: 8,
      },
      {
        name: 'default_conference_carrier',
        type: 'select',
        required: true,
        options: carrierOptions,
        disabled: readOnly,
        getOptionLabel: (option: any) => option.label,
        getOptionValue: (option: any) => option.value,
        isOptionEqualToValue: (option: any, value: any) => isEqual(option, value),
      },
    ],
    [carrierOptions, readOnly]
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      await updateSiteSettings(values);
      navigate(`/admin/${model.path}/`);
    },
    [model.path, navigate, updateSiteSettings]
  );

  return siteSettings && readOnly !== undefined ? (
    <ModelForm
      title={settingsSection}
      id={id}
      model={model}
      onSubmit={handleSubmit}
      initialModel={siteSettings}
      fields={editableFields}
      readOnly={readOnly}
    />
  ) : null;
};

export default connector(SiteSettings);
