import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import { SignupType } from '@/auth';
import Login from '@/auth/components/Login';
import Signup from '@/auth/components/Signup';
import LayoutPage from '@/components/Layout/LayoutPage';

import s from './AuthPage.module.scss';

interface AuthPageProps {
  signup: any;
  children?: ReactNode;
  tags?: string[];
  signupType: SignupType;
  nextUrl: string;
  signupLink: string;
  signinLink: string;
}

const AuthPage = ({ signup, children, tags, ...other }: AuthPageProps): JSX.Element => {
  return (
    <LayoutPage className={s.root}>
      <Helmet>
        <title>Create OnFrontiers account</title>
      </Helmet>
      {signup ? (
        <Signup tags={tags} {...other}>
          {children}
        </Signup>
      ) : (
        <Login {...other}>{children}</Login>
      )}
    </LayoutPage>
  );
};

export default AuthPage;
