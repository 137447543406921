import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { ConnectedProps, connect } from 'react-redux';
import { Link, useNavigate } from 'react-router';

import { setUserContext } from '@/actions/ui';
import { Header, NavBarItem } from '@/componentsv2/Header';
import { QUEUE_IDS } from '@/core/apiWebSocket';
import { useApp } from '@/hooks/useAppContext';
import { updateUnreadCount } from '@/messaging/store';
import { fetchConflictsCount } from '@/profile/store';
import { RootState } from '@/store';

import Button from '../Button/Button';
import SelectGroup from '../SelectGroup';
import { BadgeCount } from './UserMenu';
import { navItemsBuilder } from './navItemsBuilder';

function UserContextOption({ option }: any) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {option.name}
      {option.description && (
        <span
          style={{
            marginLeft: 5,
          }}
        >
          ({option.description})
        </span>
      )}
    </div>
  );
}

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
    userContext: state.ui.userContext,
    userContextOptions: state.ui.userContextOptions,
    unreadCount: state.messaging.unreadCount,
    conflictsCount: state.profiles.counts.conflicts,
  }),
  {
    updateUnreadCount,
    setUserContext,
    fetchConflictsCount,
  }
);

interface AccessHeaderProps {
  SearchComponent?: React.ComponentType;
  shouldShowNewRequest: boolean;
}

const AccessHeader: FC<AccessHeaderProps & ConnectedProps<typeof connector>> = ({
  viewer,
  unreadCount,
  userContext,
  userContextOptions,
  conflictsCount,
  SearchComponent,
  setUserContext,
  fetchConflictsCount,
  shouldShowNewRequest,
}) => {
  const navigate = useNavigate();
  const unsubscribeRef = useRef<(() => void) | null>(null);
  const { apiWebSocket } = useApp();

  const [navItems, setNavItems] = useState<NavBarItem[]>([]);

  const handleChangeContext = useCallback(
    (userContext: any) => {
      setUserContext(userContext);
      if (userContext === 'expert' && window.location.pathname === '/expert_requests') {
        navigate('/dashboard');
      } else {
        navigate(window.location);
      }
    },
    [navigate, setUserContext]
  );

  const handleGroupContext = useCallback(
    (value: any) => {
      if (!value) return;
      handleChangeContext(value);
    },
    [handleChangeContext]
  );

  const userMenuItems: NavBarItem[] = [
    ...(userContextOptions || []).map((option, index, items) => ({
      key: `${option.value}-context`,
      text: <UserContextOption option={option} />,
      divider: !viewer.admin && index === items.length - 1,

      onClick: () => {
        handleChangeContext(option.value);
      },
    })),
    {
      key: 'dashboard',
      text: 'Dashboard',
      onClick: () => {
        navigate('/dashboard');
      },
    },
    {
      key: 'profile',
      text: 'My Profile',
      onClick: () => {
        navigate(`/profile/${viewer.username}`);
      },
    },
    {
      key: 'settings',
      text: 'Settings',
      onClick: () => {
        navigate(`/settings`);
      },
      divider: true,
    },
  ];

  if (viewer.admin) {
    userMenuItems.push(
      ...[
        {
          key: 'applications',
          text: 'View Applications',
          to: '/search?expert_states[]=applied&expert_states[]=applying&sort=created.at&asc=false',
        },
        {
          key: 'profile-uploader',
          text: 'Profile Uploader',
          onClick: () => {
            navigate(`/profile-uploader`);
          },
        },
        {
          key: 'profile-conflicts',
          text: (
            <div className="flex items-center">
              Profile Conflicts <BadgeCount count={conflictsCount} />
            </div>
          ),
          onClick: () => {
            navigate(`/profile-conflicts`);
          },
          divider: true,
        },
      ]
    );
  }

  if (shouldShowNewRequest) {
    userMenuItems.push({
      key: 'expert-requests',
      text: <span className="text-brand-tertiary">Find Experts</span>,
      onClick: () => {
        navigate('/request_expert');
      },
    });
  }

  const hasGroups = viewer.groups && viewer.groups.length > 0;
  const isAccountEnterprise =
    viewer.groups && viewer.groups.find((g: any) => g.account_type === 'enterprise');

  const handleNewUnreadCount = useCallback((payload: any) => {
    updateUnreadCount(payload.count);
  }, []);

  useEffect(() => {
    if (!viewer.id) {
      setNavItems([
        {
          key: 'login',
          text: 'Login',
          to: '/login',
        },
        {
          key: 'signup',
          text: 'Sign Up',
          to: '/signup?next=%2Fdashboard',
        },
      ]);
      return;
    }

    const items = navItemsBuilder({
      profileUrl: viewer.html_url ? new URL(viewer.html_url).pathname : '/',
      unreadMessages: unreadCount > 0 ? unreadCount : null,
      userContext: userContext,
      isAdmin: viewer.admin,
      hasGroups,
      isAccountEnterprise,
      hasExpertState: !!viewer.expert_state,
      conflictsCount,
      shouldShowNewRequest,
      userContextOptions,
    });
    const switchAccountItem = items.find((item) => item.key === 'switch-account');
    if (switchAccountItem) {
      switchAccountItem.children = [
        ...(userContextOptions || []).map((option) => ({
          key: `${option.value}-context`,
          text: (
            <span className="flex items-center text-brand-secondary">
              {option.value === userContext && (
                <ChevronRight size={16} className="-ml-[20px] mr-[4px]" />
              )}
              <UserContextOption option={option} />
            </span>
          ),

          onClick: () => {
            handleChangeContext(option.value);
          },
        })),
        {
          key: 'context-selector',
          text: (
            <SelectGroup
              autoComplete
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              onChange={handleGroupContext}
              className="m-0 p-0 w-full"
              margin="none"
            />
          ),
          handleKeyDown: (e: any) => {
            e.stopPropagation();
          },
        },
      ];
    }
    setNavItems(
      items.map((item: any) => ({
        ...item,
        onClick: () => navigate(item.to),
      }))
    );
  }, [
    viewer,
    userContext,
    unreadCount,
    conflictsCount,
    hasGroups,
    isAccountEnterprise,
    shouldShowNewRequest,
    navigate,
    userContextOptions,
    handleChangeContext,
    handleGroupContext,
  ]);

  useEffect(() => {
    if (!viewer.id) return;

    unsubscribeRef.current = apiWebSocket.on(
      QUEUE_IDS.CHAT_UNREAD_MESSAGES,
      viewer.id,
      handleNewUnreadCount
    );

    return () => {
      if (unsubscribeRef.current) unsubscribeRef.current();
    };
  }, [apiWebSocket, handleNewUnreadCount, viewer.id]);

  useEffect(() => {
    if (!viewer.id) return;
    if (viewer.admin) fetchConflictsCount();
  }, [fetchConflictsCount, viewer]);

  if (hasGroups) {
    userMenuItems.splice(userContextOptions?.length + 2, 0, {
      key: 'teams',
      text: 'Manage My Teams',
      to: '/teams',
    });
  }

  if (viewer.admin) {
    userMenuItems.splice(userContextOptions?.length, 0, {
      key: 'context-selector',
      text: (
        <SelectGroup
          autoComplete
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onChange={handleGroupContext}
          className="m-0 p-0 w-full"
          margin="none"
        />
      ),
      handleKeyDown: (e: any) => {
        e.stopPropagation();
      },
      divider: true,
    });
  }

  const displayName = useMemo(() => {
    const context = userContextOptions?.find((o: { value: any }) => o.value === userContext);

    if (!context) return `${viewer.first_name} ${viewer.last_name}`;

    return context.name + (context.description ? ` (${context.description})` : '');
  }, [viewer, userContext, userContextOptions]);

  return (
    <Header
      userData={{
        displayName,
        picture_url: viewer.picture_url as string,
        username: viewer.username || undefined,
      }}
      isAuthenticated={!!viewer.id}
      SearchComponent={SearchComponent}
      findExperts={
        shouldShowNewRequest ? (
          <Link to="/request_expert" className="hidden xl:block">
            <Button size="small" label="Find Experts" style={{ minWidth: 115 }} />
          </Link>
        ) : null
      }
      navbarItems={navItems}
      userMenuItems={userMenuItems}
      logout={() => navigate('/logout')}
    />
  );
};

export default connector(AccessHeader);
