import pluralize from 'pluralize';
import { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { track } from '@/actions/tracking';
import { red500 } from '@/theme/colors';

import Button from '../Button/Button';
import MaterialIcon from '../Icon/MaterialIcon';
import LayoutPage from '../Layout/LayoutPage';
import s from './InaccessibleArchived.module.scss';

interface InaccessibleArchivedProps {
  entity: string;
  selectedTab: string;
}

const connector = connect(undefined, {
  track,
});

const InaccessibleArchived = ({
  selectedTab,
  entity,
  track,
}: InaccessibleArchivedProps & ConnectedProps<typeof connector>) => {
  useEffect(() => {
    track(`promo.show.${entity}.archived`);
  }, [entity, track]);

  return (
    <LayoutPage showNav selected={selectedTab}>
      <div className={s.root}>
        <MaterialIcon style={{ color: red500 }} size={45} icon="archive" />

        <div className={s.title}>You do not have access to this archived {entity}.</div>

        <div className={s.contents}>
          Looks like you’re trying to access a {entity} over 90 days old. <br />
          Upgrade to our Advanced or Enterprise account to unlock archived {pluralize(entity)}.
        </div>

        <Button
          style={{ marginLeft: 10, marginTop: 20 }}
          onClick={() => {
            track(`promo.chat.${entity}.archived`);
            window.HubSpotConversations?.widget?.open();
          }}
        >
          Start a Chat
        </Button>
      </div>
    </LayoutPage>
  );
};

export default connector(InaccessibleArchived);
