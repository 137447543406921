import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { APIError } from '@/core/api';
import RateAndPreferenceForm from '@/profile/components/forms/RateAndPreferenceForm';
import { updateProfile } from '@/profile/store';

const getStyles = makeStyles((theme) => ({
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 0,
    // @ts-expect-error TS(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      marginTop: 15,
    },
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 30,
    color: '#3F4952',
  },
}));

function RateAndPreference({ nextUrl, profileId, domain, profile, updateProfile, ...other }: any) {
  const s = getStyles();
  const navigate = useNavigate();
  const { bill_rate, available_long_term, available_marketplace, hide_profile } = profile;

  const showHourlyRate = !domain || domain.signup_prompt_hourly_rate;
  const showAvailableMarketplace = domain && domain.signup_prompt_marketplace;
  const showProfilePublicity = !domain || domain.signup_prompt_profile_publicity;

  const initialValues = useMemo(
    () => ({
      bill_rate: showHourlyRate ? bill_rate : 0,
      available_marketplace: showAvailableMarketplace ? available_marketplace : !domain,
      public_profile: showProfilePublicity ? !hide_profile : false,
      available_long_term,
    }),
    [
      available_long_term,
      available_marketplace,
      bill_rate,
      domain,
      hide_profile,
      showAvailableMarketplace,
      showHourlyRate,
      showProfilePublicity,
    ]
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await updateProfile({
          id: profileId,
          ...values,
          hide_profile: !values.public_profile,
          bill_rate: values.bill_rate,
          public_profile: undefined,
        });
      } catch (e) {
        if (e instanceof APIError) {
          return {
            [FORM_ERROR]: e.rawError.map((error: any) => error.message),
          };
        } else {
          throw e;
        }
      }

      navigate(nextUrl);
    },
    [navigate, nextUrl, profileId, updateProfile]
  );

  const validate = useCallback((values: any) => {
    const errors = {};

    if (values.bill_rate === '' || values.bill_rate === undefined) {
      // @ts-expect-error TS(2339) FIXME: Property 'bill_rate' does not exist on type '{}'.
      errors.bill_rate = 'Required';
    }

    return errors;
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} sm={12}>
        <p className={s.title}>
          {showHourlyRate ? 'Set your rate and preferences' : 'Set your preferences'}
        </p>
        <p className={s.subtitle}>You can change these at any time.</p>

        <Form
          component={RateAndPreferenceForm}
          showHourlyRate={showHourlyRate}
          showAvailableMarketplace={showAvailableMarketplace}
          showProfilePublicity={showProfilePublicity}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          {...other}
        />
      </Grid>
    </Grid>
  );
}

RateAndPreference.propTypes = {
  nextUrl: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
};

// @ts-expect-error TS(2631) FIXME: Cannot assign to 'RateAndPreference' because it is... Remove this comment to see the full error message
RateAndPreference = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId] || {};
    return {
      profile,
    };
  },
  {
    updateProfile,
  }
)(RateAndPreference);

export default RateAndPreference;
