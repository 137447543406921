import React, { PureComponent } from 'react';
import { useNavigate } from 'react-router';

import Dialog from '@/components/Dialog';
import Link from '@/components/Link';
import ReviewConsultation from '@/components/ReviewConsultation';
import { isFixedRate as isConsultationFixedRate } from '@/consultation/store';
import { isFixedRate as isERFixedRate } from '@/expertrequest/store';

import CancelConsultation from './CancelConsultation';
import ConfirmCall from './ConfirmCall';
import InviteParticipant from './InviteParticipant';
import RequestTranscript from './RequestTranscript';
import Reschedule from './Reschedule';

function Dialogs(props: any) {
  const navigate = useNavigate();

  const {
    user,
    consultation,
    date,
    viewer,
    isViewerExpert,
    onDismissReview,
    onCloseDialog,
    onDeny,
    onCancel,
    onConfirm,
    onSuggestTime,
    onRequestTranscript,
    onInviteParticipant,
    openDenyDialog,
    openConfirmDialog,
    openCancelDialog,
    openCompleteTraining,
    openReviewCall,
    openSuggestTime,
    openRequestTranscript,
    openInviteParticipant,
    submittingTranscript,
    initialUsefulnessRating,
    initialMatchExperienceRating,
    shouldReview,
    isWrittenConsultation,
  } = props;

  const {
    id: consultationId,
    expert,
    expert_request: expertRequest,
    engagement_type: engagementType,
    requester_timezone: requesterTimezone,
    expert_timezone: expertTimezone,
    credit_rate: creditRate,
    proposed_times: proposedTimes = [],
    rejected_times: rejectedTimes = [],
    expected_duration: expectedDuration,
    transcription_price: transcriptionPrice,
    recording_url: recordingURL,
    transcription_order: transcriptionOrder,
  } = consultation;

  const userTimezone = isViewerExpert ? requesterTimezone : expertTimezone;
  const hasCompletedComplianceTraining = expert && !!expert.compliance_completed_at;
  const complianceTrainingUrl = `/compliance_training/consultation/${consultationId}/${date || ''}`;

  const isFixedRate =
    isConsultationFixedRate(engagementType) || isERFixedRate(expertRequest?.er_type);

  return (
    <div>
      <CancelConsultation
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        title="Deny Call?"
        confirmLabel="Yes, Deny"
        label="What is your reason for denying? (optional)"
        open={openDenyDialog}
        showPolicy={!isViewerExpert}
        onCancel={onCloseDialog}
        onConfirm={onDeny}
      />

      <CancelConsultation
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        open={openCancelDialog}
        showPolicy={!isViewerExpert}
        onCancel={onCloseDialog}
        onConfirm={onCancel}
      />

      <ConfirmCall
        open={openConfirmDialog}
        onCancel={onCloseDialog}
        onConfirm={onConfirm}
        confirmText="This is an unpaid opportunity call. Do you want to accept?"
      />

      {isViewerExpert && !hasCompletedComplianceTraining && (
        <Dialog
          open={openCompleteTraining}
          title="Complete Compliance Training"
          confirmLabel="Start Training →"
          onClose={onCloseDialog}
          onCancel={onCloseDialog}
          onConfirm={() => navigate(complianceTrainingUrl)}
        >
          <div>
            You must <Link to={complianceTrainingUrl}>complete the compliance training</Link> before
            interacting with a consultation.
          </div>
        </Dialog>
      )}

      {shouldReview && (
        <ReviewConsultation
          open={openReviewCall}
          consultation={consultation}
          user={isViewerExpert ? consultation.requester : consultation.expert}
          onCancel={onDismissReview}
          onConfirm={onCloseDialog}
          initialUsefulnessRating={initialUsefulnessRating}
          initialMatchExperienceRating={initialMatchExperienceRating}
          isViewerExpert={isViewerExpert}
        />
      )}

      <Reschedule
        open={openSuggestTime}
        isWrittenConsultation={isWrittenConsultation}
        user={user}
        userTimezone={userTimezone}
        creditRate={creditRate}
        isViewerExpert={isViewerExpert}
        rejectedTimes={rejectedTimes}
        proposedTimes={proposedTimes}
        duration={expectedDuration}
        isFixedRate={isFixedRate}
        showDuration={!isViewerExpert}
        onCancel={onCloseDialog}
        onConfirm={onSuggestTime}
      />

      {recordingURL && (!transcriptionOrder || transcriptionOrder.state === 'canceled') && (
        <RequestTranscript
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          open={openRequestTranscript}
          onClose={onCloseDialog}
          onCancel={onCloseDialog}
          onConfirm={onRequestTranscript}
          submittingTranscript={submittingTranscript}
          transcriptionPrice={transcriptionPrice}
        />
      )}

      <InviteParticipant
        title="Invite Participant"
        viewer={viewer}
        consultation={consultation}
        open={openInviteParticipant}
        onCancel={onCloseDialog}
        onConfirm={onInviteParticipant}
      />
    </div>
  );
}

export default Dialogs;
