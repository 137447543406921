import clsx from 'clsx';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router';

import Box from '@/componentsv2/Box';
import { OneLineList } from '@/componentsv2/OneLineList';
import { useSelf } from '@/hooks/store';
import SparklesIcon from '@/icons/sparkles.svg';
import { mq } from '@/theme/screens';
import { jobTitleCapitalized, organizationCapitalized } from '@/utils/capitalization';
import { safeFormatDate } from '@/utils/date';
import { assignmentTypeDict } from '@/utils/texts';

import ExperienceRowSkeleton from './Skeleton';
import { IExperienceRowProps, RowDetails } from './types';

const testId = 'of-experience-row';

const DetailsList = ({ details }: { details: RowDetails }) => {
  return details?.length ? (
    <OneLineList className="flex-wrap" mq={mq.md}>
      {details?.map((detail, key) => (
        <OneLineList.Item key={key} className={clsx(detail.className, 'text-grey-600 body-2')}>
          {detail.text}
        </OneLineList.Item>
      ))}
    </OneLineList>
  ) : null;
};

const ExperienceRow = ({
  className,
  experience,
  label,
  details,
  detailsLine2,
  action,
  to,
  isLoading,
  indicateExpandedKnowledge,
}: IExperienceRowProps) => {
  const isSelf = useSelf();
  if (isLoading) {
    return <ExperienceRowSkeleton className="" />;
  }

  let detailsToRender: RowDetails = experience
    ? [
        {
          text: assignmentTypeDict[experience.assignment_type],
        },
        experience.organization_name
          ? { text: organizationCapitalized(experience.organization_name) }
          : {
              text: 'Company Missing',
              className: isSelf ? 'text-warning-main' : 'text-grey-400',
            },
      ]
    : [];

  let detailsLine2ToRender: RowDetails = experience
    ? [
        {
          text: `Role: ${
            experience.title_raw_term_name
              ? jobTitleCapitalized(experience.title_raw_term_name)
              : 'Participant'
          }`,
        },
        {
          text: `${safeFormatDate(
            experience.assignment_start_date
          )} - ${safeFormatDate(experience.assignment_end_date, 'Present')}`,
        },
      ]
    : [];

  if (details) detailsToRender = details;
  if (detailsLine2) detailsLine2ToRender = detailsLine2;

  return (
    <Box
      component={to ? Link : 'div'}
      {...(to ? { to } : {})}
      className={clsx(
        className,
        'group flex items-center rounded-md border border-light-primary p-12 shadow-5',
        {
          'hover:bg-gray-50': to,
        }
      )}
      data-testid={testId}
    >
      <span className="block grow">
        <p className="text-grey-900 subtitle-1">
          {indicateExpandedKnowledge ? (
            <span className="mr-4 inline-block rounded-sm bg-deep-purple-50/60 p-4 align-text-bottom">
              <SparklesIcon className="h-12 w-12 fill-deep-purple-400" />
            </span>
          ) : null}
          {label ?? (organizationCapitalized(experience?.title) || 'Work Experience')}
        </p>
        <DetailsList details={detailsToRender} />
        <DetailsList details={detailsLine2ToRender} />
      </span>
      {to ? (
        <ChevronRight className="h-16 w-16 shrink-0 transition-transform duration-200 group-hover:translate-x-4" />
      ) : action ? (
        action
      ) : null}
    </Box>
  );
};

export { testId as ExperienceRowTestId };
export type { IExperienceRowProps };
export default ExperienceRow;
