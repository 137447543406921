import { redirect } from 'react-router';

import { validateEmail } from '@/actions/address';
import { fetchSignupDomain } from '@/actions/domain';
import { ActionContext, LegacyRoute } from '@/routes/routesMiddleware';

import AwaitingEmailValidationPage from './AwaitingEmailValidationPage';
import EmailValidatedPage from './EmailValidatedPage';

const TOKEN_EXPIRED_ERROR = 'GraphQL Error: expired token';

const routes: LegacyRoute[] = [
  {
    path: '/validate_email',
    public: true,
    element: <div>Link expired.</div>,
    async action({ store, query }: ActionContext): Promise<Response | null> {
      const token = query.get('validate_token');
      document.title = 'OnFrontiers';

      if (!token) return redirect('/dashboard');

      try {
        await store.dispatch(validateEmail(token));
      } catch (err: unknown) {
        if (err instanceof Error && err.message === TOKEN_EXPIRED_ERROR) {
          return null;
        }
        throw err;
      }

      return redirect('/email_validated');
    },
  },
  {
    //subdomain: '*',
    path: '/awaiting_email_validation',
    public: false,
    element: <AwaitingEmailValidationPage />,
    async action({ store, subdomain }: ActionContext): Promise<Response | null> {
      document.title = 'Validate Email Address';
      if (subdomain) {
        const domain = await store.dispatch(fetchSignupDomain(subdomain));
        if (!domain) return redirect('/dashboard');
      }

      const { viewer } = store.getState();
      if (viewer.email && viewer.email.accepted) {
        return redirect('/dashboard');
      }
      return null;
    },
  },
  {
    path: '/email_validated',
    public: false,
    element: <EmailValidatedPage />,
    async action(): Promise<null> {
      document.title = 'Email Validated';
      return null;
    },
  },
];

export default routes;
