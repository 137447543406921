import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import { useCallback, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router';

import Box from '@/componentsv2/Box';
import { Drawer } from '@/componentsv2/Drawer';
import Dropdown from '@/componentsv2/Dropdown';
import Pagination from '@/componentsv2/Pagination';
import UpdatingIndicator from '@/componentsv2/UpdatingIndicator';
import config from '@/config';
import { useProfileId, useSelf } from '@/hooks/store';
import useCloseDrawer from '@/hooks/useCloseDrawer';
import useProfileBreadcrumbs from '@/hooks/useProfileBreadcrumbs';
import {
  DEFAULT_KNOWLEDGES_LIMIT,
  KNOWLEDGE_TYPE_ENUM,
  KnowledgeEnumType,
  KnowledgesOrdering,
  useKnowledgesByTypeQuery,
} from '@/knowledge/queries/knowledge';
import { accrualSummarySelector } from '@/knowledge/store/accrualSummarySlice';
import { TITLE as EXPERTISE_TITLE } from '@/profilev2/components/Expertise';
import { TITLE as GENERAL_KNOWLEDGE_TITLE } from '@/profilev2/components/GeneralKnowledge';
import { TITLE as SPECIALIZED_ACTIVITY_TITLE } from '@/profilev2/components/SpecializedActivities';
import { TITLE as TOOLS_TITLE } from '@/profilev2/components/Tools';
import { profileSelector } from '@/profilev2/store/profileSlice';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';
import { knowledgeCapitalized } from '@/utils/capitalization';
import { knowledgeScoreToPercent } from '@/utils/knowledgeScoreToPercent';

import KnowledgeBar from '../KnowledgeBar';
import KnowledgeBarSkeletons from '../KnowledgeBar/Skeletons';

const testId = 'of-knowledge-list-drawer';

export const KNOWLEDGE_SEGMENTS = [
  'expertise',
  'specialized-activities',
  'tools',
  'general',
] as const;

export type KnowledgeTypeSegments = (typeof KNOWLEDGE_SEGMENTS)[number];

const KNOWLEDGE_TYPE_DICT: Record<KnowledgeTypeSegments, KnowledgeEnumType> = {
  expertise: KNOWLEDGE_TYPE_ENUM.TOPICS,
  'specialized-activities': KNOWLEDGE_TYPE_ENUM.ACTIVITIES,
  tools: KNOWLEDGE_TYPE_ENUM.TOOLS,
  general: KNOWLEDGE_TYPE_ENUM.GENERAL,
};

export const CONTENT: Record<KnowledgeTypeSegments, { title: string; description?: string }> = {
  expertise: {
    title: EXPERTISE_TITLE,
    description: `A topic in ${config.productName} refers to the broad knowledge topics this person has worked with.`,
  },
  'specialized-activities': {
    title: SPECIALIZED_ACTIVITY_TITLE,
    description:
      'A specialized activity is a task or function that requires a specific set of skills, knowledge, training, equipment, or expertise to perform. These activities often require a high level of specialization and are typically performed by individuals or teams with specialized training or education in a particular field.',
  },
  tools: {
    title: TOOLS_TITLE,
  },
  general: {
    title: GENERAL_KNOWLEDGE_TITLE,
  },
};

export const KNOWLEDGE_SORT_OPTIONS: {
  value: KnowledgesOrdering;
  display: string;
}[] = [
  {
    value: '-value',
    display: 'High to low',
  },
  {
    value: 'value',
    display: 'Low to high',
  },
];

const KnowledgeListDrawer = () => {
  const profileId = useProfileId();
  const scrollableListRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState<KnowledgesOrdering>('-value');
  const params = useParams();
  const knowledgeType = params.knowledgeType as KnowledgeTypeSegments;
  const handleCloseDrawer = useCloseDrawer();
  const { data: profileData, isLoading: isProfileLoading } = useAppSelector(profileSelector);
  const isSelf = useSelf();
  const { isLoading: isAccrualRefetching, accrualIsStale } = useAppSelector(accrualSummarySelector);

  const handlePageChange = useCallback((page: number) => {
    setPage(page);
    scrollableListRef.current?.scrollTo(0, 0);
  }, []);

  const handleSortOrderChange = useCallback(
    (newOrdering: string) => {
      setSortValue(newOrdering as KnowledgesOrdering);
      handlePageChange(1);
    },
    [handlePageChange]
  );

  const validKnowledgeType = KNOWLEDGE_SEGMENTS.includes(knowledgeType);

  const {
    data: knowledges,
    isLoading,
    isRefetching,
    isPreviousData,
  } = useKnowledgesByTypeQuery(
    {
      profileId,
      knowledgeTypes: [KNOWLEDGE_TYPE_DICT[knowledgeType] as KnowledgeEnumType],
      ordering: sortValue,
      page,
    },
    {
      enabled: validKnowledgeType,
    }
  );

  const title = knowledgeType ? (CONTENT[knowledgeType].title ?? 'Skills') : 'Skills';

  const description = knowledgeType ? (CONTENT[knowledgeType].description ?? null) : null;

  const breadcrumbs = useProfileBreadcrumbs([
    {
      title,
    },
  ]);

  if (!validKnowledgeType) {
    return <Navigate to=".." replace />;
  }

  const showUpdatingScores = isSelf && (isRefetching || (isAccrualRefetching && accrualIsStale));

  return (
    <>
      <Drawer.Header onClose={handleCloseDrawer} breadcrumbs={breadcrumbs} />
      <Drawer.Main ref={scrollableListRef} data-testid={testId}>
        <Drawer.Section>
          <div className="border-b border-grey-400 p-20">
            <div className="space-y-8">
              <div className="flex items-start justify-between">
                {isProfileLoading ? (
                  <Skeleton variant="text" width={200} />
                ) : (
                  <span className="body-1">{profileData?.full_name}&rsquo;s Skills</span>
                )}
              </div>
              <h2 className="hd-4">{title}</h2>
              {description ? <p className="text-grey-600 body-2">{description}</p> : null}
            </div>
          </div>
          <div className="p-16">
            <div className="flex justify-between">
              <div className="flex gap-12">
                <Drawer.DetailsList
                  details={{
                    [title]: !isLoading ? knowledges?.count : undefined,
                  }}
                />
                {showUpdatingScores ? <UpdatingIndicator text="Updating knowledge scores" /> : null}
              </div>
              <Dropdown
                id="knowledge-list-drawer-sort"
                options={KNOWLEDGE_SORT_OPTIONS}
                value={sortValue}
                onChange={handleSortOrderChange}
              />
            </div>
            <ul
              className={clsx('mt-16 flex flex-col gap-10', {
                'opacity-50': isPreviousData,
              })}
            >
              {isLoading ? (
                <KnowledgeBarSkeletons />
              ) : (
                knowledges?.results?.map((knowledge) => {
                  return (
                    <li key={knowledge.name}>
                      <KnowledgeBar
                        label={knowledgeCapitalized(knowledge.name)}
                        value={knowledgeScoreToPercent(+(knowledge.value || 0))}
                        to={'../' + PROFILE_ROUTES().knowledge(knowledge.name ?? '')}
                      />
                    </li>
                  );
                })
              )}
            </ul>
            {knowledges?.count && knowledges.count > DEFAULT_KNOWLEDGES_LIMIT ? (
              <Box sx={{ mt: 'auto' }}>
                <Pagination
                  disabled={isLoading}
                  page={page}
                  count={Math.ceil(knowledges.count / DEFAULT_KNOWLEDGES_LIMIT)}
                  handlePageChange={(_, page) => handlePageChange(page)}
                />
              </Box>
            ) : null}
          </div>
        </Drawer.Section>
      </Drawer.Main>
    </>
  );
};

export { testId as KnowledgeListDrawerTestId };
export default KnowledgeListDrawer;
