import { redirect } from 'react-router';

import { fetchCountries } from '@/actions/country';
import { fetchSectors } from '@/actions/sector';
import { track } from '@/actions/tracking';
import Layout from '@/components/Layout';
import RequestConsultation from '@/components/RequestConsultation';
import { hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import { Viewer } from '@/core/viewer';
import { fetchExpertRequest } from '@/expertrequest/store';
import { createChannel } from '@/messaging/store';
import NotFoundPage from '@/pages/NotFoundPage';
import Profile from '@/profile/components/Profile';
import { ActionContext, LegacyRoute } from '@/routes/routesMiddleware';
import { AppStore } from '@/store';
import { fetchUser } from '@/store/user';
import { isBot, pathAndQuery } from '@/utils';

const CREATE_CHANNEL_AUTH_ERROR = 'GraphQL Error: basic accounts cannot create messaging channels';

export function getLinkedData(profile: Record<string, any>) {
  const exp = profile.experiences && profile.experiences.find((e: any) => e.current);
  const data = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: profile.name,
    url: profile.html_url,
    jobTitle: profile.title,
    address: {
      '@type': 'PostalAddress',
      addressCountry: profile.country,
      addressLocality: profile.city,
    },
    worksFor: exp && {
      '@type': 'Organization',
      legalName: exp.company_name,
      address: exp.region,
    },
  };

  return data;
}

function trackProfileView(store: AppStore, viewer: Viewer, user: any) {
  const { profile } = user;

  const isSelf = viewer.id === user.id;
  if (isSelf) return;

  if (viewer.id) {
    return store.dispatch(track('profile.view', profile.id, undefined, true));
  }

  return store.dispatch(track('profile.view', profile.id, { ua: viewer.userAgentParsed.ua }, true));
}

interface ActionOptions {
  requestConsultation?: boolean;
  messagingPromo?: boolean;
}

async function action(
  { params, store, query, location, permission }: ActionContext,
  options: ActionOptions = {}
) {
  const { requestConsultation = false, messagingPromo = false } = options;
  const expertRequestId = query.get('expertRequestId') || undefined;
  const openedDialog = !!query.get('dialog');

  const { viewer } = store.getState();

  const { username } = params;
  if (!username) {
    return viewer.html_url && redirect(viewer.html_url);
  }

  const anonymousRequest = !viewer.id && requestConsultation;
  if (anonymousRequest) {
    return redirect(`/login?next=${pathAndQuery(location)}`);
  }

  let user;
  try {
    [user] = await Promise.all([
      store.dispatch(
        fetchUser(params.username, {
          stats: true,
          groups: true,
          recruiter: true,
          audit: true,
          experiences: true,
          education: true,
          addresses: true,
          expertise: true,
          groupKeywords: true,
          sources: true,
          internalNetworks: true,
          otpAuthEnabled: !!viewer.admin,
          availableConsultation: true,
        })
      ),
      store.dispatch(fetchCountries()),
      store.dispatch(fetchSectors()),
    ]);
  } catch (e: unknown) {
    if (hasErrorCode(e, ERROR_CODES.AUTH_USER_NOT_FOUND)) {
      return <NotFoundPage />;
    }
    throw e;
  }

  const self = viewer.id === user.id;
  const { profile } = user;

  const [updatePerm] = await permission.allowedBatch([
    { service: 'profile', action: 'update', resource: profile.id },
    {
      service: 'group_member',
      action: 'update_group_keywords',
      resource: profile.id,
    },
    { service: 'promo', action: 'see_start_chat', resource: user.id },
    {
      service: 'messaging',
      action: 'start_non_anonymous_chat',
      resource: user.id,
    },
  ]);

  const expertRequest =
    expertRequestId && (await store.dispatch(fetchExpertRequest(expertRequestId)));

  const bot = isBot(viewer.userAgentParsed);

  // must await to add tracking response to the redux store and prevent
  // client/server duplication, and ignore bots
  if (!bot) {
    await trackProfileView(store, viewer, user);
  }

  // const linkedData = getLinkedData(profile);

  document.title = `${profile.name} — OnFrontiers Expert`;
  return (
    <Layout showNav selected="profile">
      <Profile
        userId={user.id}
        profileId={profile.id}
        editable={updatePerm.allowed}
        self={self}
        expertRequestId={expertRequestId}
        openedDialog={openedDialog}
        messagingPromo={messagingPromo}
      />
      {viewer.id && requestConsultation && (
        <RequestConsultation
          open={requestConsultation}
          expertId={user.id}
          profile={profile}
          expertRequest={expertRequest}
          returnTo={`/profile/${profile.url_endpoint}`}
        />
      )}
    </Layout>
  );
}

const path = '/profile';

const route: LegacyRoute = {
  path: '/profile',
  children: [
    {
      path: `${path}/:username?`,

      async action(context) {
        return await action(context, { requestConsultation: false });
      },
    },
    {
      path: `${path}/:username/request`,

      async action(context) {
        return await action(context, { requestConsultation: true });
      },
    },
    {
      path: `${path}/:username/message`,

      async action(context) {
        const { store, params } = context;
        const { viewer } = store.getState() as { viewer: Viewer };
        const { username } = params;

        if (!viewer.id) {
          return redirect(`/login?next=/profile/${username}/message`);
        }

        const user = await store.dispatch(fetchUser(username));

        try {
          const channelId = await store.dispatch(createChannel([viewer.id, user.id]));
          return redirect(`/messaging/${channelId}`);
        } catch (err: any) {
          if (err.message === CREATE_CHANNEL_AUTH_ERROR) {
            return await action(context, { messagingPromo: true });
          }
        }
      },
    },
  ],
};

export default route;
