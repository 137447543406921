import { Helmet } from 'react-helmet';
import { LoaderFunction, redirect, useLoaderData } from 'react-router';

import { fetchSignupDomain } from '@/actions/domain';
import Login from '@/auth/components/Login';
import BodyContainer from '@/components/BodyContainer';
import LayoutPage from '@/components/Layout/LayoutPage';
import { marketingLinks } from '@/components/MarketingLayout/marketingLinks';
import { rewriteSubdomainUrl } from '@/core/domain';
import NotFoundPage from '@/pages/NotFoundPage';
import { MiddlewareContext } from '@/routes/routesMiddleware';
import { parseHostname } from '@/utils';

import { SignupType } from '..';

interface LoaderData {
  marketingLinks: any;
  nextUrl: string;
  invite?: string;
  signupType?: SignupType;
  domain: any;
}

const LoginPage = () => {
  const { marketingLinks, nextUrl, invite, signupType, domain } = useLoaderData() as LoaderData;
  return (
    <LayoutPage marketingLinks={marketingLinks}>
      <Helmet>
        <title>Log In | OnFrontiers</title>
      </Helmet>
      <BodyContainer>
        <Login nextUrl={nextUrl} invite={invite} signupType={signupType} domain={domain} />
      </BodyContainer>
    </LayoutPage>
  );
};

export function loginPageMiddleware({ store }: MiddlewareContext): LoaderFunction {
  return async function loginPageLoader({ request, params }) {
    const url = new URL(request.url);
    const path = url.pathname;
    const query = url.searchParams;
    let { subdomain } = parseHostname(url.hostname);

    if (subdomain) {
      return redirect(rewriteSubdomainUrl(path, subdomain, query));
    }

    subdomain = params.subdomain;

    let domain;
    if (subdomain) {
      domain = await store.dispatch(fetchSignupDomain(subdomain));
      if (!domain) return <NotFoundPage />;
    }

    const { viewer } = store.getState();

    // login from brand page should join network by default
    const defaultNext =
      params.type === 'expert' && subdomain
        ? `/${params.subdomain}/signup/${params.type}`
        : '/dashboard';
    const next = query.get('next') || defaultNext;

    if (viewer.id) {
      return redirect(next);
    }

    const data: LoaderData = {
      marketingLinks: marketingLinks({
        showLogin: !domain,
        domain: params.subdomain,
      }),
      nextUrl: next,
      invite: query.get('invite') || undefined,
      signupType: params.type as SignupType | undefined,
      domain,
    };
    return data;
  };
}

export default LoginPage;
