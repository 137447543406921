import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import cx from 'classnames';
import qs from 'query-string';
import React, { CSSProperties, ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { useFragment } from '@/__generated__';
import {
  ActionUpdateExpertRequestCandidateMutationVariables,
  CandidateState,
  IsGoodMatch,
} from '@/__generated__/graphql';
import { notify, popup } from '@/actions/ui';
import Button from '@/components/Button';
import Dialog from '@/components/Dialog';
import Divider from '@/components/Divider';
import EditIcon from '@/components/EditIcon';
import ExpertPicture from '@/components/ExpertPicture';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import IconMenu from '@/components/IconMenu';
import InternalNetworksBadge from '@/components/InternalNetworksBadge';
import KeywordList from '@/components/KeywordList';
import Link from '@/components/Link';
import LongText from '@/components/LongText';
import RequestConsultation from '@/components/RequestConsultation';
import Tidbit from '@/components/Tidbit';
import { requestConsultation } from '@/consultation/store';
import { Viewer } from '@/core/viewer';
import { Candidate, ExpertRequest } from '@/expertrequest';
import { updateExpertRequestCandidate } from '@/expertrequest/store';
import { PROJECT_FIELDS } from '@/expertrequest/store/queries';
import { usePermissions } from '@/hooks/useAppContext';
import Message from '@/messaging/components/Message';
import { darkBrown, darkGreen, lightTan, red500, sand, teal500 } from '@/theme/colors';

import CandidateRates from './CandidateRates';
import s from './CandidateWidgetHorizontal.module.scss';
import EditClientNote from './EditClientNote';
import EditMatchNote from './EditMatchNote';
import EditMessage from './EditMessage';
import MatchState, { stateConfigurations } from './MatchState';

const isGoodMatchNoTitle = "Which part of this expert's experience did not match your needs?";
const isGoodMatchMaybeTitle = 'What should we clarify to confirm if this expert is a match?';

interface CandidateWidgetHorizontalProps {
  isGoodMatch?: IsGoodMatch;
  showKeywords?: boolean;
  pictureSize?: number;
  onRemove?: () => void;
  onSelect: (profile: any, selected: boolean) => void;
  selected: boolean;
  candidate: Candidate;
  expertRequest: ExpertRequest;
  viewer: Viewer;
  canConfirmMatch: boolean;
  style: CSSProperties;
}

const connector = connect(undefined, {
  popup,
  notify,
  requestConsultation,
  updateExpertRequestCandidate,
});

const CandidateWidgetHorizontal = React.forwardRef<
  HTMLDivElement,
  CandidateWidgetHorizontalProps & ConnectedProps<typeof connector>
>(
  (
    {
      isGoodMatch,
      showKeywords = false,
      pictureSize = 80,
      onRemove,
      onSelect,
      selected,
      candidate,
      expertRequest,
      viewer,
      canConfirmMatch,
      style,
      requestConsultation,
      updateExpertRequestCandidate,
      popup,
      notify,
    },
    ref
  ) => {
    const {
      id: candidateId,
      state,
      profile,
      match_experience: matchExperience,
      match_note: matchNote,
      client_note: clientNote,
      email,
    } = candidate;

    const [requestConsultationOpen, setRequestConsultationOpen] = useState(false);
    const [editMatchExperienceOpen, setEditMatchExperienceOpen] = useState(false);
    const [editMatchNoteOpen, setEditMatchNoteOpen] = useState(false);
    const [removeConfirmation, setRemoveConfirmation] = useState(false);
    const [editClientNote, setEditClientNote] = useState({
      open: !!isGoodMatch && [IsGoodMatch.No, IsGoodMatch.Maybe].includes(isGoodMatch),
      title:
        isGoodMatch === IsGoodMatch.No
          ? isGoodMatchNoTitle
          : isGoodMatch === IsGoodMatch.Maybe
            ? isGoodMatchMaybeTitle
            : '',
      showMatchOptions: false,
      isGoodMatch,
    });
    const [askBookConsultationOpen, setAskBookConsultationOpen] = useState(isGoodMatch === 'yes');

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const stateConfig = stateConfigurations[state];

    const expertRequestId = expertRequest.id;
    const expertRequestParam = `?${qs.stringify({ expertRequestId })}`;

    const shouldDisplayConfirmMatch =
      profile &&
      canConfirmMatch &&
      (!clientNote || clientNote.is_good_match !== 'yes') &&
      state &&
      [
        CandidateState.Matched,
        CandidateState.Vetting,
        CandidateState.Verified,
        CandidateState.SuggestedByPlatform,
        CandidateState.SuggestedByResearch,
        CandidateState.Interested,
        CandidateState.Polishing,
        CandidateState.Contacted,
      ].includes(state);

    const [canUpdateCandidateResearch, canUpdateClientNote] = usePermissions(
      [
        {
          service: 'expert_request',
          action: 'update_candidate_research',
          resource: expertRequestId,
        },
        {
          service: 'expert_request',
          action: 'update',
          resource: expertRequestId,
        },
      ],
      [expertRequestId]
    );

    const onUpdate = useCallback(
      (m: ActionUpdateExpertRequestCandidateMutationVariables) =>
        updateExpertRequestCandidate(expertRequest.id, m),
      [expertRequest.id, updateExpertRequestCandidate]
    );

    const isExpertActive = profile?.expert_state === 'active';

    const profileUrl = profile ? `${profile.html_url}${expertRequestParam}` : `mailto:${email}`;
    const experience = profile && profile.experiences && profile.experiences[0];
    const headline = profile?.title;
    const keywords = profile?.keywords;
    const groupKeywords = (profile?.group_keywords || [])
      .reduce((all: any, gk: any) => [...all, ...gk.keywords], [])
      .map((k: any) => k.name);
    const isLead = !profile?.user;
    const isUser = !!profile?.user;
    const isRecommended = candidate.match_note && candidate.match_note.recommended;
    const hasClientNote = clientNote && (clientNote.is_good_match || clientNote.note);

    const project = useFragment(PROJECT_FIELDS, expertRequest.project);
    const group = project?.group;
    const groupId = group?.id;

    const networks = (profile?.expert_internal_networks || [])
      .filter((n) => n.network && n.network.group && n.network.group.id === groupId)
      .map((n) => n.network);

    const canRequestConsultation =
      state &&
      ![
        CandidateState.RejectedByClient,
        CandidateState.RejectedByResearch,
        CandidateState.RejectedSuggestion,
      ].includes(state) &&
      (isExpertActive || isLead);

    const showBadges = isLead || networks.length > 0;

    const openRequestConsultation = (event?: MouseEvent<HTMLButtonElement>) => {
      if (event) event.preventDefault();

      const isLead = !profile?.user;
      if (isLead) return openRequestLeadConsultation();

      setRequestConsultationOpen(true);
    };

    const openRequestLeadConsultation = () => {
      if (!profile) return;

      popup({
        title: 'Send Invite?',
        contents: `We’ll invite ${profile.name} to join OnFrontiers and
accept this consultation with you.`,
        buttonAlignment: 'space-between',
        buttons: [
          {
            flat: true,
            label: 'Dismiss',
            callback: () => {},
          },
          {
            label: 'Send Request',
            primary: true,
            callback: async () => {
              try {
                await requestConsultation({
                  profile_type: 'lead',
                  expert_id: profile.id,
                  expert_request_id: expertRequest && expertRequest.id,
                });
                notify(`Request sent to ${profile.name}!`);
              } catch (err) {
                notify('Error when requesting consultation.', 'error');
                throw err;
              }
            },
          },
        ],
        contentStyle: { maxWidth: 500 },
      });
    };

    const handleConfirmMatchNo = () => {
      setEditClientNote({
        open: true,
        title: isGoodMatchNoTitle,
        showMatchOptions: false,
        isGoodMatch: IsGoodMatch.No,
      });
    };

    const handleConfirmMatchMaybe = () => {
      setEditClientNote({
        open: true,
        title: isGoodMatchMaybeTitle,
        showMatchOptions: false,
        isGoodMatch: IsGoodMatch.Maybe,
      });
    };

    const handleConfirmMatchYes = () => {
      setAskBookConsultationOpen(true);
    };

    const openClientNote = () => {
      setEditClientNote({
        open: true,
        title: '',
        showMatchOptions: true,
        isGoodMatch: IsGoodMatch.Unknown,
      });
    };

    const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
      onSelect(profile, e.target.checked);
    };

    return (
      <div
        style={style}
        ref={ref}
        className={cx(s.root, {
          [s.lead]: isLead,
          [s.recommended]: isRecommended,
        })}
      >
        <div>
          {viewer.admin && !isLead && (
            <Checkbox checked={selected} onChange={handleCheck} style={{ marginRight: 5 }} />
          )}
        </div>
        <div className={s.picture}>
          <ExpertPicture user={profile} size={pictureSize} href={profileUrl} />

          {isLead && profile?.linkedin_url && (
            <IconButton href={profile.linkedin_url} style={{ marginTop: 10 }}>
              <FAIcon icon="linkedin" size={28} color={darkGreen} />
            </IconButton>
          )}
        </div>

        <div className={s.info}>
          <div className={s.title}>
            <h3 className={s.name}>
              <Link to={profileUrl}>{profile ? profile.name : email}</Link>
            </h3>

            {stateConfig && (
              <MatchState
                stateConfig={stateConfig}
                viewer={viewer}
                editable={canUpdateCandidateResearch}
                expertRequest={expertRequest}
                candidate={candidate}
                state={state}
                onUpdate={onUpdate}
              />
            )}
          </div>

          {profile && <div className={s.headline}>{headline}</div>}

          {profile && (
            <Tidbit
              inline={false}
              city={profile.city || undefined}
              country={profile.country || undefined}
              experience={experience}
            />
          )}

          {showBadges && (
            <div className={s.badges}>
              {isLead && (
                <div className={s.leadBadge}>
                  <FAIcon iconSet="fas" icon="user-clock" size={16} color={darkGreen} />{' '}
                  Unregistered Expert
                </div>
              )}

              <InternalNetworksBadge
                className={s.internalNetworkBadge}
                networks={networks}
                fontSize={16}
              />
            </div>
          )}

          {profile && <p className={s.summary}>{profile.summary}</p>}

          {profile && showKeywords && (
            <div className={s.keywords}>
              {((keywords && keywords.length > 0) ||
                (groupKeywords && groupKeywords.length > 0)) && (
                <KeywordList
                  icon={null}
                  keywords={keywords || []}
                  highlightedKeywords={groupKeywords || []}
                />
              )}
            </div>
          )}

          {matchExperience && (
            <Message
              user={profile}
              bubbleClassName={s.leftMessageBubble}
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              <div className={s.matchMessage}>
                <LongText text={matchExperience} />
              </div>
              {canUpdateCandidateResearch && (
                <EditIcon
                  onClick={() => setEditMatchExperienceOpen(true)}
                  style={{ marginLeft: 10 }}
                />
              )}
            </Message>
          )}
          {profile && !matchExperience && canUpdateCandidateResearch && (
            <Link className={s.addMessage} onClick={() => setEditMatchExperienceOpen(true)}>
              <MaterialIcon className={s.addMessageIcon} icon="add" /> Add expert experience
            </Link>
          )}
          {profile && canUpdateCandidateResearch && (
            <EditMessage
              open={editMatchExperienceOpen}
              field="match_experience"
              messageTitle="expert experience"
              messageTitleCapitalized="Expert experience"
              onSubmit={onUpdate}
              onClose={() => setEditMatchExperienceOpen(false)}
              initialValues={{
                id: candidateId,
                match_experience: matchExperience,
              }}
            />
          )}

          {clientNote && hasClientNote && (
            <Message
              position="right"
              backgroundColor={isLead ? lightTan : sand}
              user={clientNote.author}
              bubbleClassName={s.rightMessageBubble}
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              <div className={s.matchMessage}>
                {clientNote.author && clientNote.author.name && clientNote.is_good_match && (
                  <div className={s.recommendedBy}>
                    {clientNote.author.id === viewer.id ? 'You' : clientNote.author.name}
                    &nbsp;said &#34;{clientNote.is_good_match}&#34; to this match
                  </div>
                )}
                {clientNote.note && <LongText text={clientNote.note} />}
              </div>
              {canUpdateClientNote && (
                <EditIcon onClick={openClientNote} style={{ marginLeft: 10 }} />
              )}
            </Message>
          )}
          {profile && !hasClientNote && canUpdateClientNote && (
            <Link className={s.addMessageRight} onClick={openClientNote}>
              <MaterialIcon className={s.addMessageIcon} icon="add" /> Add note to Research Manager
            </Link>
          )}
          {profile && editClientNote.open && (
            <EditClientNote
              {...editClientNote}
              onSubmit={onUpdate}
              onClose={() => {
                onUpdate({
                  id: candidate.id,
                  client_note: { is_good_match: editClientNote.isGoodMatch },
                });
                setEditClientNote((state) => ({ ...state, open: false }));
              }}
              profile={profile}
              initialValues={{
                id: candidateId,
                client_note: {
                  note: clientNote?.note || undefined,
                  is_good_match: clientNote?.is_good_match || IsGoodMatch.Unknown,
                },
              }}
            />
          )}

          {matchNote && (
            <Message
              user={matchNote.author}
              bubbleClassName={s.leftMessageBubble}
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              <div className={s.matchMessage}>
                {isRecommended && matchNote.author && matchNote.author.name && (
                  <div className={s.recommendedBy}>
                    <MaterialIcon
                      icon="supervised_user_circle"
                      color={darkGreen}
                      size={20}
                      style={{
                        verticalAlign: 'bottom',
                        width: 20,
                        marginRight: 3,
                      }}
                    />{' '}
                    Recommended by {matchNote.author.name}
                  </div>
                )}
                {matchNote.note && <LongText text={matchNote.note} />}
              </div>
              {canUpdateCandidateResearch && (
                <EditIcon onClick={() => setEditMatchNoteOpen(true)} style={{ marginLeft: 10 }} />
              )}
            </Message>
          )}
          {profile && !matchNote && canUpdateCandidateResearch && (
            <Link className={s.addMessage} onClick={() => setEditMatchNoteOpen(true)}>
              <MaterialIcon className={s.addMessageIcon} icon="add" /> Add note
            </Link>
          )}
          {profile && canUpdateCandidateResearch && editMatchNoteOpen && (
            <EditMatchNote
              open={editMatchNoteOpen}
              onSubmit={onUpdate}
              onClose={() => setEditMatchNoteOpen(false)}
              profile={profile}
              initialValues={{
                id: candidateId,
                match_note: {
                  note: matchNote?.note || undefined,
                  recommended: matchNote?.recommended || undefined,
                },
              }}
            />
          )}

          {viewer.admin && <CandidateRates candidate={candidate} expertRequest={expertRequest} />}

          <div className={s.actions}>
            <div className={s.mainActions}>
              <div className={s.requestConsultationButton}>
                {canRequestConsultation && (
                  <Button
                    color="primary"
                    size="medium"
                    disabled={!!isUser && !profile.can_request_consultation}
                    onClick={openRequestConsultation}
                  >
                    Request Expert
                  </Button>
                )}
              </div>

              {viewer.admin && isLead && profile?.emails && profile.emails.length > 0 && (
                <div className={s.sendEmailButton}>
                  <Link href={`mailto:${profile.emails[0].address}`}>
                    <Button color="white" fontColor={darkBrown} size="medium">
                      Send Email
                    </Button>
                  </Link>
                </div>
              )}
            </div>

            <div className={s.contextOptions}>
              <IconMenu>
                {profile && (
                  <MenuItem className="p-0">
                    <Link to={profileUrl} menu className="w-full px-10 py-8">
                      View Profile
                    </Link>
                  </MenuItem>
                )}
                {onRemove && (
                  <MenuItem onClick={() => setRemoveConfirmation(true)}>Remove Expert</MenuItem>
                )}
              </IconMenu>
              {onRemove && (
                <Dialog
                  open={removeConfirmation}
                  title="Are you sure you want to remove this expert?"
                  onCancel={() => setRemoveConfirmation(false)}
                  onConfirm={() => {
                    setRemoveConfirmation(false);
                    onRemove();
                  }}
                  confirmLabel="Yes"
                  cancelLabel="Nevermind"
                  confirmButtonProps={{ color: 'red' }}
                />
              )}
            </div>

            {requestConsultationOpen && profile?.user && (
              <RequestConsultation
                open={requestConsultationOpen}
                onClose={() => {
                  setRequestConsultationOpen(false);
                  onUpdate({
                    id: candidate.id,
                    client_note: { is_good_match: IsGoodMatch.Yes },
                  });
                }}
                expertId={profile.user.id}
                profile={profile}
                expertRequest={expertRequest}
                groupId={groupId}
              />
            )}
          </div>

          {shouldDisplayConfirmMatch && (
            <div>
              <Divider style={{ marginBottom: 15 }} />

              <div className={s.changeMatchStateText}>
                Is {profile.first_name} a good match for your request?
                <Button
                  variant="text"
                  fontColor={red500}
                  style={{ minWidth: 60 }}
                  onClick={handleConfirmMatchNo}
                  size="small"
                >
                  No
                </Button>
                <Button variant="text" onClick={handleConfirmMatchMaybe} size="small">
                  Maybe
                </Button>
                <Button
                  variant="text"
                  fontColor={teal500}
                  style={{ minWidth: 60 }}
                  onClick={handleConfirmMatchYes}
                  size="small"
                >
                  Yes
                </Button>
              </div>
            </div>
          )}

          <Dialog
            open={askBookConsultationOpen}
            onCancel={() => {
              setAskBookConsultationOpen(false);
              onUpdate({
                id: candidate.id,
                client_note: { is_good_match: IsGoodMatch.Yes },
              });
            }}
            onConfirm={() => {
              setAskBookConsultationOpen(false);
              openRequestConsultation();
            }}
            confirmLabel="Request Expert"
            cancelLabel="Not Now"
            style={{ textAlign: 'center' }}
          >
            <div>
              <MaterialIcon size={44} icon="perm_contact_calendar" color={darkGreen} />
            </div>
            <div className={s.askBookConsultationTitle}>
              We’re glad {profile?.first_name} is a good match!
            </div>
            <div className={s.askBookConsultationText}>
              Would you like to request a consultation now?
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
);
CandidateWidgetHorizontal.displayName = 'CandidateWidgetHorizontal';

export default connector(CandidateWidgetHorizontal);
