import { MenuItem } from '@mui/material';
import cx from 'classnames';
import moment from 'moment-timezone';
import Tooltip from 'rc-tooltip';
import { PureComponent } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { ExpertRequestState } from '@/__generated__/graphql';
import { track } from '@/actions/tracking';
import { popup } from '@/actions/ui';
import CloseExpertRequestDialog from '@/components/CloseExpertRequestDialog';
import CountBox from '@/components/CountBox';
import DeleteExpertRequestDialog from '@/components/DeleteDialog/DeleteExpertRequestDialog';
import FAIcon from '@/components/Icon/FAIcon';
import IconMenu from '@/components/IconMenu';
import Link from '@/components/Link';
import { deleteExpertRequest, updateExpertRequestState } from '@/expertrequest/store';
import { RootState } from '@/store';
import { primary, red500 } from '@/theme/colors';
import { formatDateTime } from '@/utils';

import s from './ExpertRequestPreview.module.scss';

function stateAppearance(state: any) {
  if (!state || state === '' || state === 'open') {
    return {
      label: 'Active',
      icon: { glyph: 'check-circle', color: primary },
    };
  }
  if (state === 'closed') {
    return {
      label: 'Closed',
      icon: { glyph: 'times-circle', color: red500 },
    };
  }
  throw new Error(`unknown expert request state ${state}`);
}

interface ExpertRequestPreviewProps {
  className?: string;
  request: any;
  project?: any;
  showState?: boolean;
  showContextActions?: boolean;
  showAddAction?: boolean;
  showDeleteAction?: boolean;
  showCloseAction?: boolean;
}

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    updateExpertRequestState,
    deleteExpertRequest,
    popup,
    track,
  }
);

class ExpertRequestPreview extends PureComponent<
  ExpertRequestPreviewProps & ConnectedProps<typeof connector>
> {
  state = {
    deleteConfirmationOpen: false,
    closeExpertRequestDialogOpen: false,
  };

  handleUpdateState = (closeReason: string) => {
    const { request, updateExpertRequestState } = this.props;
    const state =
      request.state === ExpertRequestState.Open
        ? ExpertRequestState.Closed
        : ExpertRequestState.Open;
    updateExpertRequestState(request.id, state, closeReason);
  };

  setCloseExpertRequestDialogOpen = () => {
    this.setState({ closeExpertRequestDialogOpen: true });
  };

  setCloseExpertRequestDialogClosed = () => {
    this.setState({ closeExpertRequestDialogOpen: false });
  };

  confirmDelete = () => this.setState({ deleteConfirmationOpen: true });

  closeConfirmDelete = () => this.setState({ deleteConfirmationOpen: false });

  showPremiumFeaturePromo = () => {
    const { popup, track } = this.props;
    track('promo.show.request.archived');
    popup({
      layout: 'promo',
      title: 'Request archival is a premium feature',
      contents:
        'Trying to access a request over 90 days old? ' +
        'Upgrade to our Advanced or Enterprise account ' +
        'to view your full project history.',
      buttons: [
        {
          label: 'Start a chat',
          primary: true,
          callback: () => {
            track('promo.chat.request.archived');
            window.HubSpotConversations?.widget?.open();
          },
        },
      ],
      links: [{ label: 'No thanks.', callback: () => {} }],
    });
  };

  render() {
    const {
      viewer,
      className,
      request,
      project,
      showState,
      showContextActions,
      showAddAction,
      showDeleteAction,
      showCloseAction,
    } = this.props;

    const state = stateAppearance(request.state);

    const blocked = request.archived && !request.permissions.includes('view_archived');

    return (
      <div className={cx(className)}>
        <div className={s.requestCard}>
          {blocked && (
            <div
              className={s.blockedArchived}
              onClick={this.showPremiumFeaturePromo}
              onKeyPress={this.showPremiumFeaturePromo}
            />
          )}
          <div className={s.requestTitle}>
            <Link
              to={request.html_url}
              className={s.requestTitleLink}
              id={`expertRequest-${request.id}`}
            >
              <FAIcon icon="user-circle-o" style={{ width: 20, height: 20, marginRight: 7 }} />
              <span>{request.name}</span>
            </Link>

            <Tooltip
              overlay={formatDateTime(request.created_at, viewer.timezone || undefined)}
              trigger={['hover', 'click']}
              placement="top"
            >
              <div className={s.requestTitleAge}>
                Created {moment(request.created_at).fromNow()}
              </div>
            </Tooltip>
          </div>
          <div className={s.requestDetails}>
            <div className={s.requestStats}>
              <CountBox
                grayOutZero
                className={s.countBox}
                count={request.stats.candidates_count}
                label="Matches"
                linkTo={`${request.html_url}?section=experts`}
              />
              <CountBox
                grayOutZero
                className={s.countBox}
                count={request.stats.calls}
                label="Calls"
                linkTo={`${request.html_url}?section=consultations`}
              />
            </div>
            <div className={s.requestControls}>
              {showState && (
                <div className={s.requestControlsState}>
                  <FAIcon
                    size={20}
                    icon={state.icon.glyph}
                    color={state.icon.color}
                    style={{ verticalAlign: 'middle' }}
                  />
                  <span className={s.requestControlsStateLabel}>{state.label}</span>
                </div>
              )}
              {showContextActions && request.permissions && (
                <IconMenu buttonStyle={{ marginLeft: 20 }}>
                  {showCloseAction && (
                    <MenuItem
                      onClick={() => {
                        if (request.state === 'open') {
                          this.setCloseExpertRequestDialogOpen();
                        } else {
                          // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
                          this.handleUpdateState();
                        }
                      }}
                      disabled={!request.permissions.includes('update')}
                    >
                      {request.state === 'open' ? 'Close' : 'Reopen'}
                    </MenuItem>
                  )}
                  {project && showAddAction && (
                    <Link to={`/request_expert?project_id=${project.id}`} menu>
                      <MenuItem key="addExpertRequest">Add Expert Request</MenuItem>
                    </Link>
                  )}

                  <Link to={`/request_expert/details?copy_from=${request.id}`} menu>
                    <MenuItem key="makeACopy">Make a Copy</MenuItem>
                  </Link>
                  {showDeleteAction && (
                    <MenuItem
                      onClick={this.confirmDelete}
                      disabled={
                        !request.permissions.includes('delete') ||
                        (request.stats && request.stats.calls > 0)
                      }
                    >
                      Delete Request
                    </MenuItem>
                  )}
                </IconMenu>
              )}
            </div>
          </div>
        </div>
        <CloseExpertRequestDialog
          expertRequestId={request.id}
          onConfirm={this.handleUpdateState}
          onReset={this.setCloseExpertRequestDialogClosed}
          onCancel={this.setCloseExpertRequestDialogClosed}
          onClose={this.setCloseExpertRequestDialogClosed}
          open={this.state.closeExpertRequestDialogOpen}
        />
        <DeleteExpertRequestDialog
          expertRequestId={request.id}
          onConfirm={this.closeConfirmDelete}
          onCancel={this.closeConfirmDelete}
          open={this.state.deleteConfirmationOpen}
        />
      </div>
    );
  }
}

export default connector(ExpertRequestPreview);
