import { useEffect } from 'react';
import { Outlet, matchPath, useParams } from 'react-router';

import { Drawer } from '@/componentsv2/Drawer';
import useCloseDrawer from '@/hooks/useCloseDrawer';
import { fetchData as fetchProfile } from '@/profilev2/store/profileSlice';
import { useAppDispatch } from '@/store';

export const ProfileFetcher = () => {
  const dispatch = useAppDispatch();
  const { id: profileId } = useParams();
  useEffect(() => {
    dispatch(fetchProfile(profileId ?? ''));
  }, [dispatch, profileId]);
  return <Outlet />;
};

const SearchDrawers = () => {
  const handleCloseDrawer = useCloseDrawer('./');

  return (
    <>
      <Drawer
        open={
          !!matchPath('search/profile/:id/jobs', location.pathname) ||
          !!matchPath('search/saved/:savedSearchId/profile/:id/jobs', location.pathname)
        }
        onClose={() => handleCloseDrawer()}
      >
        <Outlet />
      </Drawer>

      <Drawer
        open={
          !!matchPath('search/profile/:id/jobs/:jobId', location.pathname) ||
          !!matchPath('search/saved/:savedSearchId/profile/:id/jobs/:jobId', location.pathname)
        }
        onClose={() => handleCloseDrawer()}
        size="lg"
      >
        <Outlet />
      </Drawer>

      <Drawer
        open={
          !!matchPath('search/profile/:id/knowledge-types/:knowledgeType', location.pathname) ||
          !!matchPath(
            'search/saved/:savedSearchId/profile/:id/knowledge-types/:knowledgeType',
            location.pathname
          )
        }
        onClose={() => handleCloseDrawer()}
      >
        <Outlet />
      </Drawer>

      <Drawer
        open={
          !!matchPath('search/profile/:id/knowledge', location.pathname) ||
          !!matchPath('search/saved/:savedSearchId/profile/:id/knowledge', location.pathname)
        }
        onClose={() => handleCloseDrawer()}
        size="lg"
      >
        <Outlet />
      </Drawer>

      <Drawer
        open={
          !!matchPath('search/profile/:id/locations', location.pathname) ||
          !!matchPath('search/saved/:savedSearchId/profile/:id/locations', location.pathname)
        }
        onClose={() => handleCloseDrawer()}
      >
        <Outlet />
      </Drawer>
      <Drawer
        open={
          !!matchPath('search/profile/:id/location', location.pathname) ||
          !!matchPath('search/saved/:savedSearchId/profile/:id/location', location.pathname)
        }
        onClose={() => handleCloseDrawer()}
        size="lg"
      >
        <Outlet />
      </Drawer>

      <Drawer
        open={
          !!matchPath('search/profile/:id/contracts/projects', location.pathname) ||
          !!matchPath(
            'search/saved/:savedSearchId/profile/:id/contracts/projects',
            location.pathname
          ) ||
          !!matchPath('search/profile/:id/contracts/proposals', location.pathname) ||
          !!matchPath(
            'search/saved/:savedSearchId/profile/:id/contracts/proposals',
            location.pathname
          )
        }
        onClose={() => handleCloseDrawer()}
      >
        <Outlet />
      </Drawer>

      <Drawer
        open={
          !!matchPath('search/profile/:id/contracts/projects/:projectId', location.pathname) ||
          !!matchPath(
            'search/saved/:savedSearchId/profile/:id/contracts/projects/:projectId',
            location.pathname
          ) ||
          !!matchPath('search/profile/:id/contracts/proposals/:projectId', location.pathname) ||
          !!matchPath(
            'search/saved/:savedSearchId/profile/:id/contracts/proposals/:projectId',
            location.pathname
          )
        }
        onClose={() => handleCloseDrawer()}
        size="lg"
      >
        <Outlet />
      </Drawer>

      <Drawer
        open={
          !!matchPath('search/profile/:id/colleagues/:colleagueId', location.pathname) ||
          !!matchPath(
            'search/saved/:savedSearchId/profile/:id/colleagues/:colleagueId',
            location.pathname
          )
        }
        onClose={() => handleCloseDrawer()}
      >
        <Outlet />
      </Drawer>
    </>
  );
};

export default SearchDrawers;
